@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import './theme-variables.scss';
// @import './icons.scss';

html,
body {
	margin: 0;
	font-family: 'Montserrat';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	background: $theme-color-orange-bg;
	color: white;
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	src: url('assets/Fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('ttf');
}

code,
a,
h1,
h2,
h4,
h5,
h6,
p {
	font-family: 'Montserrat';
}

#loading-wrap {
    font-size: 18px;
    text-align: center;
    margin: 40px 0;
	display: none;
}

.rdg-cell .has-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}
/* React data grid style overrides */
.rdg-cell[aria-sort='descending'] .rdg-sort-arrow {
	background-image: url('./assets/mdi_filter-menu-outline.svg');
}

.rdg-cell[aria-sort='ascending'] .rdg-sort-arrow {
	background-image: url('./assets/mdi_filter-menu-outline.svg');
}

.MuiMenuItem-root.Mui-selected {
	background: rgba(25, 118, 210, 0.6);
}

.MuiInputAdornment-root .MuiTypography-root {
	color: #71758f;
	font-size: 12px;
	font-style: italic;
}

.export-btn--wrap {
	background: #1b1e35;
	border-radius: 4px;
	position: absolute;
	line-height: 46px;
	width: 50px;
	text-align: center;
}

.Schedule-export-btn {
	right: 58%;
	top: -75px;
}

.Report-export-btn {
	right: 78px;
	top: 100px;
}

.clear-sec {
	border-top: 1px solid #71758f;
	padding: 15px 20px 15px 30px;
	height: min-content;
	min-height: max-content;
	float: left;
	width: 86%;
}

.clear-row img {
	float: left;
}

.clear-row a {
	margin-left: 10px !important;
	float: left;
	margin: 0px;
	line-height: 14px;
	text-transform: capitalize;
	font-size: 14px;
	text-decoration: none;
	color: #f5f5f5;
	font-family: 'Montserrat';
}

.clear-row {
	width: 86%;
	float: left;
}

.clear-row:nth-child(2) {
	margin-top: 10px;
}

.text-arrow-icon {
	float: right !important;
}

.recharts-legend-wrapper {
	margin-bottom: -15%;
	margin-top: 10px !important;
	width: 432px !important;
	left: -54px !important;
}

.legenLabel {
	text-align: end;
	font-size: 12px;
	color: #299bff;
}

.calandar input {
	color-scheme: dark;
	color: #e5e5e5;
	border: none;
	border-radius: 4px;
	background-color: #71758f15 !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
	width: 20px;
	height: 20px;
	padding: 0px;
	margin: 0px;
	background-image: url('./assets/calendar-outline_white.svg');
}

.radialChart {
	margin-left: 58px;
	margin-top: 4px;
}

.legend {
	margin-bottom: -3px;
}

.default-view {
	border: none;
	background: #1b1e35;
	color: white;
	text-transform: capitalize;
	position: absolute;
	/* right: 10px;
    top: 25px; */
	top: -32px;
	right: 80%;
}

.default-view:hover {
	border: none;
}

.job-schedule-table .default-view {
	/* top: -60px;
	padding: 13px 15px;
	right: 16px; */
	top: -75px;
	padding: 13px 15px;
	right: 46%;
}

.run_history_tabledata .default-view {
	top: -55px;
	padding: 13px 15px;
	right: 36%;
}

.radialChart .recharts-wrapper {
	width: 100%;
}

.radialChart .recharts-legend-wrapper {
	width: 360px !important;
}

/* .jobs-schedule-table .job-schedule-table {
	height: calc(100vh - 130px) !important;
} */

.resonsExe {
	margin: 0px;
}

.resonsExe:hover {
	padding: 0px 10px;
	border: 1px solid;
	position: absolute;
	overflow: visible;
}

.cal-icon {
	float: left;
	height: 15px !important;
	margin: 2px 10px 0px;
	margin-left: 20px !important;
}

.cal-txt {
	margin: 0px;
	float: left;
	padding-right: 15px;
}

.reasonForDelay:hover {
	border: 1px solid #71758f;
	padding: 0px 10px;
	background: #1b1e35;
	max-width: max-content;
	min-width: max-content;
}

.has-editor p {
	margin: 0px;
}

.has-editor:hover {
	padding: 3px 0px !important;
}

.h197vzie7-0-0-beta-26>.c1wupbe7-0-0-beta-26 {
	// z-index: 150 !important;
	inset-block-start: 0 !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
	position: absolute;
	left: -12px;
	top: -12px;
}

/* SWAL CSS */
/* Add your custom styles */
// .custom-swal-container {
	/* Custom styles for the container */
// }

.custom-swal-title {
	/* Custom styles for the title */
	background: #1b1e35 !important;
	color: #e5e5e5 !important;
	font-size: 18px !important;
	border-radius: 9px !important;
}

.custom-swal-html-container {
	/* Custom styles for the HTML container */
	background: #1b1e35 !important;
	color: #e5e5e5 !important;
	font-size: 16px !important;
	border-radius: 9px !important;
}

.custom-swal-confirm-button {
	color: white !important;
	border-radius: 4px !important;
	text-transform: capitalize;
	background-color: #00d08d !important;
}

.custom-swal-cancel-button {
	color: #00d08d !important;
	border-radius: 4px !important;
	text-transform: capitalize;
	background-color: #1b1e35 !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
	display: none;
	position: relative;
	box-sizing: border-box;
	grid-template-columns: minmax(0, 100%);
	width: 32em;
	max-width: 100%;
	padding: 0 0 1.25em;
	border: none;
	background: #1b1e35 !important;
	color: #e5e5e5 !important;
	font-family: inherit;
	font-size: 18px;
	border-radius: 9px !important;
}
.theme-bg-blue-powerBIObs {
	.main-content,
	.header {
		background: #001e3e;
	} 
}
.theme-bg-blue {
	.MuiMenu-paper,
	.MuiPaper-root {
		background-color: #001e3e !important;
	}
	.makeStyles-okBtn-24 {
		background-color: rgb(0, 55, 113) !important;
	}
	.main-content,
	.header {
		background: #001e3e;
	}
	.ps-sidebar-container {
		background-color: #003771 !important;
	}
	.ps-sidebar-container li > a {
		background-color: #003771;
	}
	.ps-menuitem-root.active::before {
		background: #009bd8;
	}
	
	.icons-header-collapse,
	.icons-header {
		background-color: #003771 !important;
	}

	#customGrid {
		.rdg {
			background: transparent;
		}
		.rdg-header-row {
			background-color: #003771;
		}
		.pagination-div,
		.darkPagination {
			background-color: rgba(0, 35, 73, 1);
			.makeStyles-jobText1-21 {
				margin-top: 10px !important;
			}
		}
		.darkPagination {
			height: 50px;
		}
		.makeStyles-jobText-18 {
			padding-left: 10px;
			margin-top: 15px !important;
		}
		.makeStyles-jobText1-19 {
			margin-top: 7px !important;
		}
	}
}.filter-bg {
	.MuiPaper-root{
		background: #003771 !important;	
	}
	.MuiButton-root{
		background: transparent !important;
		padding: 10px 35px !important;
		border-radius: 5px !important;
		box-shadow: none !important;
		color: #009BD8 !important;
		margin: 5px;
	}
	.MuiButton-root:active{
		background: #009BD8 !important;
		color:#F5F5F5 !important;
	}
	.MuiButton-root:hover{
		background: #009BD8 !important;
		color:#F5F5F5 !important;
	}
	.filter-header{
		padding-bottom: 0px;
	}
	.filterContainers .sector-list{
		width: 55%;
	}.filters-text{
		font-weight: 800;
		font-size: 18px;
		top: 15px;
	}.filterContainers .Mui-checked{
		color: #009BD8 !important;
		//background: white;
		padding: 0px;
		width: 17px;
		height: 10px;
		margin: 0px 13px;
	}
}
.parent-view {
	padding-left: 5px;
    padding-right: 10px;
}
.toggle-wrap {
	vertical-align: sub;
}
.child-view {
	position: relative;
	display: inline-block;
	height: 100%;
	line-height: 2.8;
	padding-left: 20px;
    padding-right: 10px;
    margin-left: 5px;
    &:before,
    &:after {
        content: '';
        position: absolute;
		background-color: #A9ADCB;
		top: 0;
    }
    &:before {
        inset-inline-start: 5px;
        inline-size: 1px;
        block-size: 100%;
    }
    &:after {
        inset-block-start: 50%;
        inset-inline-start: 5px;
        block-size: 1px;
        inline-size: 10px;
    }
	&.l1-t {
		padding-left: 20px;
		padding-right: 10px;
		margin-left: 25px;
	}
	&.l1-f {
		margin-left: 44px;
	}
	&.l1-fv {
		margin-left: 64px;
	}
	&.l1-six {
		margin-left: 84px;
	}
	&.l1-sv {
		margin-left: 104px;
	}
	&.l1-eg {
		margin-left: 124px;
	}
	&.l1-nn {
		margin-left: 144px;
	}
	&.l1-tn {
		margin-left: 164px;
	}
	&.l1-el {
		margin-left: 184px;
	}
	&.l1-tw {
		margin-left: 204px;
	}
	&.l1-th {
		margin-left: 224px;
	}
	&.l1-ft {
		margin-left: 244px;
	}
	&.l1-ff {
		margin-left: 264px;
	}
	&.l1-st {
		margin-left: 284px;
	}
	
	&.Successful,
	&.COMPLETED,
	&.Completed {
		&:before,
		&:after {
			background-color: #01c008;
		}
	}
	&.Failure,
	&.FAILURE,
	&.TERMINATED,
	&.Terminated,
	&.SUSPENDED,
	&.Suspended {
		&:before,
		&:after {
			background-color: #cd2429;
		}
	}
	&.SCHEDULED,
	&.Scheduled {
		&:before,
		&:after {
			background-color: rgba(169, 173, 203, 1);
		}
	}
	&.HOLD,
	&.Hold {
		&:before,
		&:after {
			background-color: #299BFF;
		}
	}
	&.RUNNING,
	&.Running,
	&.IN.PROGRESS,
	&.In.progress {
		&:before,
		&:after {
			background-color: #299BFF;
		}
	}
	&.NOT.STARTED,
	&.Not.started,
	&.Not.Started {
		&:before,
		&:after {
			background-color: #71758F;
		}
	}
}


.grid-added-jobs {
	.rdg .rdg-row .rdg-cell:has(.child-view) {
		padding-bottom: 0 !important;
		padding-top: 0 !important;
	}
}

.v2Background {
	.MuiBox-root, .header, .MuiStack-root{
		background: #8db6e1;
	}
}
