@import '../../theme-variables.scss';
.dashboard-panel-powerBIObs {
    margin-top: 135px !important;
    .loader {
        z-index: 9;
    }
    .headerIPB-title {
        line-height: 20px;
        width: 49%;
        margin: 0px;
    }
}
.keyMetrics-base{
    padding-left: 25px;
    margin-top: 135px;
}
.custom-grid-powerBIObs {
    .MuiGrid-item {
        max-width: 20%;
    }
}
.powerBIObs-metrics-title {
    flex: 1 1 auto;
    border-radius: 4px;
    font-size: 12px;
    font-weight: normal;
    line-height: 40px;
    position: relative;
    width: 10px;
    padding-left: 16px !important;
    padding-bottom: 16px !important;
    background-Color: #003771 !important;
}
@media (max-width: 600px) {
    .powerBIObs-metrics-title {
      flex: none;
      width: 100%;
    }
  }
.powerBIObs-metrics-title-grid1 {
    height: 130px !important;
    margin: 10px 0 5px 6px;
}  
.powerBIObs-metrics-title-grid2 {
    height: 135px !important;
    margin: -5px 0 5px 6px;
}  
.powerBIObs-barChart {
    border-radius: 4px;
    background-Color: #003771 !important;
}  
.monitor-head {
    min-height: 40px;
    padding-right: 20px;
}
.powerBIObs-subheader {
    font-size: 40px;
    font-weight: 600;
    margin-top: 10px;
}
.powerBI-sector-filter-cost {
    width: 180px !important;
    right: 25px !important;
}
.sector-filter-dataestate {
    background-Color: #003771 !important;
    .filter-bg .MuiButton-root {
        width: 130px !important
    }
}
.powerBI-sector-filter {
    background-color: #003771 !important;
    border-radius: 4px;
    position: fixed;
    right: 35px;
    top: 85px;
    z-index: 999;
    width: 160px;
    min-height: 43px;

    .sector-label {
        line-height: 30px;
        vertical-align: top;
        display: inline-block;
        margin-top: 5px;
        margin-left: 10px;
    }

    .sector-cta {
        cursor: pointer;
        display: inline-block;
        position: relative;
    }

    svg {
        height: 40px;
        width: 40px;
        position: absolute;
        right: 0;
    }
}
.powerBIObs-subheader-span {
    font-size: 25px;
    color: #71758f;
}
.page-table {
	padding: 0px;
	margin-top: 50px;
    width: 96%;
}
.capacityDetails_tabledata {
    .MuiChip-filled {
        width: 90px;
    }
    .MuiChip-filled,
    .MuiChip-label {
        margin: 0;
        font-family: "Montserrat";
        font-weight: 600;
        text-transform: uppercase;
    }
}
.capacityDetails_tabledata .rdg-header-row {
	background-Color: #003771 !important;
}
.capacityDetails_tabledata .rdg-header-row .rdg-cell {
	background-Color: #003771 !important;
}
.capacityDetails_tabledata .rdg {
	background: transparent !important;
	height: calc(100vh - 250px);
	border: none;
	overflow-y: auto !important;
}
.capacityDetails_tabledata .MuiTypography-root{
    color: white;
}
.capacityDetails_tabledata .pagination-div {
    width: 100%;
    bottom: 0px;
    height: 50px;
    background-Color: #002349 !important;
}
.capacityDetails_tabledata .darkPagination {
    margin-bottom: 0;
    margin-top: 10px;
    bottom: inherit;
    right: 0;
    height: 0px;
    background-Color: #002349 !important;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0px 0px;
    box-sizing: border-box;
    float: right;
    margin-top: 2px !important;
}
.capacityDetails_tabledata .css-6epwgu-MuiPagination-root .Mui-selected {
    background-color: #003771;
}
.capacityDetails_tabledata .highlightClass .rdg-cell {
    background-Color: #001e3e !important;
}
.capacityDetails-title {
    margin-bottom: 16px;
    margin-top: 16px;
}
.sector-filter, .inference-filter {
	top: 82px;
	left: 95%;
	position: absolute;
}
.sector-filter-text-ml {
	top: 85px;
	left: 89%;
	position: absolute;
}
.selectAll-sec-powerBI {
    overflow-y: auto !important;  
    overflow-x: hidden !important;
    padding-right: 10px !important; 
    background-Color: #003771 !important;
    height: 140px;
  
    &::-webkit-scrollbar {
      width: 8px !important; 
    }
  
    &::-webkit-scrollbar-thumb {
      background-Color: #009BD8 !important;
      border-radius: 10px !important; 
    }
}
.filterContainer-powerBI .MuiPaper-root {
    margin-left: 0px !important;
}
.loading {
    font-size: 12px;
}
.powerBI-notification-icon {
    border-radius: 4px;
    position: fixed;
    right: 65px;
    top: 25px;
    z-index: 999;
    width: 25px;
    min-height: 25px;
}
.details-loader {
    display: flex;
    justify-content: center;
    align-content: center;
    min-height: 400px;
    transform: scale(2);
}.text-filter-row {
    .selectAll input[type=checkbox]:checked, .selectAll-sec{
        background-color: transparent !important;
    }
}.filter-bg .filterContainer .MuiPaper-root{
    width: 280px !important;
    .text-filter-sec{
        width: 87%;
    }
    .MuiButton-root{
        float: left;
        width: 46%;
    }
}
.no-padding{
    padding-left: 0 !important;
}.MuiStack-root.sector-filter-drop .MuiStack-root{
    z-index: 100;
}.capacity-metrics.MuiStack-root{
    z-index: 9 !important;
}