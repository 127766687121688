@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	src: url('../../assets/Fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('ttf');
}
.filter-icon span img {
	float: right;
	margin-top: 8px;
}
.filter-icon span {
	font-family: 'Montserrat';
	font-weight: 500;
	color: #e5e5e5;
}
.rdg-cell {
	padding: 4px 7px 4px 7px !important;
	color: #e5e5e5;
}
/* .filter-icon span:first-child {
	/* float: left;
    width: 90%; */
/* }  */
/* .job-list-table {
	width: 100%;
}
.job-list-table .rdg {
	width: 100%;
}
.job-list-table .rdg::before {
	width: 100%;
} */

.job_executions_tabledata .MuiChip-filled {
	height: 20px;
	width: 85px;
}
.job_executions_tabledata .MuiChip-filled.slaChip {
	width: 55px;
}
.job_executions_tabledata .MuiChip-label {
	padding: 0 10px;
	font-size: 10px;
}
.job-schedules-wrap {
	position: relative;
	padding: 0 16px;
}
.job-schedules-wrap .calendar-ui {
	position: absolute;
	right: 55% !important;
	top: -75px;
	width: 290px;
}
.job-schedules-wrap .calendar-ui input {
	font-size: 14px;
	height: 33px;
	min-width: 198px;
	color: #fff;
}
.job-schedules-wrap .calendar-ui .edit-icon {
	width: 50px;
	float: right;
	height: 50px;
}
.total {
	color: #e5e5e5;
}
.success {
	color: #01c008;
}
.pending {
	color: #71758f;
}
.failed {
	color: #fe2626;
}
.others {
	color: #299bff;
}
.job-schedule-table {
	height: calc(100vh - 100px);
	overflow: auto;
	margin-top: 158px;
}
.job-schedule-table {
	height: calc(100vh - 150px) !important;
}

.job-exexutions-dateFilter-div {
	margin-right: 10px !important;
	z-index: 9999;
	margin-top: 0;
}

.datefilter-align .job-exexutions-dateFilter-div {
	margin-right: 10px !important;
	margin-top: 15px;
}
.jobs-list-datas .job-exexutions-dateFilter-div,
.jobs-list-table .job-exexutions-dateFilter-div {
	/* display: inline-flex; 
		align-items: flex-end;
    clear: both;
    padding: 25px 0px 20px;
    float: left;
		position: absolute;
    right: 48%;
    top: -90px;
    width: 35%;  */
	/* background-color: red; */
	/* margin-left: 10px; */
	display: flex;
	align-items: flex-end;

	/* width: 30%; */
	position: absolute;
	top: -75px;
	right: 65px;
	margin-right: 2px;
	background-color: #71758f0d;
	padding: 2px;
	z-index: 99;
	/* position: fixed; */
	/* top: 15%; */
}
.datefilter-align-sector .job-exexutions-dateFilter-div {

	display: flex;
	align-items: flex-end;
	position: fixed;
	top: 85px;
	right: 8px;
	margin-right: 2px;
	background-color: #71758f0d;
	padding: 2px;
	z-index: 99;
}
.jobs-list-datas .job-exexutions-dateFilter-div .dateFilter-formControl {
	margin: 0;
	margin-right: 10px;
}
.Mui-disabled {
	color: #e5e5e544 !important;
}
.job-exexutions-dateFilter-div .dateRangeApplyBtn {
	background-color: #00d08d;
	height: 30px;
	margin-bottom: 5px;
}

.range_menu_title {
	padding: 20px 20px;
	font-size: 15px;
	font-weight: bold;
}

.dateFilter {
	background-color: rgba(90, 84, 152, 0.2);
	margin: 5px 15px;
	width: 90%;
}
.dateFilterTD {
	/* background-color: rgba(90, 84, 152, 0.2); */
	margin: 0;
	width: 90%;
}

.label {
	margin: 3px 5px;
	padding-top: 5px;
	padding-left: 15px;
}

.date_range_button {
	margin-top: 20;
	width: '100%';
	display: 'flex';
	align-items: 'center';
}

.jobsNameList:hover {
	background: #0c0f25;
	padding: 10px !important;
	border: 1px solid #71758f;
}

.row-select-drpdwn {
	margin: 0;
}

.row-select-drpdwn .MuiSelect-select {
	padding-left: 8px;
}

.row-select-drpdwn .MuiSelect-icon {
	color: #fff;
	right: 16px !important;
}

/* Timepicker MUI */
.MuiTimePickerToolbar-hourMinuteLabel {
	margin-top: 25%;
}

.MuiTimePickerToolbar-root .MuiTypography-root {
	font-family: Montserrat !important;
	color: #f5f5f5;
	text-transform: unset;
	font-weight: 700px;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.07);
	border-radius: 4px;
}

.EditSLATimePicker .PrivatePickersToolbarText-root.Mui-selected,
.EditSLATimePicker .PrivatePickersToolbarText-root.MuiTypography-root {
	color: #f5f5f5;
	font-size: 55px;
	background: #71758f66;
	padding: 3px;
	border-radius: 9px;
	width: 70px;
}

.PrivatePickersToolbarText-root.Mui-selected {
	border: 1px solid #00d08d;
}

.MuiTimePickerToolbar-separator.MuiTypography-root {
	background: none;
	width: 10px;
}

.MuiPickersArrowSwitcher-button {
	background-color: transparent;
	color: #00d08d;
}

.MuiPickersArrowSwitcher-button.Mui-disabled {
	background-color: transparent;
	color: #00d08d55;
}

.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockNumber-root.Mui-selected {
	background-color: #00d08d;
}

.MuiPickersToolbar-penIconButton {
	display: none;
}

.time-picker-zone {
	position: fixed;
	margin-top: 30%;
}
/* MUI chips */
.MuiChip-colorSuccess {
	background-color: #17372e;
	color: #01c008;
}

.MuiChip-colorSecondary {
	background-color: #3d2033;
	color: #cd2429;
}

.MuiChip-colorWarning {
	background-color: #ffa20033;
	color: #ffa200;
}

.MuiChip-colorDefault {
	background-color: #0c0f25;
	color: #e5e5e5;
	margin-bottom: 10px;
	max-width: 106px;
	margin-right: 10px;
	height: 24px;
}
.job_executions_tabledata .rdg {
	background: #0c0f25;
	height: calc(100vh - 250px);
	border: none;
	overflow-y: auto !important;
}

.job_executions_tabledata .rdg-cell {
	font-weight: 300;
	padding: 4px 7px 4px 10px !important;
	color: white;
	border: none;
	line-height: 28px;
	font-size: 12px;
}
.job_executions_tabledata .rdg-cell:hover {
	overflow: visible;
	z-index: 111;
	max-width: initial;
	background: #0c0f25;
}

.job_executions_tabledata .rdg-cell[aria-selected='true'] {
	outline: none;
}

.job_executions_tabledata .rdg-cell a {
	color: white;
	text-decoration: none;
}

.job_executions_tabledata .rdg-cell[aria-sort='descending'] .rdg-sort-arrow,
.job_executions_tabledata .rdg-cell[aria-sort='ascending'] .rdg-sort-arrow {
	fill: none;
	height: 20px;
}

.job_executions_tabledata .rdg-row:nth-child(even) {
	background: none;
}

.job_executions_tabledata .rdg-header-row {
	background: #1b1e35;
}

.job_executions_tabledata .rdg-row:nth-child(odd) {
	background: rgba(113, 117, 143, 0.05);
}

.job_executions_tabledata .rdg-row:nth-child(odd) .rdg-cell {
	border-right: 1.5px solid #1b1e35;
}

.job_executions_tabledata .rdg-header-row .rdg-cell {
	border-right: 1.5px solid #0c0f25;
}

.job_executions_tabledata .rdg-cell:last-child {
	border-right: none !important;
}

.job_executions_tabledata .rdg-text-editor {
	appearance: none;
	box-sizing: border-box;
	inline-size: 100%;
	block-size: 100%;
	border: 2px solid #1b1e35;
	color: #f5f5f5;
	background-color: #71758f66;
	font-family: 'Montserrat';
	font-size: 12px;
	height: 30px;
}

.job_executions_tabledata .rdg-text-editor::placeholder {
	color: #999;
	opacity: 1;
}

.job_executions_tabledata .rdg-text-editor:focus {
	border: 1px solid;
	border-color: white;
	outline: none;
}

.job_executions_tabledata .rdg-editor-container .MuiInputBase-root {
	font-family: Montserrat !important;
	font-size: 12px;
}

.job_executions_tabledata .rdg-cell.has-editor:hover {
	outline: 1px solid #3f3a4b;
	background: #3f3a4baa;
}

.job_executions_tabledata .reasonForDelay:hover {
	position: absolute;
}

.job_executions_tabledata .rdg-cell.has-editor.rdg-editor-container:hover {
	outline: none;
}

.job_executions_tabledata .has-editor.rdg-editor-container {
	padding-left: 0 !important;
	padding-top: 2px !important;
}

.job_executions_tabledata .has-editor.rdg-editor-container * {
	background: none !important;
}

.job_executions_tabledata .rdg-cell.has-editor input {
	border: 1px solid #3f3a4b;
}

.job_executions_tabledata .rdg-cell.center-text {
	text-align: center;
}

.job_executions_tabledata .highlightClass .rdg-cell {
	background-color: #71758f66;
	border-right: none !important;
}

.job_executions_tabledata .highlightClass:hover .rdg-cell {
	background-color: #71758f66;
}

.jobs-run-table .job_executions_tabledata .rdg {
	height: 47vh;
}
.jobs-list-datas .dateFilter .MuiSvgIcon-root,
.jobs-list-table .dateFilter .MuiSvgIcon-root,
.jobs-run-table .dateFilter .MuiSvgIcon-root {
	color: #71758f;
}

.jobs-list-datas .dateFilter input,
.jobs-list-table .dateFilter input,
.jobs-run-table .dateFilter input {
	color: white;
	font-size: 12px;
}

.jobs-list-datas .dateFilter-formControl,
.jobs-list-table .dateFilter-formControl,
.jobs-run-table .dateFilter-formControl {
	width: 95%;
	float: left;
	margin: 0px 10px 20px;
}

.jobs-list-datas .dateFilter-div,
.jobs-list-table .dateFilter-div,
.jobs-run-table .dateFilter-div {
	display: block;
	clear: both;
	padding: 25px 0px 20px;
	float: left;
	border-top: 1px solid #71758f;
}

.jobs-list-datas .dateFilter .MuiOutlinedInput-notchedOutline,
.jobs-list-table .dateFilter .MuiOutlinedInput-notchedOutline,
.jobs-run-table .dateFilter .MuiOutlinedInput-notchedOutline {
	border: none;
	/* border-bottom: 1px solid #71758f; */
	border-radius: 0px;
}

.jobs-list-datas .dateFilter .MuiInputAdornment-root,
.jobs-list-table .dateFilter .MuiInputAdornment-root,
.jobs-run-table .dateFilter .MuiInputAdornment-root {
	margin-left: 0px;
}

.jobs-list-datas .dateFilter .MuiOutlinedInput-root.Mui-focused,
.jobs-list-table .dateFilter .MuiOutlinedInput-root.Mui-focused,
.dateFilter .MuiOutlinedInput-notchedOutline:hover,
.jobs-run-table .dateFilter .MuiOutlinedInput-root.Mui-focused {
	border-color: #00d08d;
}

.jobs-list-datas .dateFilter .editJobForm .MuiOutlinedInput-root.Mui-focused,
.jobs-list-table .dateFilter .editJobForm .MuiOutlinedInput-root.Mui-focused,
.dateFilter .MuiOutlinedInput-notchedOutline,
.jobs-run-table .dateFilter .editJobForm .MuiOutlinedInput-root.Mui-focused {
	border-color: #71758f;
}

.jobs-list-datas .dateFilter-formControl .MuiInputBase-root:hover,
.jobs-list-table .dateFilter-formControl .MuiInputBase-root:hover,
.jobs-run-table .dateFilter-formControl .MuiInputBase-root:hover {
	border-bottom: 1px solid #00d08d;
	border-radius: 0px;
}

.jobs-list-datas .MuiButtonBase-root,
.jobs-list-table .MuiButtonBase-root,
.jobs-run-table .MuiButtonBase-root {
	border-radius: 0px;
	background: transparent;
	color: white;
}
.jobs-list-datas .MuiPickersPopper-root .MuiTypography-root.Mui-selected,
.jobs-list-table .MuiPickersPopper-root .MuiTypography-root.Mui-selected,
.jobs-run-table .MuiPickersPopper-root .MuiTypography-root.Mui-selected {
	font-size: 15px;
	color: #00d08d;
	padding: 0px;
	border: none;
}

.jobs-list-datas .MuiPickersDay-root.Mui-selected,
.jobs-list-table .MuiPickersDay-root.Mui-selected,
.jobs-run-table .MuiPickersDay-root.Mui-selected {
	background-color: #00d08d !important;
}
.jobs-list-datas .MuiPickersPopper-root,
.jobs-list-table .MuiPickersPopper-root,
.jobs-run-table .MuiPickersPopper-root {
	top: 0px;
}
.jobs-list-datas .MuiDateTimePickerToolbar-separator,
.jobs-list-table .MuiDateTimePickerToolbar-separator,
.jobs-run-table .MuiDateTimePickerToolbar-separator {
	padding: 10px;
}
.jobs-list-datas .MuiPickersPopper-root .MuiClockNumber-root,
.jobs-list-table .MuiPickersPopper-root .MuiClockNumber-root,
.jobs-run-table .MuiPickersPopper-root .MuiClockNumber-root {
	color: white;
}
.jobs-list-datas .MuiPickersPopper-root .MuiClockPicker-root,
.jobs-list-table .MuiPickersPopper-root .MuiClockPicker-root,
.jobs-run-table .MuiPickersPopper-root .MuiClockPicker-root {
	margin-top: 10px;
}
.jobs-list-datas .MuiDateTimePickerToolbar-dateContainer .MuiTypography-root,
.jobs-list-datas .MuiPickersPopper-root .MuiTypography-root,
.jobs-list-table .MuiDateTimePickerToolbar-dateContainer .MuiTypography-root,
.jobs-list-table .MuiPickersPopper-root .MuiTypography-root,
.jobs-run-table .MuiPickersPopper-root .MuiTypography-root {
	font-family: Montserrat !important;
	color: #f5f5f5;
	text-transform: unset;
	font-size: 15px;
	font-weight: 700px;
}
.jobs-list-datas .MuiDateTimePickerToolbar-root .time-picker-zone,
.jobs-list-table .MuiDateTimePickerToolbar-root .time-picker-zone,
.jobs-run-table .MuiDateTimePickerToolbar-root .time-picker-zone {
	margin-top: 15%;
	color: white;
	margin-bottom: 10px;
}
.jobs-list-table {
	overflow: hidden !important;
}
.jobs-list-datas #root,
.jobs-list-table #root {
	overflow: hidden !important;
}
.jobs-list-table #root.jobs-list {
	overflow: auto !important;
}
.root,
#root {
	height: 100%;
	overflow: auto;
	max-height: 100%;
}
