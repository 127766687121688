@import '../../theme-variables.scss';

.base-tab {
    margin-top: 15vh;
    margin-left: 4vh;
    margin-right: 4vh;

    #simple-tabpanel-0,
    #simple-tabpanel-1,
    #simple-tabpanel-2,
    #simple-tabpanel-3,
    #simple-tabpanel-4,
    #simple-tabpanel-5 {
        background-color: $color-secondary !important;
        margin-top: 0px;
        border-radius: 0%;
    }
}

/* MUI Tabs */
.MuiTab-root {
    color: $color-grey;
    font-family: 'Montserrat';
    font-weight: $font-weight-500;
    font-size: $font-size-small;
    width: 34vh;
    background: $color-primary-light;
    margin-right: 1px;
    min-height: 30px !important;
    position: relative;
    top: 5px;

    .Mui-disabled {
        color: $color-grey-md;
        pointer-events: unset;
        cursor: not-allowed;
    }

    .Mui-selected {
        border-radius: 4px;
        color: $color-tertiary !important;
        background: $color-primary !important;
        font-weight: $font-weight-700 !important;
    }
}
.tab-zero-width{
    width : auto !important
}

@media screen and (max-width: 992px) {
    .base-tab {
        margin-top: 14vh;
        margin-left: 4vh;
        margin-right: 4vh;
    }

    .MuiTab-root {
        position: relative;
        top: 5px;
    }
}