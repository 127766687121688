body{
    height: 100%;
    overflow: auto;
}.outerLoginContainer{
    background: url('../../assets/login_actual_image.svg')no-repeat;
    font-family: 'Montserrat';
    background-position: left;
    background-size: cover;
    height: 100%;
    position: fixed;
    width: 100% !important;
    max-height: 100%;
}@font-face{
    font-family: 'Montserrat';
    font-style: normal;
	src: url('../../assets/Fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('ttf');
}.login-para{
    font-family: 'Montserrat' !important;
    font-size: 14px !important;
    color: #ffffffd6;
    font-weight: 800;
    line-height: 18px !important;
    margin-left: 14% !important;
    margin-bottom: 10px;
}.login-continue{
    clear: both;
    display: block;
    color: white;
    width: 80%;
    font-size: 16px !important;
    float: left;
    margin-top: 40px !important;
    margin-left: 14% !important;
    font-family: 'Montserrat';
    font-weight: bold !important;
}.login-textbox{
    top: 20px;
    background: rgba(113, 117, 143, 0.4);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin-left: 14% !important;
    margin-bottom: 10px !important;
    font-family: 'Montserrat';
    width: 67%;
}.login-textbox::placeholder{
    font-size: 12px !important;
    font-weight: 100 !important;
    font-style: italic !important;
    font-family: 'Montserrat';
}.login-below-text{
    margin-left: 14%;
    margin-top: 10px;
    width: 67%;
}.MuiTypography-root{
    font-family: Montserrat !important;
}.warning-txt{
    top: 13px;
    color: red;
    position: relative;
    padding-left: 20%;
    text-align: left;
    font-size: 14px;
}.css-10drtbx-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: #00D08D !important;
}.css-10drtbx-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: #00D08D !important;
}#searchFieldTextUser,#searchFieldTextUser:placeholder-shown{
    background: transparent;
    font-family: 'Montserrat' !important;
    font-size: 16px;
    color: #F5F5F5 !important;
    font-weight: 500;
}#searchFieldpassword {
    color: #F5F5F5 !important;
    font-size: 16px;
    font-weight: 500;
}.login-below-text .MuiCheckbox-root{
    width: 18px;
    height: 18px;
    margin-left: 10px;
    margin-right: 10px;
}.login-below-text label{margin-top: 10px;}
.login-below-text .MuiCheckbox-root.MuiCheckbox-root.Mui-checked{
    margin-top: 0px;
}.MuiInputBase-root .MuiInputBase-input:-webkit-autofill {
    background: rgba(113, 117, 143, 0.4);
    transition-delay: 9999s;
}.ErrorTxt{
    top: 20px;
	width: 67%;
	background: rgba(113, 117, 143, 0.4);
	backdrop-filter: blur(10px);
	border-radius: 4px;
	margin-left: 14% !important;
    margin-bottom: 10px !important;
	font-family: Montserrat !important;
    border-bottom: 2px solid red;
}.ErrorTxt input::placeholder{
    font-size: 12px;
    font-family: Montserrat !important;
    font-style: italic;
}.ErrorTxt .MuiInput-root::after{
    border-bottom: 2px solid transparent;
}.login-below-text .MuiCheckbox-root.Mui-checked{
    background-color: white;
}.MuiInput-root::after{
    border-bottom: 2px solid #00D08D;
}