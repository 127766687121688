.base-tab-datagrowth {
    margin-top: 8vh;
    margin-left: 4vh;
    margin-right: 4vh;
}
.base-tab-datagrowth #simple-tabpanel-0,#simple-tabpanel-1,#simple-tabpanel-2,#simple-tabpanel-3,#simple-tabpanel-4,#simple-tabpanel-5{
    background-color: #1b1e35 !important;
    margin-top: 0px;
    border-radius: 0%;
}
/* MUI Tabs */
.base-tab-datagrowth .MuiTab-root {
	color: #e5e5e5;
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 12px;
    width: 185vh;
    background: #11142a;
    margin-right: 1px; 
    min-height: 30px !important;
    position: relative;
    top: 5px;    
    text-transform: capitalize;

}

.base-tab-datagrowth .PBITabs .MuiTab-root.Mui-disabled {
	color: #71758f;
	pointer-events: unset;
	cursor: not-allowed;
}
.base-tab-datagrowth .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    max-width: 635px;
    min-width: 90px;
}
.base-tab-datagrowth .MuiTab-root.Mui-selected {
    border-radius: 4px;
    color: #00d08d !important;
        background: #0c0f25 !important;
        /* font-weight: 700 !important; */
    }
    
        @media screen and (max-width: 992px) {
            .base-tab-datagrowth {
                margin-top: 14vh;
                margin-left: 4vh;
                margin-right: 4vh;
            }
    
            .base-tab-datagrowth .MuiTab-root {
                position: relative;
                top: 5px;   
                width: 98vh;
            }
        }



