.login-footer-text {
	text-transform: capitalize;
	position: absolute;
	bottom: 0px;
	text-align: center;
	width: 100%;
	color: #e5e5e5;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 25px;
	}
