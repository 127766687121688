@import '../../theme-variables.scss';

.main-content {
    height: 100vh;
    overflow-y: auto;
}

.dataestate-dashboard-wrap {
    color: #E5E5E5;
    margin-top: 130px;
    padding: 0 24px;
}

.theme-bg-blue .loader {
    z-index: 9;
}

.w-190 {
    width: 190px !important;
}

.service-sector-filter {
    background-color: #00377144;
    border-radius: 4px;
    position: fixed;
    right: 35px;
    top: 85px;
    z-index: 999;
    width: 160px;
    min-height: 43px;

    .sector-label {
        line-height: 30px;
        vertical-align: top;
        display: inline-block;
        margin-top: 5px;
        margin-left: 10px;
    }

    .sector-cta {
        cursor: pointer;
        display: inline-block;
        position: relative;
    }

    svg {
        height: 40px;
        width: 40px;
        position: absolute;
        right: 0;
    }
}

.service-sector-filter-cost {
    width: 115px !important;
    right: 60px !important;
}

.service-date-filter {
    background-color: #00377133;
    border-radius: 4px;
    font-size: 15px;
    position: fixed;
    right: 200px;
    top: 85px;
    z-index: 999;
    min-width: 200px;
    text-align: left;

    fieldset {
        display: none;
    }

    .date-icon {
        position: absolute;
        right: 10px;
        top: 8px;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 10px 42px 10px 10px;
    }
}

.panel-legend {
    background-color: rgba(0, 55, 113, 0.2);
    border-radius: 4px;
    padding: 15px;

    .panel-legend--title {
        font-size: 16px;
        font-weight: normal;
        margin-top: 0;
    }

    .panel-box {
        background-color: rgba(0, 55, 113, 1);
        border-radius: 4px;
        padding: 10px 15px;

        .panel-box--title {
            font-size: 14px;
            font-weight: normal;
            min-height: 30px;
            margin-top: 0;
        }

        .panel-box--count {
            font-size: 30px;
            font-weight: 600;
        }

        .conversion {
            font-size: 14px;
        }
    }
}

.service-request-wrap {
    .panel-legend {
        .panel-legend--title {
            font-size: 14px;
        }
    }

    .panel-box {
        padding: 10px;

        .panel-box--title {
            font-size: 12px;
        }
    }

    .small-boxes {
        .panel-box {
            .panel-box--title {
                min-height: 33px;
            }

            .panel-box--count {
                font-size: 18px;
            }
        }
    }
}

.mr-10 {
    margin-right: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-15 {
    margin-right: 15px;
}

.mb-0 {
    margin-bottom: 0;
}

.height-230 {
    height: 230px;
    overflow: hidden;
}

.costsummary-sector-filter {
    top: 70px !important;
    right: 50px !important;
}