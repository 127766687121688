.page-table {
	padding: 0px 16px;
	margin-top: 130px;
}
.page-table-ibp-monitor {
	margin-top: 20px;
}
.page-table-ibp-cost {
	margin-top: 20px;
}
.page-table-ibp-platform {
	margin-top: 20px;
}
.page-table-ibp-data-quality {
	margin-top: 20px;
}
.page-table-ibp-obs {
	margin-top: 20px;

	.breadcrumb {
		margin-bottom: 30px;
		margin-top: 80px;

		img {
			height: 30px;
			margin-top: 0;
		}

		.titleLink,
		.page-title {
			font-size: 16px !important;
		}
	}
}
.jobs_tabledata .rdg {
	background: #0c0f25;
	height: calc(100vh - 250px);
	border: none;
	overflow-y: auto !important;
}

.jobs_tabledata .rdg-cell {
	font-weight: 300;
	padding: 4px 7px 4px 7px !important;
	color: #e5e5e5;
	border: none;
	line-height: 28px;
	font-size: 12px;
}


.jobs_tabledata .rdg-cell:hover {
	overflow: visible;
	z-index: 111;
	max-width: initial;
	background: #0c0f25;
}

.jobs_tabledata .rdg-cell[aria-selected='true'] {
	outline: none;
}

.jobs_tabledata .rdg-cell a {
	color: white;
	text-decoration: none;
}

.jobs_tabledata .rdg-cell[aria-sort='descending'] .rdg-sort-arrow,
.jobs_tabledata .rdg-cell[aria-sort='ascending'] .rdg-sort-arrow {
	fill: none;
	height: 20px;
}

.jobs_tabledata .rdg-row:nth-child(even) {
	background: none;
}

.jobs_tabledata .rdg-header-row {
	background: #1b1e35;
}

.jobs_tabledata .rdg-row:nth-child(odd) {
	background: rgba(113, 117, 143, 0.05);
}

.jobs_tabledata .rdg-row:nth-child(odd) .rdg-cell {
	border-right: 1.5px solid #1b1e35;
}

.jobs_tabledata .rdg-header-row .rdg-cell {
	border-right: 1.5px solid #0c0f25;
}

.jobs_tabledata .rdg-cell:last-child {
	border-right: none !important;
}

.jobs_tabledata .reasonForDelay:hover {
	position: absolute;
}

.jobs_tabledata .highlightClass .rdg-cell {
	background-color: #71758f66;
	border-right: none !important;
}

.jobs_tabledata .highlightClass:hover .rdg-cell {
	background-color: #71758f66;
}