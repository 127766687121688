.titleLink {
	text-decoration: none;
	color: #71758f !important;
	font-family: 'Montserrat' !important;
	font-weight: 600 !important;
	font-size: 20px !important;
	margin: 0 3px !important;
}
.breadcrumb {
	width: 40%;
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	margin-top: 110px;
	z-index: 10000;
	align-items: center;
}
.breadcrumb img {
	float: left;
	margin-top: 10px;
}
.breadcrumb .titleLink {
	float: left;
	margin-top: 5px;
}
.breadcrumb .page-title {
	float: left;
	display: inline-block;
	max-width: 450px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #e5e5e5;
	font-size: 20px;
	font-weight: 600;
	vertical-align: initial;
	white-space: nowrap;
}
.breadcrumb .symbol {
	font-size: 20px;
	color: #71758f;
	display: inline-block;
	margin-right: 5px;
	font-weight: 600;
	margin-top: 10px;
}

.breadcrumb .page-title:hover {
	overflow: visible;
	display: block;
	background: #0c0f25;
	width: max-content;
	z-index: 1;
	max-width: max-content;
}
.page-table {
	padding: 0px 16px;
}

.job_details_page_table {
	margin-top: 60px;
	margin-left:20px;
	width : 96% !important;
}
.verAlign {
	vertical-align: text-bottom;
	margin:6px !important;
}
/* MUI Tabs */
.MuiTab-root {
	color: #e5e5e5;
	font-family: 'Montserrat';
	font-weight: 800;
	font-size: 16px;
}

.MuiTab-root.Mui-selected {
	border-radius: 10px 10px 0px 0px;
	color: #00d08d;
	background: #1b1e35 !important;
}

.MuiTabs-indicator {
	display: none !important;
}

.JDTabs .MuiTab-root.Mui-disabled {
	color: #71758f;
	pointer-events: unset;
	cursor: not-allowed;
}

.MuiBox-root {
	border-bottom: none !important;
}
