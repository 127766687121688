@import '../../theme-variables.scss';

.outerLandingPageContainer {
	width: 100%;
	overflow-y: none;
	max-height: 100vh;
}

.message {
	margin-top: 80px;
	color: $color-grey;
	font-family: Montserrat;
	font-size: $font-size-52;
	font-style: normal;
	font-weight: $font-weight-700;
}

.landingMessage {
	margin-top: 55px;
	color: $color-grey;
	font-family: Montserrat;
	font-size: $font-size-52;
	font-style: normal;
	font-weight: $font-weight-700;
}
.landingMessage-container {
    display: flex;        
    align-items: center;  
}

.landingMessageImage{
    margin-right: 8px; 
   cursor: pointer;
}

.welcome_text {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	p {
		color: $color-grey;
		font-family: Montserrat;
		font-size: $font-size-28;
		font-style: normal;
		font-weight: $font-weight-500;
		margin-top: 9px;
	}
}

.type {
	color:$color-grey-md;
	font-family: Montserrat;
	font-size: $font-size-15;
	font-style: normal;
	font-weight: $font-weight-600;
	margin-right: 10px;
}

.user_type {
	color: #f5f5f5;
	font-family: Montserrat;
	font-size: $font-size-15;
	font-style: normal;
	font-weight: $font-weight-500;
}

.multiple_role {
	margin-bottom: 8px;
	color: #71758f;
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	margin-right: 10px;
}

.role_info {
	height: 15px;
	margin-left: 2px;
}

.module_screens {
	margin-top: 80px;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.observalbility_screens {
	min-height: 160px;
	background-color: $color-blue;
	padding: 26px 29px;
	position: relative;
	cursor: pointer;
}
.observalbility_screens1 {
	min-height: 160px;
	background-color: #0c2d50;
	padding: 26px 29px;
	position: relative;
	//cursor: pointer;
}
.landing_observalbility_screens {
	min-height: 62px;
	background-color: $color-blue;
	padding: 26px 29px;
	position: relative;
}
.observalbility_screens_blank {
	min-height: 90px;
	background-color: none;
	padding: 26px 29px;
	width: 44.8%;
	position: relative;
}

.title {
	display: flex;
	align-items: center;

	// to be removed
	.obs_logo {
		margin-right: 10px;
		height: 44px;
		width: 44px;
	}
	.icon-style {
		font-size: 44px;
		margin-right: 10px;
	}
}

.obsTitle {
	color: $color-grey;
	font-family: Montserrat;
	// font-size: $font-size-17;
	font-style: normal;
	font-weight: $font-weight-800;
	font-size: min(1.2vw, 18px);
}
.obsTitle1 {
	color: #71758F;
	font-family: Montserrat;
	// font-size: $font-size-17;
	font-style: normal;
	font-weight: $font-weight-800;
	font-size: min(1.2vw, 18px);
}
.tooltip-container {
    position: relative;
    display: inline-block;
    max-width: 100%; 
}

.message1 {
    margin-top: 10px;
    margin-bottom: 20px; 
    color: #e5e5e5;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2; 
    max-height: 3.6em; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limits the text to 3 lines */
    -webkit-box-orient: vertical;
    white-space: normal;
    cursor: pointer;
}
.message2 {
    margin-top: 10px;
    margin-bottom: 20px; 
    color: #71758F;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2; 
    max-height: 3.6em; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limits the text to 3 lines */
    -webkit-box-orient: vertical;
    white-space: normal;
    cursor: pointer;
}

.tooltip-text {
    visibility: hidden;
    width: 300px; 
    max-width: 400px; 
    background-color: #1b1e35;
    color: #fff;
    text-align: left;
    padding: 8px;
    border-radius: 4px;
    position: absolute;
    bottom: 125%; /* Position above the text */
    left: 50%;
    margin-left: -100px; 
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    white-space: normal; 
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.click_button_container {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 10px;
}

.click_button {
	color: $color-tertiary;
	font-family: Montserrat;
	font-size: $font-size-larger;
	font-style: normal;
	font-weight: $font-weight-600;
	border: none;
	text-transform: none;
	cursor: pointer;
	padding-left: 0px;
}

.last_login {
	margin-top: 10px;
}

.sector_type {
	margin-top: 10px;
}

.login_details {
	margin-right: 12px;
}

.module_container {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.sector {
	color: $color-grey-md;
	font-family: Montserrat;
	font-size: $font-size-15;
	font-style: normal;
	font-weight: $font-weight-600;
	margin-right: 35px;
	margin-top: 20px;
}

.observalbility_heading {
	background-color: $color-secondary;
	padding: 20px 29px;
	position: relative;
	margin-top: 50px;
	font-weight: $font-weight-700;
	font-size: $font-size-larger;
	color: thistle;
	font-style: italic;
}

@media screen and (max-width: 1600px) {
	.observalbility_screens_blank {
		width: 44%;
		margin-right: 10px;
	}

	.message {
		font-size: 40px;
	}

	.module_screens {
		margin-top: 50px;
	}

	.module_container {
		margin-top: 10px;
	}
}

@media screen and (min-width: 1600px) {
	.observalbility_screens_blank {
		width: 48%;
		margin-right: 10px;
	}

	.message {
		font-size: 40px;
	}

	// .landing_page_footer {
	// 	position: absolute;
	// 	bottom: 0;
	// }

	.module_screens {
		margin-top: 50px;
	}

	.module_container {
		margin-top: 10px;
	}
}

@media screen and (max-width: 1300px) {
	.observalbility_screens {
		min-height: 170px;
	}
}