.panel {
	background: #1b1e35;
	height: 100%;
	width: 500px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1000;
	overflow: auto;
	box-shadow: -4px 0px 20px 0px #00000099;
}

.container {
	width: 440px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #71758f33;
}

.icon-container {
	width: 440px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 3px;
}

.data-container {
	width: 500px;
	min-height: 60px;
	border-bottom: 1px solid #71758f33;
}

.inner-circle {
	border-radius: 50%;
	width: 10px;
	height: 10px;
	position: absolute;
	background-color: red;
	right: 10px;
	top: 10px;
	z-index: -1;
}
.rule-title {
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	position: relative;
	width: 440px !important;
}
.innerrules {
	border-left: 1px solid #71758f;
	padding: 0px 10px;
}
.rule-title img {
	position: absolute;
	top: -2px;
}
.rule-title span {
	margin-left: 35px;
	white-space: break-spaces;
    display: block;
}

.rule-title-banner {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	padding: 18px 12px 0px 12px;
}

.observation-count {
	background: #fe2626;
	width: 30px;
	height: 30px;
	border-radius: 50px;
	line-height: 30px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
}

.panel-title {
	margin: 0;
	font-family: Montserrat;
	font-size: 14px;
	font-style: italic;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	margin-left: 20px;
}

.icons {
	display: flex;
	align-items: center;
	margin-right: -38px;
}

.icons img {
	width: 24px;
	/* Adjust icon width */
	height: 24px;
	/* Adjust icon height */
	margin-left: 5px;
	/* Adjust spacing between icons */
}

.icons .icon-close {
	cursor: pointer;
}

.icons-list .icon-group {
	display: inline-block;
	width: 52px;
	height: 52px;
	background: #71758F0D;
	line-height: 62px;
	text-align: center;
	margin-right: 3px;
	position: relative;
}

.icon-group {
	position: relative;
}
.icons-list .icon-group svg.rule-engine-icon {
	cursor: pointer;
	width: 24px !important;
	height: 24px !important;
}
.icons-list .icon-group.active {
	background-color: #1b1e35;
}
.icons-list .icon-group.active svg.rule-engine-icon {
	cursor: default;
}
.icons-list .icon-group svg.rule-engine-icon path {
	fill: #fff;
}
.icons-list .icon-group.active svg.rule-engine-icon path {
	fill: #00d08d;
}

.selectedRule {
	font-family: Montserrat;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #00d08d;
	padding: 20px;
}
.inner-observation {
	display: flex;
	justify-content: space-between;
	margin-bottom: 7px;
}
.inner-observation .title {
	font-weight: 600;
	color: #71758F;
}
.inner-observations-name {
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
	color: #e6e6e6;
}
.inner-observations-name1 {
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
	color: #e6e6e6;
	width: 125px;
	white-space: nowrap;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
}
.inner-observations-count {
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: right;
	color: #e6e6e6;
}

.view-details {
	margin-bottom: 10px;
}

.view-details span {
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	color: #00d08d;
	margin-right: 5px;
}
.view-details .date {
	font-family: Montserrat;
	font-size: 12px;
	font-style: italic;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: #71758F;
	padding-left: 10px;
	position: relative;
}
.view-details .link {
	position: absolute !important;
    right: 30px;
    bottom: 0px;
    color: #00d08d !important;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}
.view-details .icon {
	position: absolute;
    right: 12px;
    bottom: -5px;
}
.view-details  .link a {
	
    color: #00d08d !important;
	text-decoration: none;
}

.title1 {
	color: #71758F;
	white-space: pre-wrap;
	text-align: right !important;
}

.controls_tools {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-group1 {
    display: flex;
    align-items: center;
	cursor: pointer;
	background-color: rgba(113, 117, 143, 0.05);
	border-radius:15px;
    border: 2px solid transparent; 
    margin-right: 8px; 
    font-family: "Montserrat";
	position: relative;
}

.tool_title {
    margin-top: 5px; /* Adjust as needed */
    margin-right: 25px;
	position: relative; 
}

.tool_title .inner-circle {
    position: absolute;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: red;
    right: -10px;
    top: -5px; 
    z-index: -1;
}

.icon-group1.active .rule-engine-icon {
    fill: #00d08d; 
}

.active .tool_title {
    color:  #00d08d; 
}
.VV-iconStyle {
    width: 25px;
    padding: 5px;
    height: 40px;
}
.icon-group1.active .rule-engine-icon,
.icon-group1.active .rule-engine-icon path {
    fill: #00d08d;
}