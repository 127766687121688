.machines-table .MuiChip-filled {
	height: 20px;
	width: 85px;
}
.machines-table .MuiChip-label {
	padding: 0 10px;
	font-size: 10px;
}
/* MUI chips */
.MuiChip-colorSuccess {
	background-color: #17372e;
	color: #01c008;
}

.MuiChip-colorSecondary {
	background-color: #3d2033;
	color: #cd2429;
}

.MuiChip-colorWarning {
	background-color: #ffa20033;
	color: #ffa200;
}

.MuiChip-colorDefault {
	background-color: #0c0f25;
	color: #e5e5e5;
	margin-bottom: 10px;
	max-width: 106px;
	margin-right: 10px;
	height: 24px;
}