.model-overview-table .attrTitle {
	font-size: 12px;
	padding-bottom: 20px;
	vertical-align: top;
	min-width: 60px;
	width: 14%;
	max-width: 140px;
	color: #71758f;
}
.model-overview-table .attrValue {
	font-size: 12px;
	padding-bottom: 20px;
	width: 25%;
	max-width: 25%;
	word-break: break-all;
	vertical-align: top;
}
.page-table {
	padding: 0px 16px;
}

.performance-overview-table .attrTitle {
	font-size: 12px;
	padding-bottom: 20px;
	/* vertical-align: top; */
	min-width: 60px;
	width: 30%;
	max-width: 140px;
	color: #71758f;
}
.performance-overview-table .attrValue {
	font-size: 12px;
	padding-bottom: 20px;
	width: 15%;
	max-width: 25%;
	word-break: break-all;
	/* vertical-align: top; */
}

.models_tabledata .rdg {
	background: #0c0f25;
	height: calc(100vh - 250px);
	border: none;
	overflow-y: auto !important;
}
.feature-drift-table .rdg {
	background: #0c0f25;
	height: fit-content;
	border: none;
}
.feature-drift-table .darkPagination {
	height: 50px !important;
	width: 325px !important;
	margin-left: 15px !important;
}
.feature-drift-table .customPaginationText {
	margin-top: 15px !important;
	margin-left: 5px !important;
}
.accordin-title {
	font-family: Montserrat;
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}
.accordin-subtitle {
	font-family: Montserrat;
	font-size: 16px;
	font-style: italic;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	padding-left: 5px;
	color: #e5e5e5;
}
.models_tabledata .rdg-cell {
	font-weight: 300;
	padding: 4px 7px 4px 10px !important;
	color: white;
	border: none;
	line-height: 28px;
	font-size: 12px;
}

.models_tabledata .rdg-cell[aria-selected='true'] {
	outline: none;
}

.models_tabledata .rdg-cell:hover {
	overflow: visible;
	z-index: 111;
	max-width: initial;
	background: #0c0f25;
}

.models_tabledata .rdg-cell[aria-sort='descending'] .rdg-sort-arrow,
.models_tabledata .rdg-cell[aria-sort='ascending'] .rdg-sort-arrow {
	fill: none;
	height: 20px;
}

.models_tabledata .rdg-row:nth-child(even) {
	background: none;
}

.models_tabledata .rdg-header-row {
	background: #1b1e35;
}

.models_tabledata .rdg-row:nth-child(odd) {
	background: rgba(113, 117, 143, 0.05);
}

.models_tabledata .rdg-row:nth-child(odd) .rdg-cell {
	border-right: 1.5px solid #1b1e35;
}

.models_tabledata .rdg-header-row .rdg-cell {
	border-right: 1.5px solid #0c0f25;
}

.models_tabledata .rdg-cell:last-child {
	border-right: none !important;
}
.models_tabledata .reasonForDelay:hover {
	position: absolute;
}

.models_tabledata .highlightClass .rdg-cell {
	background-color: #71758f66;
	border-right: none !important;
}

.models_tabledata .highlightClass:hover .rdg-cell {
	background-color: #71758f66;
}

.models_tabledata .selectedClass .rdg-cell {
	border: 1px solid !important;
	border-right: none !important;
	border-left: none !important;
	border-color: #00d08d !important;
}

.models_tabledata .selectedClass .rdg-cell:last-child {
	border-right: 1px solid #00d08d !important;
}
.models_tabledata .selectedClass .rdg-cell:first-child {
	border-left: 1px solid #00d08d !important;
}

.models_tabledata .drifted .rdg-cell {
	color: #ff6666 !important;
}
.models_tabledata .rdg-cell a {
	color: white;
	text-decoration: none;
}

.models_tabledata .drifted .rdg-cell a {
	color: #ff6666;
	text-decoration: none;
}

.modelsNameList:hover {
	background: #0c0f25;
	padding: 10px !important;
	border: 1px solid #71758f;
}

.model-details-panel-stack {
	padding: 0px 10px 10px 35px;
}

.w-full {
	width: 100%;
}
.mt-105 {
	margin-top: 105px;
}
.mt-95 {
	margin-top: 124px;
}
.mt-12px {
	margin-top: 12px;
}
.mt-15 {
	margin-top: 15px;
}
.ml-5 {
	margin-left: 5px !important;
}
.according-expand-icon {
	color: #fff;
}
.feature-drift-table {
	margin-top: 0 !important;
	padding: 0 !important;
}
.info-icon {
	width: 20px;
	height: 20px;
	position: relative;
	left: 5px;
}

.filterContainerml .MuiPaper-root {
	height: min-content;
	width: 200px !important;
	padding: 0px;
	margin-top: 30px;
}

/* .filter-Container {
	height: min-content;
	width: 200px !important;
	padding: 0px;
	margin-top: 30px;

} */

.selectDate {
	padding: 5px;
	margin: 5px;
}
@media (min-width: 900px) {
    .feature-drift-visualization {
        width: calc(38% + 45px) !important;
    }
}
@media (max-width: 1400px) {
    .feature-drift-visualization {
        width: calc(39% - -150px) !important;
    }
}
