.pieChart {
    position: absolute;
    left: 64px;
    bottom: auto;
}
.pieCenterLabel {
    fill: #9c9c9c;
    position: relative;
    width: 130px;
    text-align: center;
    font-family: sans-serif;
}
.pieCenterLabel .dotted-circle {
    position: absolute;
    width: 29px;
    height: 27px;
    border: 2px dashed #555555;
    border-radius: 50px;
    padding: 23px;
    top: -257px;
    left: 178px;
}
.pieCenterLabel .line1 {
    top: -235px;
    left: 173px;
    position: absolute;
    width: 90px;
    height: 90px;
    font-size: 10px;
    margin-left: auto;
    margin-right: auto;
}
.pieCenterLabel .line2 {
    top: -215px;
    left: 189px;
    position: absolute;
    width: 60px;
    height: 60px;
    font-size: 18px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}