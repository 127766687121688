.machineLearning_title_div {
	display: flex;
	align-items: center;
	margin-top: -20px;
	color: #e5e5e5;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-left: 18px;
}

.machineLearning_title {
	color: #fff;
	font-family: Montserrat;
	font-size: 24px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
	text-transform: uppercase;
	margin-left: 10px;
}

.sector-filter, .inference-filter {
	top: 82px;
	left: 95%;
	position: absolute;
}

.sector-filter-text-ml {
	top: 85px;
	left: 89%;
	position: absolute;
}

.inference-filter-text {
	top: 86px;
	left: 85%;
	/* border: 1px solid; */
	position: absolute;
	background:#1b1e35;
}

.ml-model-menu {
	color: #e5e5e5;
	font-size: 20px;
	font-weight: 600;
	font-family: 'Montserrat';
	padding-top: 20px;
	position: absolute;
	margin-left: -14px;
}

.ml-icon {
	width: 40px;
	margin-top: 40px;
	margin-left: 15px;
	padding-top: 0px;
	position: absolute;
}

.ml-header-name {
	position: absolute;
	margin-left: 40px;
	margin-top: 29px;
	color: #ffffff;
	padding: 20px;
}

.ml-page-title {
	color: #e5e5e5;
	font-size: 18px;
	font-weight: 600;
	font-family: 'Montserrat';
	position: absolute;
	top: 79px;
	left: 200px;
}

.ml-symbol {
	color: #71758f;
	font-size: 20px;
	font-weight: 600;
	font-family: 'Montserrat';
	position: absolute;
	top: 79px;
	left: 178px;
}