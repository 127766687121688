@import '../../theme-variables.scss';

.main-content {
    height: 100vh;
    overflow-y: auto;
}

.dashboard-panel-dataestate {
    margin-top: 135px !important;
    .loader {
        z-index: 9;
    }
    .headerIPB-title {
        line-height: 20px;
    }
    .incidents_tabledata {
        .MuiChip-filled {
            width: 90px;
        }
        .MuiChip-filled,
        .MuiChip-label {
            margin: 0;
            font-family: "Montserrat";
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}

.incidents_tabledata-details {
    margin-top: 140px !important;
    .filter-icon {
        position: relative;
        span img {
            user-select: none;
            opacity: .5;
        }
        &:before {
            content: '';
            position: absolute;
            width: 25px;
            height: 30px;
            right: 0;
            background-color: transparent;
            z-index: 999;
        }
    }
}

.subheader-dataestate {
    font-size: 30px;
    font-weight: 600;
	text-align: center;
}
.monitor-head-dataestate {
    line-height: 20px;
    text-align: center;
}
.monitor-head {
    position: relative;
    .badge {
        position: absolute;
        right: 5px;
        top: 0;
        padding: 1px 8px;
        border-radius: 50px;
        background-color: #009BD8;
        color: #001E3E;
        font-size: 12px;
        font-weight: 600;
        z-index: 9;
    }
    .badge-sec {
        top: 33px;
    }
}
.arrow-icon-styles-up-black {
    width: 15px !important;
    height: 10px;
    &.down-arrow {
        transform: rotate(180deg);
    }
}
.dataestate-title {
    flex: 1 1 auto;
    border-radius: 4px;
    margin: 10px 0 5px 6px;
    font-size: 14px;
    font-weight: normal;
    line-height: 40px;
    // padding: 50px;
    position: relative;
}
.dataestate-title-right {
    flex: 1 1 auto;
    border-radius: 4px;
    margin: 15px 3px 0px 3px;
    font-size: 14px;
    font-weight: normal;
    line-height: 40px;
    padding: 50px;
    position: relative;
    height: 31.6vh;
}
.custom-grid-data-storage {
    .MuiGrid-item {
        max-width: 20%;
    }
}
.chart-widget {
    min-height: 61vh;
    height: 61vh;
}

.headerdataestate-title {
    flex: 1 1 auto; 
    border-radius: 4px;
    margin: 8px 12px 0 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    padding: 15px;
    position: relative;
    height: 20vh;
}
.dataestate-subheader {
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;
}
.subheader-text{
    font-size: 14px;  
}
.subheader-right{
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;

}
.headerdataestate-title-rt {
    flex: 1 1 auto; 
    border-radius: 4px;
    margin: 8px 1% 0 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    padding: 15px;
    position: relative;
    height: 20vh;
    right: 5px;
}

.dataestate-title-process {
    flex: 1 1 auto; 
    border-radius: 4px;
    margin: 15px 0px 0 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    padding: 15px;
    position: relative;
    height: 30vh;
}
.dataestate-dashboard-wrapper {
    color: white;
    margin-bottom: 30px;
    .ibp-header {
        .ibp-header--title {
            border-right: 5px solid rgba(112, 173, 71, 1);
            border-radius: 4px;
            margin-left: 1%;
            display: inline-block;
            font-size: 22px;
            font-weight: 600;
            line-height: 30px;
            padding: 20px;
            position: relative;
            max-width: 32.6%;
        }
    }


	.last-refresh-box {
		padding: 10px;
		max-width: 33%;

		.last-refresh-label {
			font-size: .875rem;
			font-weight: normal;
			margin-top: 0;
		}
		.last-refresh-value {
			font-size: 20px;
			font-weight: 600;
			margin-top: 35px;
		}
	}

    .no-mr {
        margin-right: 0 !important;
    }
    .no-ml {
        margin-left: 0 !important;
    }
    .mb-5 {
        margin-bottom: 5px;
    }
    .mb-10 {
        margin-bottom: 10px;
    }
	.mt-10 {
		margin-top: 10px !important;
	}

    .wid-80 {
        display: inline-block;
        width: 80%;
    }

    .wid-18 {
        display: inline-block;
        width: 18%;
    }

    .pad-15 {
        padding: 16.1px 0 !important;
    }

    .pad-20 {
        padding: 20px 0 !important;
    }

    .pad-25 {
        padding: 26.4px 0 !important;
    }
	.bdr-none {
		border: none !important;
	}
    .bdr-yellow {
        border-color: rgba(252, 192, 70, 1) !important;
    }
    .bdr-red {
        border-color: rgba(254, 38, 38, 1) !important;
    }
	.bdr-blue {
        border-color: rgba(0, 145, 255, 0.874) !important;
    }
	.bdr-light-blue {
        border-color: rgba(41, 155, 255, 1) !important;
	}


    .bgcolor-green {
        background-color: rgba(112, 173, 71, 0.2) !important;
    }
    .bgcolor-maroon {
        background-color: rgba(252, 192, 70, 0.2) !important;
    }
    .bgcolor-brown {
        background-color: rgba(254, 38, 38, 0.2) !important;
    }
    .bgcolor-red {
        background-color: rgba(254, 38, 38, 0.2) !important;
    }
    .bgcolor-grey {
        background-color: rgba(27, 30, 53, 1) !important;
    }
	.bgcolor-blue {
        background-color: #003771 !important;
    }
	.bgcolor-light-blue {
		background: rgba(41, 155, 255, 0.2) !important;
	}
    
    .text-green {
        color: rgba(112, 173, 71, 0.2) !important;
    }
    .text-maroon {
        color: rgba(252, 192, 70, 0.2) !important;
    }
    .text-red {
        color: rgba(254, 38, 38, 0.2) !important;
    }

    .highlight-bg-green {
        background: rgba(0, 208, 141, 0.2) !important;
    }

    .highlight-text-green {
        color: rgba(0, 208, 141, 1) !important;
    }
}


// new change
.dataestate-dashboard-wrap {
    color: #E5E5E5;
    margin-top: 130px;
    padding: 0 24px;
}

.panel-legend {
    background-color: rgba(0, 55, 113, 0.2);
    border-radius: 4px;
    padding: 15px;

    .monitor-head {
        min-height: 40px;
    }

    .panel-legend--title {
        font-size: 16px;
        font-weight: normal;
        margin-top: 0;
    }
    .panel-legend--title-summary {
        font-size: 16px;
        font-weight: 500 !important;
        margin-top: 0;

        .arrow-right-styles {
            vertical-align: middle;
            width: 20px;
        }
    }

    .panel-box {
        background-color: rgba(0, 55, 113, 1);
        border-radius: 4px;
        padding: 10px 15px;

        .panel-box--title {
            font-size: 14px;
            font-weight: normal;
            margin-top: 0;
        }

        .panel-box--count {
            font-size: 30px;
            font-weight: 600;
        }
        .panel-box--count-summary {
            font-size: 25px;
            font-weight: 600;
        }
        .panel-box--count-summary-color-g {
            font-size: 25px;
            font-weight: 600;
            color:green
        }
        .conversion {
            font-size: 14px;
        }
    }

    .panel-box-summary {
        background-color: rgba(0, 55, 113, 1);
        border-radius: 4px;
        padding: 20px 15px;
        position: relative;

        &.has-navigation {
            cursor: pointer;
        }

        .panel-box--title {
            font-size: 14px;
            font-weight: normal;
            margin-top: 0;
            min-height: 33px;
        }

        .panel-box--count {
            font-size: 30px;
            font-weight: 600;
        }
        .panel-box--count-summary {
            font-size: 25px;
            font-weight: 600;
            display: inline-block;
        }
        .conversion {
            font-size: 14px;
        }
    }

    .panel-box-summary-powerBi{
        background-color: rgba(0, 55, 113, 1);
        border-radius: 4px;
        padding: 20px 15px;
        &.has-navigation {
            cursor: pointer;
        }
        .panel-box--title {
            font-size: 14px;
            font-weight: normal;
            margin-top: 0;
            min-height: 33px;
        }
        .panel-box--count {
            font-size: 30px;
            font-weight: 600;
        }
        .panel-box--count-summary {
            font-size: 25px;
            font-weight: 600;
        }
        .conversion {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 1250px) {

	.panel-legend {
        .panel-box-summary {
    
            .panel-box--title {
                font-size: 13px;
            }
    
            .panel-box--count {
                font-size: 27px;
            }
            .panel-box--count-summary {
                font-size: 20px;
            }
            .conversion {
                font-size: 13px;
            }
        }
    
        .panel-box-summary-powerBi{
            .panel-box--title {
                font-size: 13px;
            }
            .panel-box--count {
                font-size: 27px;
            }
            .panel-box--count-summary {
                font-size: 20px;
            }
            .conversion {
                font-size: 13px;
            }
        }
    }
}

.mr-10 {
    margin-right: 10px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-30 {
    margin-bottom: 30px;
}

.mt-30 {
    margin-top: 30px;
}
.mr-15 {
    margin-right: 15px;
}
.mb-0 {
    margin-bottom: 0;
}
.ml-5 {
    margin-left: 5px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-15 {
    margin-left: 15px;
}
.height-230 {
    height: 230px;
    overflow: hidden;
}

.costsummary-sector-filter {
    top: 70px !important;
    right: 50px !important;
}


.summary-ml {
    margin-left: -10px !important;
}

.arrow-icon-styles {
    width: 23px;
}
.arrow-icon-styles-up {
    width: 18px;
}
.text-green {
    color: #00A556 !important;
}.storage-row{
    .storage-col{
        background-color: #003771;
        padding: 10px;
        max-width: 32.8%;
        margin: 5px 0px;
        height: max-content;
        border-radius: 4px;
    }
    .storage-col6{
        max-width: 49.5%;
        background-color: #003771;
        padding: 10px;
        margin: 5px 0px;
        height: max-content;
        border-radius: 4px;
    }
    .storage-col6:nth-child(2){
        margin-left: 9px;
    }
    .storage-col:nth-child(2){
       margin-left: 9px;
       margin-right: 9px;
    }.storage-col h1{
        font-size: 54px;
        font-weight: 700;
        margin: 25px 0px;
    }.storage-col h1 span{
        font-size: 20px;
        margin-left: 10px;
    }.storage-col label{
        font-size: 14px;
        line-height: 19.5px;
        font-weight: 500;
    }.storage-col-graph{
        background-color: #003771;
        padding: 10px;
        margin: 5px 0px;
        height: max-content;
        max-width: 49.5%;
        border-radius: 4px;
        label{
            font-size: 14px;
            line-height: 19.5px;
            font-weight: 500;
            span{
                i{
                    color:#E5E5E5;
                    text-transform: capitalize;
                    font-weight: 400;
                    font-size: 14px;
                    margin-left: 5px;
                }
            }
        }
        .echarts-for-react {
            canvas{
                height: max-content;
            }
        }
    }.storage-col-graph:first-child{
        margin-right: 9px;
    }
    }.storage-base{
        padding-left: 25px;
        margin-top: 135px;
    }
    
.text-red {
    color: #FE2626 !important;
}.containerBI{
    flex-wrap: nowrap !important;
}.date-picker-summary{
    #single-date-select{
        padding: 10px 14px !important;
    }
}.col6{
    width: 49%;
}.chart-widget-query{
    height: max-content !important;
    padding: 6px;
}

.loading {
    font-size: 12px;
}
.min-height-30 {
    min-height: 30px;
}
.date-custom{
    background-color: #001e3e !important;
    // margin-top: 10rem  !important;
    border-radius: 4px;
    width: 175px;
    min-height: 30px;
    padding: 5px 0px 0px 5px;
    top: 0;
	right: 0;
    position: relative;
}
.custom-month-btn {
    width:50%;
    border-radius: 5px 5px 5px 5px !important;
    color: white !important;
    border-color: white !important;
    background-color: #001e3e !important;
    margin: 0px 10px 10px 0px;
    text-transform:none;
}
.year-arrow {
    cursor: pointer;
}
.up-arrow {
    transform: rotate(180deg);
    cursor: pointer;
}
.custom-dis {
    display: flex;
}
.custom-class-date .MuiPopover-root {
    width: 90%;
    .MuiBackdrop-root {
        width: 80%;
    }
}
.page-table {
	padding: 0px 16px;
	margin-top: 140px;
}
.incidents_tabledata .rdg-header-row {
	background: #003771 !important;
}
.incidents_tabledata .rdg {
	background: transparent !important;
	height: calc(100vh - 250px);
	border: none;
	overflow-y: auto !important;
}
.incidents_tabledata .pagination-div {
    width: 100%;
    bottom: 0px;
    background: #002349;
}
.incidents_tabledata .darkPagination {
    margin-bottom: 0;
    bottom: inherit;
    right: 0;
    height: 86px;
    background: #002349;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0px 0px;
    box-sizing: border-box;
    float: right;
}
.incidents_tabledata .highlightClass .rdg-cell {
    background-color: #062242;
}
.table-title-incident {
	vertical-align: top;
	margin-left: 5px !important;
}
.mt20 {
	vertical-align: top;
	margin-top: 20px !important;
}
.teradataProd {
    display: inline-block;
    padding: 10px;
    margin-top: 0px;
    margin-left: 5px;
    background-color: #003771 !important;
    width: max-content !important;
    text-align: left;
    border-radius: 10px !important;
    position: relative !important;
    margin-right: 2%;
    margin-left: 0px;
    top: 0px !important;
    right: 0px !important;
}
.teradataBG {
    background-color: transparent !important;
    width: 50%;
    display: block !important;
    text-align: right;
    right: 15px !important;
}

.job-monitor-head {
    padding-bottom: 20px;
    font-size: 16px;
}.padl-10{
    padding-left: 10px !important;
}.full-width{
    width: 100%;
    margin: 0px;
}.no-padl{
    padding-left: 0px;
}.overall-summary .MuiGrid-grid-xs-4{
    width: 33% !important;
   margin-right: 10px;
   max-width: 33% !important;
}.no-marr{
    margin-right: 0px !important;
}.marl-10{
    margin-left: 10px !important;
}.chart-widget{
    min-height: max-content !important;
}.table-heading{
    padding-left: 10px;
    padding-top: 10px;
    clear: both;
    display: block;
}.page-title{
    margin-left: 10px !important;
}

.dataestate_tabledata .rdg-header-row {
    background: #003771 !important;
}
.dataestate_tabledata .rdg {
    background: transparent !important;
    height: calc(100vh - 250px);
    border: none;
    overflow-y: auto !important;
}
.dataestate_tabledata .pagination-div {
    width: 100%;
    bottom: 0px;
    background: #002349;
}
.dataestate_tabledata .darkPagination {
    margin-bottom: 0;
    bottom: inherit;
    right: 0;
    height: 86px;
    background: #002349;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0px 0px;
    box-sizing: border-box;
    float: right;
}
.dataestate_tabledata .highlightClass .rdg-cell {
    background-color: #062242;
}
.cursor {
    cursor: pointer;
}

.tooltip-panel{
    img{
        display: inline-block;
        margin-left: 10px;
        margin-top: 2px;
        vertical-align: top;
    }
    h2{
        display: inline-block;
        min-width: min-content;
        max-width: 80%;
    }
}

.data-estate-right {
    right: 60px !important;
}
.data-estate-flex-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-top: -30px;
}
.data-estate-flex-center-cost {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 24px;
}
.de-exe-mt {
    margin-top: -25px;
}
.de-exe-mb {
    margin-bottom: -5px;
}
.de-service {
    margin-top: -20px !important;
    margin-bottom: 0px !important;
}
.de-link {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.data-esate-image {
    width: 20px !important;
    height: 20px !important;
    margin-bottom: -10px;
    margin-right: 5px;
}
.mr-10{
    margin-right: 10px;
}
.de-no-program-txt {
    font-size: 11px;
    font-weight: 100;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-top: 0px;
}
.de-exe-icon {
    margin-right: 0px !important;
}
.de-exe-txt {
    margin-top: 10px !important;
    margin-right: 0px !important;
}
.de-exec-txt {
    margin-top: 15px !important;
    margin-right: 0px !important;
}
.de-summary-fs {
    font-size: 11px !important;
}
.de-link-txt {
    text-decoration: none;
    color: #E5E5E5 !important;
    font-family: 'Montserrat' !important;
    font-weight: 100 !important;
    font-size: 11px !important;
    margin-left: 50px;
}
.ml-15 {
    margin-left: 15px !important;
}
.ml-30 {
    margin-left: 30px !important;
}
.mt-15 {
    margin-top: 15px !important;
}
.ml-45 {
    margin-left: 45px !important;
}
.mheight{
    min-height: 382px !important;
}
.single-line-ellipsis {
    white-space: nowrap;       
    overflow: hidden;           
    text-overflow: ellipsis;   
    width: 200px;          
  }
.bg-no {
    background-color: transparent !important;
}
  

   .min-height-420 {
    position: relative;
   }
   .min-height-125 {
    min-height: 100px;
    position: relative;
}

.place-bottom {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-align: center;
    .arrow-icon {
        height: 10px;
        vertical-align: middle;
    }
}
.min-height-80 {
    min-height: 92px;
}

.min-height-305 {
    min-height: 305px;
}
.bi-application-charts {
    height: auto !important;
}
.custom-date-filter-menu {
    .MuiPaper-root {
        background: none !important;
    }
}
.byte-filter {
    background-color: #00377133;
    border-radius: 4px;
    font-size: 15px;
    position: fixed;
    right: 270px;
    top: 85px;
    z-index: 999;
    min-width: 90px;
    text-align: left;
    fieldset {
        display: none;
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 10px 42px 10px 10px;

    }
}

@media screen and (max-width: 800px) {
    .byte-filter {
        right: 50px;
    }
}

.byte-summary {
    #single-byte-select{
        padding: 10px 14px !important;
    }
}
.mh-404 {
    min-height: 404px !important;
}
.mh-324 {
    min-height: 324px !important;
}
.mh-294 {
    min-height: 294px !important;
}
.mh-398 {
    min-height: 398px !important;
}
.mh-86 {
    min-height: 86px !important;
}
.byte { 
    .MuiPaper-elevation ul {
        height: max-content !important;
    }
    .MuiSvgIcon-root {
        color: #fff;
    }
}
.mh-88{
    min-height: 88px !important;
}
.inner-grid-mttr {
    .panel-box {
        min-height: 103px;
    }
}
.mh-138{
    min-height: 138px !important;
}

.m-0 {
    margin: 0 !important;
}

@media screen and (max-width: 650px) {
    .service-date-filter {
        padding-top: 15px;
    }
}

.tableHeight{
    margin-top: 140px !important;
}

.overlayDropdown{
    background: rgba(0, 55, 113, 0.4);
}
.noDataTextColor{
    color: #979595;
    font-size: 20px;
}

.disableFilter{
    .Mui-disabled{
        color: white;
    }
}

.custom-disabled-select .MuiSelect-select.Mui-disabled {
    color: gray !important; 
    opacity: 1 !important;
    -webkit-text-fill-color: gray !important; 
}

.custom-calendar-icon {
    color: gray !important;
}
