@import '../../theme-variables.scss';

.sidebar .ps-sidebar-root {
	height: 100% !important;
	background-color: #262626 !important;
	position: fixed !important;
	overflow: hidden !important;
	border: none !important;
	width: 60px;
	min-width: 60px;
	z-index: 999;
	&.expanded {
		width: 250px;
		min-width: 250px;
	}
}

.ps-sidebar-container {
	background-color: $color-secondary !important;
	position: relative !important
}
.ps-submenu-content {
	background-color: $color-secondary !important;
}
.ps-sidebar-container li > a {
	background-color: $color-secondary;
}

.ps-menu-button {
	height: 50px !important;
	color: white !important;
	border-bottom: 0.5px solid rgb(12, 15, 37);
}
.css-wx7wi4 {
	width: 20px !important;
	min-width: 20px !important;
}
.css-12vkui9 > .ps-menu-button {
	padding-left: 55px !important;
}
.ps-menu-button:hover {
	background-color: rgba(113, 117, 143, 0.05) !important;
	border-radius: 4px 0px 0px 4px;
	height: 55px !important;
}
.ps-menuitem-root.active a {
	background: $color-primary !important;
	border-radius: 4px 0px 0px 4px;
	height: 55px !important;
}
.ps-menuitem-root.active::before {
	content: '';
	background: $color-tertiary;
	position: absolute;
	width: 8px;
	height: 55px;
	margin-left: -4px;
	border-radius: 4px;
}
/* .logo {
	margin: 25px 16px 20px 16px;
} */
.dashboardMenu dashboardIcon {
	background: url('../../assets/lucide_layout-dashboard.svg') no-repeat;
	width: 20px;
	height: 20px;
	background-size: contain;
}
.ps-menuitem-root.active .menu-icon {
	width: 40px;
	height: 40px;
	display: none;
}
.ps-menuitem-root.active .menu-icon-schedule {
	width: 40px;
	height: 40px;
	display: none;
}
.ps-menuitem-root.active .menu-icon1 {
	width: 40px;
	height: 40px;
	display: block;
}
.ps-menuitem-root.active .menu-icon-schedule1 {
	width: 40px;
	height: 40px;
	display: block;
}
.ps-menuitem-root {
	.ibp-dq {
		width: 30px !important;
		height: 30px !important;
	}
}
.menu-icon1 {
	width: 15px;
	height: 15px;
	display: none;
}
.menu-icon-schedule1 {
	width: 40px;
	height: 40px;
	display: none;
}
.menu-icon-schedule {
	width: 40px;
	height: 40px;
}
.menu-icon {
	width: 40px;
	height: 40px;
}
.service-request {
	height: 25px !important;
	width: 25px !important;
}

.menu-dashboard-icon {
	width: 20px;
	height: 20px;
}
.menu-dashboard-icon1 {
	width: 40px;
	height: 40px;
}
.menu-monitor-icon,
.menu-cost-icon {
	width: 20px;
}
.menu-monitor-icon-dq {
	width: 40px;
}
.sidebar .ps-submenu-expand-icon {
	display: none;
} /* width */
::-webkit-scrollbar {
	width: 12px;
	background: transparent;
	height: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $color-secondary;
	height: 5px;
	border-radius: 4px;
}
/* Track */
// ::-webkit-scrollbar-track {
// 	background: #002349;
//   }
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $color-secondary;
}
::-webkit-scrollbar-corner {
	background: transparent;
}

.theme-bg-blue {
	
	::-webkit-scrollbar-thumb {
		background: $color-scrollbar;
	}
	::-webkit-scrollbar-track {
		background: #002349;
	  }
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: $color-scrollbar;
	}
}

.dashboardMenu jobsIcon {
	background: url('../../assets/clarity_process-on-vm-line.svg') no-repeat;
	width: 20px;
	height: 20px;
	background-size: contain;
}
.dashboardMenu masterIcon {
	background: url('../../assets/mdi_table.svg') no-repeat;
	width: 20px;
	height: 20px;
	background-size: contain;
}
.icons-header {
	height: 55px !important;
	width: 100% !important;
	background: $color-secondary !important;
	margin-left: 0px !important;
	justify-content: flex-start;
	border-top: 0.5px solid #0c0f25;
	border-radius: 0;
	padding-left: 14px;
}

.icons-header-username {
	width: 100% !important;
	background: $color-secondary !important;
	margin-left: 0px !important;
	justify-content: flex-start;
	border-top: 0.5px solid #0c0f25;
	border-radius: 0;
	padding-left: 14px;
	position: absolute;
	bottom : 0
}

.icons-header:hover {
	background: rgba(113, 117, 143, 0.05) !important;
}

.icons-header-collapse a {
	display: none;
}
.icons-header-collapse .menu-icon-div-collapse {
	width: 30px;
}
.icons-header .menu-icon-div {
	width: 30px;
	height: 33px;
	line-height: 33px;
	text-align: center;
}
.icons-header a {
	font-size: 16px;
	color: white;
	text-decoration: none;
	padding: 20px 6px;
	width: 70%;
}
.menu-icon-dev {
	float: left;
	text-align: center;
}
.menu-icon-div-collapse {
	text-align: center;
	float: left;
}
.user-icon {
	width: 18px !important;
	height: 18px !important;
}
.masterSubmenu {
	height: 150px;
	overflow: auto;
}
/* MUI Menu */
.MuiMenu-paper {
	background-color: $color-secondary !important;
	color: #f5f5f5 !important;
}

.MuiMenuItem-root {
	height: 30px !important;
	font-family: Montserrat !important;
	font-size: 12px;
	font-size: 12px;
	padding: 6px;
}

.MuiInputBase-root {
	font-family: Montserrat !important;
}

.MuiFormControlLabel-label.Mui-disabled {
	color: #f5f5f5 !important;
	opacity: 0.5;
}
.menu-icon-home-powerbi {
    position: absolute;
    width: 55px;
    height: 35px;
    top: 17px;
    left: 9px;
}
.menu-dashboard-icon2 {
	width: 40px;
	height: 40px;
}

.home_logo {
	display: flex;
	align-items: center;
}

.sidebar_home {
	color: #e5e5e5;
	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration: none;
	/* margin-left: 12px; */
}

.home_img {
	cursor: pointer;
	margin-left: 11px;
	img {
		height: 40px;
		width: 40px;
	}
}
.ps-collapsed {
	.home_img {
		margin-right: 10px;
	}
}

.user_name_details {
	
	color: #E5E5E5;
		font-family: Montserrat;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-left: 12px;
}

.user_email_details {
	color: #F5F5F5;
		font-family: Montserrat;
		font-size: 12px;
		font-style: italic;
		font-weight: 400;
		line-height: 15px;
		margin-left: 12px;
		margin-top: 1px;
		
}
.active-icon{
	color: #00d08d;
}

.icon-material-symbols_dashboard {
	font-size: 32px;
}

.icon-material-symbols_Operationdashboard {
	font-size: 30px;
}

.icon-ri_file-list {
	font-size: 35px;
}

.icon-material-symbols_schedules {
	font-size: 35px;
}

.icon-Infrastructure_Selected {
	font-size: 35px;
}

.icon-Resource-Optimization_Selected {
	font-size: 35px;
}

.icon-Cost-Summary {
	font-size: 35px;
}

.icon-Performance_Selected {
	font-size: 35px;
}

.icon-powerbi_datagrowth {
	font-size: 35px;
}

.icon-powerbi_dataset {
	font-size: 35px;
}

.icon-Capacity_Performance {
	font-size: 35px;
}

.icon-CatalogueQuality_Selected {
	font-size: 35px;
}

.icon-Tableau_selected {
	font-size: 35px;
}

.icon-PowerBI {
	font-size: 35px;
}

.icon-material-symbols_table-chart-view-sharp {
	font-size: 35px;
}

.icon-mingcute_building {
	font-size: 35px;
}

.icon-ri_computer {
	font-size: 35px;
}

.icon-material-symbols_logout {
	font-size: 30px;
}

.icon-bxs_home {
	font-size: 50px;
}
.biAppIcon {
	width: 25px !important;
	height: 25px !important;
}

.common-menu-header {
	cursor: pointer;
}
  
.menu-items {
	display: flex;
	flex-direction: column;
}

.menu-container {
    max-height: 450px; 
    overflow-y: auto;  
    overflow-x: hidden; 
	cursor: pointer;
}

/* For screens 992px wide and smaller (tablet size) */
@media (max-height: 992px) {
    .menu-container {
        max-height: 650px;
    }
}

/* For screens 768px wide and smaller (mobile size) */
@media (max-height: 768px) {
    .menu-container {
        max-height: 550px;
    }
}

/* For screens 576px wide and smaller (very small devices) */
@media (max-height: 576px) {
    .menu-container {
        max-height: 400px;
    }
}

.DAIExecutive_menus{
  	padding-left: 3.5em;
}

.DAIExecutive_icon{
	display: grid;
	padding-left: 5.5em;
}