@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	src: url('../../assets/Fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('ttf');
}
.filter-icon span img {
	float: right;
	margin-top: 8px;
}
.filter-icon span {
	font-family: 'Montserrat';
	font-weight: 500;
	color: #e5e5e5;
}

/* .filter-icon span:first-child{
    float: left;
    width: 90%;
} */
/* .job-list-table {
	width: 100%;
}
.job-list-table .rdg {
	width: 100%;
}
.job-list-table .rdg::before {
	width: 100%;
} */
.filter-row {
	padding: 15px 20px 10px 30px;
	width: 86%;
	float: left;
}
.filter-row label span {
	font-size: 14px;
	text-transform: capitalize;
	font-family: 'Montserrat';
	padding-right: 0px;
}
.filter-row span svg {
	width: 18px;
	height: 18px;
}
.filter-row span svg:first-child {
	margin-right: 10px;
}
.filter-row img {
	float: left;
	margin-top: 0px;
	margin-right: 10px;
}
.filter-row:nth-child(2) {
	padding: 0px 20px 15px 30px;
}
.css-d2bh51-MuiButtonBase-root-MuiRadio-root.Mui-checked {
	color: #eb7b30 !important;
}
.css-d2bh51-MuiButtonBase-root-MuiRadio-root {
	color: #71758f !important;
}
.clear-sec {
	border-top: 1px solid #71758f;
	padding: 15px 20px 15px 30px;
	height: min-content;
	min-height: max-content;
	float: left;
	width: 86%;
}
.clear-row img {
	float: left;
}
.clear-row a {
	margin-left: 10px !important;
	float: left;
	margin: 0px;
	line-height: 14px;
	text-transform: capitalize;
	font-size: 14px;
	text-decoration: none;
	color: #f5f5f5;
	font-family: 'Montserrat';
}
.clear-row {
	width: 86%;
	float: left;
}
.clear-row:nth-child(2) {
	margin-top: 10px;
}
.text-filter-sec {
	float: left;
	width: 90%;
	border-top: 1px solid #71758f;
	padding: 15px 0px 0px 20px;
}
.text-filter-row img {
	float: left;
}
.text-filter-row p {
	float: left;
	margin: 0px 0px 0px 10px;
	text-transform: capitalize;
	line-height: 15px;
	font-family: 'Montserrat';
}
.text-arrow-icon {
	float: right !important;
}
.filter-row a {
	text-transform: capitalize;
	color: #f5f5f5;
	float: left;
	width: 88%;
	font-size: 14px;
	text-decoration: none;
	font-family: 'Montserrat';
}
.selectAll {
	background: rgba(113, 117, 143, 0.4);
	border: 1px solid #3d3a50;
	border-radius: 8px;
	padding: 0px 20px 5px;
	float: left;
	width: 100%;
}
.selectAll .MuiCheckbox-root {
	color: #71758f;
	width: 20px;
	height: 20px;
	margin-top: 7px;
}
.selectAll label span {
	color: #f5f5f5;
	font-size: 12px;
	padding-left: 10px;
	padding-top: 7px;
	font-family: 'Montserrat';
}
.selectAll input[type='checkbox']:checked,
.selectAll-sec {
	height: 140px;
	overflow: auto;
	padding: 0px;
	margin: 0px;
	background-color: black;
}
.selectAll-sec label {
	float: left;
	width: 100%;
	font-family: 'Montserrat';
}
.selectAll label {
	margin-left: 4px;
}
.checkbox-sec label {
	height: 30px;
	font-family: 'Montserrat';
	float: left;
	margin-left: 0px;
}
.checkbox-sec {
	margin-left: 10px;
	float: left;
	margin-top: 10px;
	width: 100%;
}
.checkbox-sec .MuiCheckbox-root {
	color: #71758f;
	width: 20px;
	height: 20px;
}
.checkbox-sec label span {
	color: #f5f5f5;
	font-size: 12px;
	padding-left: 10px;
}
.JDTabs .MuiTab-root {
	background-color: #1b1e35;
	padding: 0 50px;
	font-size: 13px !important;
}
.JDTabs .MuiTab-root.Mui-selected {
	background: none !important;
	box-shadow: none !important;
}
.width-65 {
	width: 60%;
}
.width-30 {
	width: 39%;
}
.job-overview-table {
	margin: 10px 0 0 4px;
}
.job-overview-table .attrValue {
	font-size: 12px;
	padding-bottom: 20px;
	width: 25%;
	max-width: 25%;
	word-break: break-all;
	vertical-align: top;
}
.job-overview-table .attrValue a {
	text-decoration: none;
	color: #e5e5e5;
}
.job-overview-table .attrValue a:hover {
	text-decoration: underline;
}
.job-overview-table .attrValue .MuiChip-colorDefault {
	background-color: #0c0f25;
	color: #e5e5e5;
	margin-bottom: 10px;
	max-width: 106px;
	margin-right: 10px;
	height: 24px;
}
.job-overview-table .attrValue .MuiChip-colorDefault:nth-child(even) {
	margin-right: 0;
}
.job-overview-table .attrValue .MuiChip-colorDefault .MuiChip-label {
	font-family: 'Montserrat';
	font-size: 12px;
}
.job-overview-table .attrValue .MuiChip-colorSuccess {
	background-color: #17372e;
	color: #01c008;
}
.job-overview-table .attrValue .MuiChip-colorSecondary {
	background-color: #3d2033;
	color: #cd2429;
}
.job-overview-table .attrValue .MuiChip-colorWarning {
	background-color: #ffa20033;
	color: #ffa200;
}
.job-overview-table .attrTitle {
	font-size: 12px;
	padding-bottom: 20px;
	vertical-align: top;
	min-width: 60px;
	width: 18%;
	max-width: 140px;
}
.job-overview-table .emptyCell {
	width: 7%;
}
.box-panel {
	padding: 0px 12px;
}
.box-panel .MuiTypography-h6 {
	font-size: 14px;
	font-weight: 600;
	padding-right: 0px !important;
}
.box-panel {
	position: relative;
}
.h-360 {
	height: 360px !important;
}
.h-500 {
	height: 500px !important;
}
.box-panel .edit-icon--wrap {
	background-color: #0c0f25;
	padding-left: 10px;
	padding-bottom: 10px;
	position: absolute;
	right: 0;
	top: 0;
}
.box-panel .edit-icon--style {
	background: #00d08d;
	height: 30px;
	border-radius: 5px;
	width: 30px;
}
.box-panel .edit-icon--style .MuiSvgIcon-root {
	color: #fff;
	height: 18px;
	width: 18px;
}
.box-panel .edit-icon--style:hover .MuiSvgIcon-root {
	color: #1976d2;
}
.run-history--grid {
	margin-top: 30px;
	margin-bottom: 10px;
}
.run-history--grid .run-history--top {
	background-color: #1b1e35;
	padding: 10px;
}
.run-history--grid .run-history--top .attrTitle {
	font-size: 14px;
}
.run-history--grid .run-history--top .attrValue {
	font-size: 14px;
	font-weight: 500;
	width: 90%;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}

.job-widgets-xs-5 {
	margin-left: 5px;
	flex-basis: 19.1%;
	max-width: 19.1%;
}
.job-widgets .MuiBox-root {
	font-size: 14px;
	margin-left: 0;
	margin-bottom: 0;
}
.job-widgets .MuiPaper-root {
	background-color: #1b1e35;
	color: #71758f;
	font-weight: 600;
	padding: 10px;
}
.job-widgets .MuiStack-root {
	position: relative;
}
.job-widgets .MuiStack-root span {
	margin: 0 !important;
	position: absolute;
	right: 0;
	top: 10px;
}
.job-widgets .MuiStack-root span b {
	display: inline-block;
	line-height: 26px;
	font-size: 10px;
}
.job-widgets .MuiBox-root .MuiStack-root p {
	margin-top: 6px;
	margin-bottom: 0;
	font-size: 24px;
}
/* .job-list-tabel .rdg-row .rdg-cel {
	height: 50px;
} */
.edit-job-modal .MuiDialog-paperWidthMd {
	padding: 0;
}
.edit-job-modal .MuiDialogTitle-root {
	background-color: #1b1e35;
	font-size: 14px;
	font-weight: bold;
	padding: 10px 20px;
}
.edit-job-modal .MuiDialogTitle-root .MuiIconButton-root {
	padding: 0;
}
.edit-job-modal .MuiDialogTitle-root .MuiIconButton-root .MuiSvgIcon-root {
	color: #fff;
	font-size: 18px;
}
.edit-job-modal .editJobForm {
	background-color: #0c0f25;
	font-size: 12px;
}
.edit-job-modal .editJobForm .attrTitle {
	font-size: 13px;
}
.edit-job-modal .editJobForm .MuiGrid-container .MuiFormControl-root {
	border-color: #0c0f25;
}
.edit-job-modal .editJobForm .MuiGrid-container .MuiSvgIcon-root {
	color: #fff;
}
.edit-job-modal .editJobForm .MuiGrid-container .MuiFormControl-root .MuiInputLabel-root {
	color: #71758f !important;
}
.edit-job-modal .editJobForm .MuiGrid-container .MuiFormControl-root input,
.edit-job-modal .editJobForm .MuiGrid-container .MuiFormControl-root .MuiSelect-select {
	font-size: 12px;
}
.edit-job-modal .editJobForm .MuiGrid-container .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
	border-color: #71758f;
}
.edit-job-modal .editJobForm .MuiDialogActions-root {
	padding: 0 35px 30px 0;
}
.edit-job-modal .editJobForm .MuiDialogActions-root .MuiButton-outlinedError {
	border: 0;
	color: #fff;
	font-size: 12px;
	padding: 6px 20px;
	font-weight: 400;
}
.edit-job-modal .editJobForm .MuiDialogActions-root .MuiButton-containedSuccess {
	background-color: #00bb80;
	font-size: 12px;
	padding: 6px 30px;
	font-weight: 400;
}

.edit-job-modal .editJobForm .MuiButton-root.Mui-disabled {
	background-color: #00bb8066;
	cursor: not-allowed;
}

.edit-job-modal .editJobForm .MuiSelect-select.MuiSelect-outlined.Mui-disabled.Mui-disabled {
	-webkit-text-fill-color: rgba(200, 200, 200, 0.38);
}
.total {
	color: #e5e5e5;
}
.success {
	color: #01c008;
}
.pending {
	color: #ffa200;
}
.failed {
	color: #fe2626;
}
.others {
	color: #71758f;
}
.editJobForm .criticalFlagCheck {
	margin-left: unset;
}
.edit-job-modal .editJobForm .criticalFlagCheck .MuiSvgIcon-root {
	color: unset;
}
.clear-filter-sec {
	float: left;
	width: 100%;
	border-top: 1px solid #71758f;
}
.job-dependency-iframe-container {
	height: calc(100vh - 225px);
	width: 100%;
	display: block;
	border: none;
}
.job-execution-iframe-container {
	 position: absolute;
    width: 38.6vw;
    height: 100vh; 
	border: none;
	margin-left: -15px;
}
.job-execution-iframe-div {
	margin-left: 0px !important;
}
.scheduleTime {
	word-break: break-all;
}

.dots_menu_text {
	color: white;
	font-weight: 600;
}
.jobsNameList:hover {
	background: #0c0f25;
	padding: 10px !important;
	border: 1px solid #71758f;
}
.pagination-div {
	/* position: absolute; */
	width: 100%;
	bottom: 0px;
	background: #0c0f25;
}
.page-table {
	padding: 0px 16px;
	margin-top: 158px;
}

/* MUI Tooltip */
.MuiTooltip-tooltip {
	background: #0c0f25;
	font-size: 12px;
	color: #f5f5f5;
}

.MuiTooltip-tooltip img {
	vertical-align: middle;
	padding: 10px;
}

.MuiTooltip-arrow {
	color: #0c0f25;
}
.no-record-info {
	color: #e5e5e5;
	margin-left: 25%;
	padding: 10px;
	font-weight: 500;
	grid-column: 1/-1;
}
.exportButton {
	font-family: 'Montserrat';
	font-size: 12px;
	font-weight: 400;
	text-transform: capitalize;
	color: #e5e5e5;
	margin-left: -10px;
	background: transparent;
	margin-top: -2px;
}

.exportButton .MuiTouchRipple-root {
	display: none;
}

.exportButton .MuiButton-startIcon {
	margin-right: 0;
	margin-left: 0;
	width: 50px;
	height: 50px;
}
/* MUI Menu */
.MuiMenu-paper {
	background-color: #1b1e35 !important;
	color: #f5f5f5 !important;
}

.MuiMenuItem-root {
	height: 30px !important;
	font-family: Montserrat !important;
	font-size: 12px;
	font-size: 12px;
	padding: 6px;
}

.MuiInputBase-root {
	font-family: Montserrat !important;
}

.MuiFormControlLabel-label.Mui-disabled {
	color: #f5f5f5 !important;
	opacity: 0.5;}

.jobs_tabledata .rdg {
	background: #0c0f25;
	height: calc(100vh - 250px);
	border: none;
	overflow-y: auto !important;
}

.jobs_tabledata .rdg-cell {
	font-weight: 300;
	padding: 4px 7px 4px 7px !important;
	color: #e5e5e5;
	border: none;
	line-height: 28px;
	font-size: 12px;
}


.jobs_tabledata .rdg-cell:hover {
	overflow: visible;
	z-index: 111;
	max-width: initial;
	background: #0c0f25;
}

.jobs_tabledata .rdg-cell[aria-selected='true'] {
	outline: none;
}

.jobs_tabledata .rdg-cell a {
	color: white;
	text-decoration: none;
}

.jobs_tabledata .rdg-cell[aria-sort='descending'] .rdg-sort-arrow,
.jobs_tabledata .rdg-cell[aria-sort='ascending'] .rdg-sort-arrow {
	fill: none;
	height: 20px;
}

.jobs_tabledata .rdg-row:nth-child(even) {
	background: none;
}

.jobs_tabledata .rdg-header-row {
	background: #1b1e35;
}

.jobs_tabledata .rdg-row:nth-child(odd) {
	background: rgba(113, 117, 143, 0.05);
}

.jobs_tabledata .rdg-row:nth-child(odd) .rdg-cell {
	border-right: 1.5px solid #1b1e35;
}

.jobs_tabledata .rdg-header-row .rdg-cell {
	border-right: 1.5px solid #0c0f25;
}

.jobs_tabledata .rdg-cell:last-child {
	border-right: none !important;
}

.jobs_tabledata .reasonForDelay:hover {
	position: absolute;
}

.jobs_tabledata .highlightClass .rdg-cell {
	background-color: #71758f66;
	border-right: none !important;
}

.jobs_tabledata .highlightClass:hover .rdg-cell {
	background-color: #71758f66;
}

.root,
#root {
	height: 100%;
	overflow: auto;
	max-height: 100%;
}

.job-schedule-table {
	height: calc(100vh - 100px) !important;
}.records-msg{
	color: white;
}