.newJob-iframe-div {
	transform: translateX(-27px);
	float: right;
	/* width: 46.6vw; */
}

.sla-iframe-div {
	float: left;
	/* width: 46.6vw; */
	padding: 0 !important;
}

.reason-job-execution-iframe-div {
	margin-left: 0px !important;
	margin-top: -74px;
}

.second-iframe-container {
	transform: translateY(-10%);
	display: flex;
}

.time-select {
	left: 42%;
	top: 5px;
	z-index: 1;
	height: 28px;
	background-color: #1b1e35;
	font-size: 14px;
}

.time-select1 {
	position: absolute;
	top: auto;
	right: 2%;
	margin-top: 21px;
	z-index: 1;
	height: 30px;
	background-color: #1b1e35;
	font-size: 14px;
}

.time-select .MuiSvgIcon-root {
	color: #f5f5f5;
}

.time-select1 .MuiSvgIcon-root {
	color: #f5f5f5;
}

.time-select .MuiOutlinedInput-root.Mui-focused,
.time-select .MuiOutlinedInput-notchedOutline {
	border: none;
	left: 5px;
}

.time-select1 .MuiOutlinedInput-root.Mui-focused,
.time-select1 .MuiOutlinedInput-notchedOutline {
	border: none;
	left: 5px;
}

/*
.MuiPopover-root .MuiPaper-root .MuiList-root .MuiButtonBase-root {
    font-size: 14px;
}
.time-label {
    display: inline-block;
    padding-left: 15px;
    padding-bottom: 3px;
    font-size: 14px;
    position: relative;
}
.dashboard-tab {
    float: right;
}
.last-tab {
    margin-right: 35px;
}
.time-range {
    display: flex;
    float: right;
    align-items: center;
}
.page-header {
    margin-top: -55px;
}


.dashboard-panel .MuiTab-root {
	color: #E5E5E5;
	font-family: 'Montserrat';
	font-weight: 800;
	font-size: 14px;
	width: 200px !important;
    background: #1B1E35;
    margin: 0px 15px;
}
.dashboard-panel .MuiTab-root.Mui-selected {
    border-radius: 4px;
    color: #00D08D !important;
	background: none !important;
}
.dashboard-header {
    margin-top: -50px;
} */

.time-select3 {
	position: absolute;
	top: auto;
	right: 4%;
	margin-top: -46px;
	z-index: 1;
	height: 30px;
	background-color: #1b1e35;
	font-size: 14px;
}

.time-select3 .MuiSvgIcon-root {
	color: #f5f5f5;
}

.time-select3 .MuiOutlinedInput-root.Mui-focused,
.time-select3 .MuiOutlinedInput-notchedOutline {
	border: none;
	left: 5px;
}

/* Business Dashboard CSS */
.dashboard-panel-teradata {
	/* padding: 5px; */
	margin-top: 158px;
}

.bd-container {
	padding: 0px 15px;
	position: relative;
	top: -10px;
	/* Adjust the value as needed */
	transform: translateY(-10%);
}

.pDetailsInfo {
	font-style: italic;
	font-size: 11px;
}

.bd-container1 {
	padding: 12px;
}

.bd-overview {
	margin-top: 10px;
}

.dashboardPageBtn {
	color: #e5e5e5;
}

.dashboardPageBtn.Mui-disabled {
	color: #e5e5e544;
}

.dashboardPageBtn.Mui-disabled path {
	fill: #e5e5e544;
}

.pieChart-container {
	width: 12%;
}

.pieChart-container svg {
	width: 70%;
}

.bd-container .MuiPaper-root,
.bd-container .MuiTableCell-root {
	background-color: #1b1e35;
	color: #e5e5e5;
	border: 1px solid #0c0f25 !important;
	padding: 5px;
}

.slaTrendCell,
.slaNoPopupCell {
	font-family: 'Montserrat';
	width: 100px;
}

/* if the browser window is at least 800px-s wide: */
@media screen and (min-width: 800px) {

	.slaTrendCell,
	.slaNoPopupCell {
		width: 70px;
	}
}

/* if the browser window is at least 1000px-s wide: */
@media screen and (min-width: 1000px) {

	.slaTrendCell,
	.slaNoPopupCell {
		width: 100px;
	}
}

/* .slaTrendCell:hover,
.slaTrendCellHover {
	display: block;
	font-weight: 800;
	font-size: 14px;
	border: none !important;
	transform: scale(1.7, 1.7) translate(0px, 5.5px);
	-webkit-transform: scale(1.7, 1.7) translate(0px, 5.5px);
	-moz-transform: scale(1.7, 1.7) translate(0px, 5.5px);
	-webkit-box-shadow: 0 15px 30px 8px rgba(0, 0, 0, 0.8);
	-moz-box-shadow: 0 15px 30px 8px rgba(0, 0, 0, 0.8);
	box-shadow: 0 15px 30px 8px rgba(0, 0, 0, 0.8);
} */

.slaTrendEmptyCell:hover {
	display: block;
	border: none !important;
	transform: scale(1.7, 5.5) translate(0px, 3.5px);
	-webkit-transform: scale(1.7, 5.5) translate(0px, 3.5px);
	-moz-transform: scale(1.7, 5.5) translate(0px, 3.5px);
	-webkit-box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.8);
	-moz-box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.8);
	box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.8);
}

.slaCellInfoIcon {
	display: none;
}

.slaTrendCell:hover .slaCellInfoIcon,
.slaCellInfoIconShow {
	display: inline;
}

.slaPopup {
	box-shadow: none;
	background-color: #1b1e35;
	color: #e5e5e5;
	border: none !important;
	padding: 5px;
}

.popoverText,
.popoverLabel,
.popoverLink {
	font-size: 12px;
	font-weight: 700;
}

.popoverLabel {
	color: #71758f;
	margin-top: 20px !important;
}

.popoverLink:hover {
	text-decoration: underline;
	cursor: pointer;
}

/* .lastSync {
  top: -4%;
  left: 30%;
  position: absolute;
  font-size: 13px;
  color: #71758F;

} */
.lastSync1 {
	top: -5%;
	left: 30%;
	position: absolute;
	font-size: 13px;
	color: #fcc046 !important;
}

.paginationStack {
	position: absolute;
	bottom: 0px;
	right: 0px;
}

.paginationStack3 {
	margin-top: -18vh;
	float: right;
}

.paginationIcon {
	margin-top: 0 !important;
	min-width: 30px !important;
}

.businessDashboard .MuiTableCell-root {
	font-family: 'Montserrat' !important;
}

.dashboardSlaPopup {
	background: transparent !important;
	font-family: 'Montserrat' !important;
}

.businessDashboard .pDetailsInfo {
	font-weight: 300;
}

.warning {
	height: 10px;
}

.dashboard-business-root {
	overflow: scroll !important;
}

/* MUI Paper */
.MuiPaper-root,
.MuiTableCell-root {
	background-color: #1b1e35;
	color: #e5e5e5;
	border: none !important;
	padding: 5px;
}

/* MUI Menu */
.MuiMenu-paper {
	background-color: #1b1e35 !important;
	color: #f5f5f5 !important;
}

.MuiMenuItem-root {
	height: 30px !important;
	font-family: Montserrat !important;
	font-size: 12px;
	font-size: 12px;
	padding: 6px;
}

.MuiInputBase-root {
	font-family: Montserrat !important;
}

.MuiFormControlLabel-label.Mui-disabled {
	color: #f5f5f5 !important;
	opacity: 0.5;
}

.pie-chart-over:hover text {
	display: none;
	transition-duration: 2s;
}

.pie-chart-over text:hover {
	display: none;
}

@media screen and (min-width: 1100px) {
	.time-select {
		left: 42%;
		top: 8px;
	}
}

.processTitlesla {
	margin-top: 7%;
	margin-bottom: 1%;

}

@media screen and (max-width: 1100px) {
	.processTitlesla {
		margin-top: 11%;
		margin-bottom: 1%;

	}
}

.processTitle {
	margin-top: 1%;
	margin-bottom: 1%;
}