@import '../../theme-variables.scss';


.dashboard-panel-dataestate {
    margin-top: 135px !important;
    .loader {
        z-index: 9;
    }
    .headerIPB-title {
        line-height: 20px;
        width: 49%;
    }
}

.subheader-dataestate {
    font-size: 30px;
    font-weight: 600;
	text-align: center;
}
.monitor-head-dataestate {
    line-height: 20px;
    text-align: center;
}
.monitor-head {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    margin-left: 5px;
    min-height: 30px;
    
    @media screen and (max-width: 1300px) {
        font-size: 14px;
    }
    .badge {
        position: absolute;
        right: 5px;
        top: 0;
        padding: 1px 8px;
        border-radius: 50px;
        background-color: #009BD8;
        color: #001E3E;
        font-size: 12px;
        font-weight: 600;
        z-index: 9;
    }
    .badge-sec {
        top: 33px;
    }
}
.arrow-icon-styles-up-black {
    width: 15px !important;
    height: 10px;
}
.dataestate-title-fn {
    flex: 1 1 auto;
    border-radius: 4px;
    margin: 10px 0 5px 6px;
    font-size: 14px;
    font-weight: normal;
    line-height: 40px;
    // padding: 50px;
    position: relative;
    height: 27vh;
    width: 49%;
}

.dataestate-title-right {
    flex: 1 1 auto;
    border-radius: 4px;
    margin: 15px 3px 0px 3px;
    font-size: 14px;
    font-weight: normal;
    line-height: 40px;
    padding: 50px;
    position: relative;
    height: 31.6vh;
}
.chart-widget {
    min-height: 62vh;
    height: 62vh;
}

.headerdataestate-title {
    flex: 1 1 auto; 
    border-radius: 4px;
    margin: 8px 12px 0 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    padding: 15px;
    position: relative;
    height: 20vh;
}
.dataestate-subheader {
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;
}
.subheader-text{
    font-size: 28px;  
}
.subheader-right{
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;

}
.headerdataestate-title-rt {
    flex: 1 1 auto; 
    border-radius: 4px;
    margin: 8px 1% 0 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    padding: 15px;
    position: relative;
    height: 20vh;
    right: 5px;
}

.dataestate-title-process {
    flex: 1 1 auto; 
    border-radius: 4px;
    margin: 15px 0px 0 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    padding: 15px;
    position: relative;
    height: 30vh;
}
.dataestate-dashboard-wrapper {
    color: white;
    margin-bottom: 30px;
    .ibp-header {
        .ibp-header--title {
            border-right: 5px solid rgba(112, 173, 71, 1);
            border-radius: 4px;
            margin-left: 1%;
            display: inline-block;
            font-size: 22px;
            font-weight: 600;
            line-height: 30px;
            padding: 20px;
            position: relative;
            max-width: 32.6%;
        }
    }


	.last-refresh-box {
		padding: 10px;
		max-width: 33%;

		.last-refresh-label {
			font-size: .875rem;
			font-weight: normal;
			margin-top: 0;
		}
		.last-refresh-value {
			font-size: 20px;
			font-weight: 600;
			margin-top: 35px;
		}
	}

    .no-mr {
        margin-right: 0 !important;
    }
    .no-ml {
        margin-left: 0 !important;
    }
    .mb-5 {
        margin-bottom: 5px;
    }
    .mb-10 {
        margin-bottom: 10px;
    }
	.mt-10 {
		margin-top: 10px !important;
	}

    .wid-80 {
        display: inline-block;
        width: 80%;
    }

    .wid-18 {
        display: inline-block;
        width: 18%;
    }

    .pad-15 {
        padding: 16.1px 0 !important;
    }

    .pad-20 {
        padding: 20px 0 !important;
    }

    .pad-25 {
        padding: 26.4px 0 !important;
    }
	.bdr-none {
		border: none !important;
	}
    .bdr-yellow {
        border-color: rgba(252, 192, 70, 1) !important;
    }
    .bdr-red {
        border-color: rgba(254, 38, 38, 1) !important;
    }
	.bdr-blue {
        border-color: rgba(0, 145, 255, 0.874) !important;
    }
	.bdr-light-blue {
        border-color: rgba(41, 155, 255, 1) !important;
	}


    .bgcolor-green {
        background-color: rgba(112, 173, 71, 0.2) !important;
    }
    .bgcolor-maroon {
        background-color: rgba(252, 192, 70, 0.2) !important;
    }
    .bgcolor-brown {
        background-color: rgba(254, 38, 38, 0.2) !important;
    }
    .bgcolor-red {
        background-color: rgba(254, 38, 38, 0.2) !important;
    }
    .bgcolor-grey {
        background-color: rgba(27, 30, 53, 1) !important;
    }
	.bgcolor-blue {
        background-color: #003771 !important;
    }
	.bgcolor-light-blue {
		background: rgba(41, 155, 255, 0.2) !important;
	}
    
    
    .text-green {
        color: rgba(112, 173, 71, 0.2) !important;
    }
    .text-maroon {
        color: rgba(252, 192, 70, 0.2) !important;
    }
    .text-red {
        color: rgba(254, 38, 38, 0.2) !important;
    }

    .highlight-bg-green {
        background: rgba(0, 208, 141, 0.2) !important;
    }

    .highlight-text-green {
        color: rgba(0, 208, 141, 1) !important;
    }
}

// new change
.dataestate-dashboard-wrap {
    color: #E5E5E5;
    margin-top: 130px;
    padding: 0 24px;
}
.panel-legend--title {
    font-size: 16px;
    font-weight: normal;
    margin-top: 0;
    width:100%;
}
.panel-legend {
    background-color: rgba(0, 55, 113, 0.2);
    border-radius: 4px;
    padding: 15px;

    .monitor-head {
        min-height: 40px;
    }

    .panel-legend--title {
        font-size: 16px;
        font-weight: normal;
        margin-top: 0;
    }
    .panel-legend--title-summary {
        font-size: 16px;
        font-weight: 700;
        margin-top: 0;
    }

    .panel-box {
        background-color: rgba(0, 55, 113, 1);
        border-radius: 4px;
        padding: 10px 15px;

        .panel-box--title {
            font-size: 14px;
            font-weight: normal;
            margin-top: 0;
        }

        .panel-box--count {
            font-size: 30px;
            font-weight: 600;
        }
        .panel-box--count-summary {
            font-size: 25px;
            font-weight: 600;
        }
        .panel-box--count-summary-color-g {
            font-size: 25px;
            font-weight: 600;
            color:green
        }
        .conversion {
            font-size: 14px;
        }
    }

    .panel-box-summary {
        background-color: rgba(0, 55, 113, 1);
        border-radius: 4px;
        padding: 20px 15px;

        &.has-navigation {
            cursor: pointer;
        }

        .panel-box--title {
            font-size: 14px;
            font-weight: normal;
            margin-top: 0;
            min-height: 33px;
        }

        .panel-box--count {
            font-size: 30px;
            font-weight: 600;
        }
        .panel-box--count-summary {
            font-size: 25px;
            font-weight: 600;
        }
        .conversion {
            font-size: 14px;
        }
    }
}

.mr-10 {
    margin-right: 10px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-30 {
    margin-bottom: 30px;
}

.mt-30 {
    margin-top: 30px;
}
.mr-15 {
    margin-right: 15px;
}
.mb-0 {
    margin-bottom: 0;
}
.ml-5 {
    margin-left: 5px;
}
.ml-15 {
    margin-left: 15px;
}
.height-230 {
    height: 230px;
    overflow: hidden;
}

.costsummary-sector-filter {
    top: 70px !important;
    right: 50px !important;
}


.summary-ml {
    margin-left: -10px !important;
}

.arrow-icon-styles {
    width: 23px;
}
.arrow-icon-styles-up {
    width: 18px;
}
.text--green {
    color: #00A556 !important;
}.storage-row{
    .storage-col{
        background-color: #003771;
        padding: 10px;
        max-width: 32.8%;
        margin: 5px 0px;
        height: max-content;
        border-radius: 4px;
    }
    .storage-col6{
        max-width: 49.5%;
        background-color: #003771;
        padding: 10px;
        margin: 5px 0px;
        height: max-content;
        border-radius: 4px;
    }
    .storage-col6:nth-child(2){
        margin-left: 9px;
    }
    .storage-col:nth-child(2){
       margin-left: 9px;
       margin-right: 9px;
    }.storage-col h1{
        font-size: 54px;
        font-weight: 700;
        margin: 25px 0px;
    }.storage-col h1 span{
        font-size: 20px;
        margin-left: 10px;
    }.storage-col label{
        font-size: 14px;
        line-height: 19.5px;
        font-weight: 500;
    }.storage-col-graph{
        background-color: #003771;
        padding: 10px;
        margin: 5px 0px;
        height: max-content;
        max-width: 49.5%;
        border-radius: 4px;
        label{
            font-size: 14px;
            line-height: 19.5px;
            font-weight: 500;
            span{
                i{
                    color:#E5E5E5;
                    text-transform: capitalize;
                    font-weight: 400;
                    font-size: 14px;
                    margin-left: 5px;
                }
            }
        }
        .echarts-for-react {
            canvas{
                height: max-content;
            }
        }
    }.storage-col-graph:first-child{
        margin-right: 9px;
    }
    }.storage-base{
        padding-left: 25px;
        margin-top: 135px;
    }
    
.text--red {
    color: #FE2626 !important;
}.containerBI{
    flex-wrap: nowrap !important;
}.date-picker-summary{
    #single-date-select{
        padding: 10px 14px !important;
    }
}.col6{
    width: 49%;
}.chart-widget-query{
    height: max-content !important;
    padding: 6px;
}

.loading {
    font-size: 12px;
}
.min-height-30 {
    min-height: 30px;
}
.chart-widget-finop{
    min-height: 55vh; 
    height: 55vh;

}


.mb--20 {
    margin-bottom: -20px !important;
}

.finop_tabledata .rdg-header-row {
	background: #003771 !important;
}

.finop_tabledata .rdg {
	background: transparent !important;
    height: calc(100vh - 250px);
    border: none;
    overflow-y: auto !important;
    &::-webkit-scrollbar-thumb {
        background: #009BD8 !important;
    }
}

.mt-35 {
    margin-top: 35px !important;
}
.finop_tabledata .pagination-div {
    width: 100%;
    bottom: 0px;
    background: #002349;
}
.finop_tabledata .darkPagination {
    margin-bottom: 0;
    bottom: inherit;
    right: 0;
    height: 86px;
    background: #002349;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0px 0px;
    box-sizing: border-box;
    float: right;
}
// .finop_tabledata #customGrid
//     {
//     div:first-child{
//     button{
//         background-color: #0c2743 !important;
//         padding: 4px 2px;
//         border-radius: 5px !important;
//         position: absolute;
//         top: 20px;
//         width: 42px;
//         height: 45px;
//         margin: 0px 5px;
//         display: block;
//     }
// }
//     }
.title-italic{
    font-style: italic;
}

.chart-widget-finop-details{
    min-height: 35vh; 
    height: 35vh;

    .echarts-for-react
    {
        
    // margin-top: -50px !important;
    div:first-child{
    height: 100% !important;
}
    }


}
.page-table-finops {
    margin-top: 0;
}
.fineops-subheader {
    font-size: 46px;
    font-weight: 600;
    margin-top: 50px;
    margin-left: 5px;

    @media screen and (max-width: 1300px) {
        font-size: 32px;
        margin-top: 45px;
    }
}
.cost-tag {
    text-align: right;
}
.cost-finOps{
    width: 48%;
}

@media screen and (min-width: 1301px) {       
    .dataestate-title-fn {
        height: 26vh;
    }
    .chart-widget-finop {
        min-height: 53vh;
        height: 53vh;
    }
}
@media screen and (min-width: 1480px) {       
    .dataestate-title-fn {
        height: 23.5vh;
    }
    .chart-widget-finop {
        min-height: 48vh;
        height: 48vh;
    }
}
@media screen and (min-width: 1620px) {      
    .dataestate-title-fn {
        height: 22vh;
    }
    .chart-widget-finop {
        min-height: 45vh;
        height: 45vh;
    }
}
@media screen and (min-width: 1900px) {    
    .dataestate-title-fn {
        height: 19.5vh;
    }
    .chart-widget-finop {
        min-height: 40vh;
        height: 40vh;
    }
}

@media screen and (max-width: 1300px) {

    .dataestate-title-fn {
        flex: 1 1 auto;
        border-radius: 4px;
        margin: 10px 0 5px 6px;
        font-size: 14px;
        font-weight: normal;
        line-height: 40px;
        position: relative;
        height: 29vh;
        width: 48%;
    }
    .chart-widget-finop {
        min-height: 59vh;
        height: 59vh;
    }
    .monitor-head-exe {
        font-size: 13px;
    }
}

.finop_tabledata .highlightClass .rdg-cell {
    background-color: #062242;
}

.detailsFilter.filterContainers .MuiPaper-root{
    max-height: 400px;
    min-height: 375px;
}

.detailsFilter 
{
    .sector-list{
        width: 250px !important;
    }
    .dataGrowthselectAll-sec1{
        width: 185px;
        .list-items {
            ul {
                height: auto !important;

                .MuiTypography-root {
                    font-family: Montserrat;
                    color: #d0e7ff;
                }
            }
        }
    }
    .MuiPaper-root{
        width: 445px;
        left: auto !important;
        right: 20px !important;
    }
    .checkbox-sec-datagrowth{
        border-left: none !important;
        padding-left: 0;
        .loading {
            color: #fff;
        }
    }

    .MuiFormControlLabel-label {
        font-family: Montserrat;
        color: #d0e7ff;
    }
    .list-items{
        border-right: 1px solid rgba(113, 117, 143, 0.2705882353);
    }
}

.text-filter-rows-finops {
    .filters-text {
        color: #fff;
        font-family: Montserrat;
        font-weight: 600;
    }
    .buttonsSec1 {
        .makeStyles-okBtn-27 {
            background: #009BD8 !important;
            color: #F5F5F5 !important;
        }
    }
}

.filter-bg .MuiButton-root{
    float: right;
}
.rdg .rdg-cell:hover {
    background: none;
}
.jobsNameList:hover {
    background: none !important;
    padding: 10px 10px 10px 5px !important;
    border: none !important;
}

.datagrowth-filter-wrapper {
    .overall-filter {
        cursor: pointer;
        top: 70px;
        right: 38px;
        position: fixed;
        z-index: 999;
        width: 40px;
    }
}
.m-legend {
    margin-right: 10px !important;
    margin-bottom: 10px !important;
}

.program-arrow { 
    .MuiSelect-icon {
        color: #fff;
    }
}

.program-label {
    line-height: 30px;
    vertical-align: top;
    display: inline-block;
    margin-top: 5px;
    margin-left: 10px;
}

.exec-head {
    min-height: 0px !important;
}
.env-echart {
    min-height: 430px !important;
    .echarts-for-react {
        min-height: 425px !important;
        bottom: 35px !important;
    }
}
.service-echart {
    min-height: 430px !important;
    .echarts-for-react {
        min-height: 640px !important;
        bottom: 205px !important;
    }
    @media screen and (max-width: 1300px) {
        .echarts-for-react {
            min-height: 720px !important;
            bottom: 260px !important;
        }
    }
    @media screen and (min-width: 3440px) {
        .echarts-for-react {
           padding-top: 50px !important;
        }
    }
}
.teams-echart {
    min-height: 430px !important;
    .echarts-for-react {
        min-height: 552px !important;
        bottom: 141px !important;
    }
    @media screen and (max-width: 1300px) {
        .echarts-for-react {
            min-height: 495px !important;
            bottom: 140px !important;
        }
    }
    @media screen and (min-width: 3440px) {
        .echarts-for-react {
           padding-top: 50px !important;
        }
    }
}
@media screen and (min-width: 1900px) {    
    .service-echart {
        .echarts-for-react {
            min-height: 627px !important;
            bottom: 170px !important;
        }
    }
    .teams-echart {
        .echarts-for-react {
            min-height: 592px !important;
            bottom: 133px !important;
        }
    }
}
.head-ytd {
    padding-right: 16px !important;
}
.monitor-head-exe {
    position: relative;
    line-height: 16px;
    .badge {
        position: absolute;
        // right: 5px;
        top: 0px;
        padding: 1px 8px;
        border-radius: 50px;
        background-color: #009BD8;
        color: #001E3E;
        font-size: 12px;
        font-weight: 600;
        z-index: 9;
    }
    .badge-sec {
        top: 33px;
    }
}
.no-data {
    margin-top: -10px !important;
    color: #fff !important;
}
.search-filter {
    top: 0px;
    width: 97%;
    background: #002349;
    backdrop-filter: blur(10px);
    border-radius: 4px;
    margin-left: 0px !important;
    margin-bottom: 10px !important;
    font-family: 'Montserrat';
    padding-left: 10px !important;
    color: white;
    input {
      padding-left: 0px;
      background: transparent;
      &::placeholder {
        font-size: 12px;
        font-family: 'Montserrat';
        text-transform: capitalize;
        color: #E5E5E5;
      }
      &:active,
      &:focus,
      &:hover {
        background: transparent;
      }
      &:placeholder-shown {
        background: transparent;
        background-color: transparent;
      }
    }
  }
  .filterContainers::-webkit-scrollbar-thumb {
    background-color: #0070BD !important;
  }
  .records-msg{
	color: white;
}

.highlightClass .rdg-cell:hover {
    background-color: #0c0f25 !important;
    border-right: none !important;
}

.rm-icons {
    width: 20px;
    height: 20px; 
    cursor: pointer;
}

.rm-del-icons {
    width: 30px;
    height: 30px; 
    cursor: pointer;
    // margin-left: 0px !important;
}

.accord .MuiAccordionDetails-root {
    border: 2px solid #003771 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    background-color: #003771 !important;
    background: #003771 !important;
}