@import '../../../theme-variables.scss';

.tableau_title {
    color: $color-white;
    font-family: Montserrat;
    font-size: $font-size-default;
    font-style: normal;
    font-weight: $font-weight-700;
    line-height: normal;
    margin-bottom: 10px;
}

.tableauJobs_tabledata {
    padding: 0px 15px;
    margin-top: 28px;
    height: calc(100vh - 130px);
    overflow-y: auto !important;

    .MuiChip-filled {
        height: 20px;
        width: 85px;

        &.slaChip {
            width: 55px;
        }
    }
}

.MuiChip-label {
    padding: 0 10px;
    font-size: $font-size-tiny;
}


.rdg {
    background: $theme-color-orange-bg;
    height: calc(100vh - 300px);
    border: none;
    overflow-y: auto !important;

    .rdg-cell {
        font-weight: $font-weight-300;
        padding: 4px 7px 4px 10px !important;
        color: $color-white;
        border: none;
        line-height: 28px;
        font-size: $font-size-small;

        &:hover {
            z-index: 111;
            max-width: initial;
            background: $theme-color-orange-bg;
        }

        &[aria-selected='true'] {
            outline: none;
        }

        a {
            color: $color-white;
            text-decoration: none;
        }

        &[aria-sort='descending'],
        &[aria-sort='ascending'] {
            .rdg-sort-arrow {
                fill: none;
                height: 20px;
            }
        }

        &.center-text {
            text-align: center;
        }

        &.has-editor {
            input {
                border: 1px solid #3f3a4b;
            }
        }
    }
}

.rdg-text-editor {
    appearance: none;
    box-sizing: border-box;
    inline-size: 100%;
    block-size: 100%;
    border: $color-secondary;
    color: $color-white;
    background-color: $primary-color-bg;
    font-family: 'Montserrat';
    font-size: $font-size-small;
    height: 30px;

    &::placeholder {
        color: $color-grey;
        opacity: 1;
    }

    &:focus {
        border: 1px solid;
        border-color: $color-white;
        outline: none;
    }
}

.rdg-editor-container {
    .MuiInputBase-root {
        font-family: Montserrat !important;
        font-size: $font-size-small;
    }
}

.reasonForDelay:hover {
    position: absolute;
}


.rdg-row:nth-child(even) {
    background: none;
}

.rdg-header-row {
    background: $color-secondary;

    .rdg-cell {
        border-right: $color-primary;
    }
}

.rdg-row:nth-child(odd) {
    background: rgba(113, 117, 143, 0.05);

    .rdg-cell {
        border-right: 1.5px solid #1b1e35;
    }
}

.rdg-cell:last-child {
    border-right: none !important;
}

.highlightClass {
    .rdg-cell {
        background-color: $primary-color-bg;
        border-right: none !important;

        &:hover {
            background-color: $primary-color-bg;
        }
    }
}


.tableauJobs_tabledata .rdg-cell.has-editor:hover {
    outline: 1px solid #3f3a4b;
    background: #3f3a4baa;
}

.tableauJobs_tabledata .rdg-cell.has-editor.rdg-editor-container:hover {
    outline: none;
}

.tableauJobs_tabledata .has-editor.rdg-editor-container {
    padding-left: 0 !important;
    padding-top: 2px !important;
}

.tableauJobs_tabledata .has-editor.rdg-editor-container * {
    background: none !important;
}

.jobs-run-table {
    .tableauJobs_tabledata .rdg {
        height: 47vh;
    }
}

.databricks-jobs {
    .main-content {
        overflow: scroll !important;
        height: calc(100vh) !important;
    }
}

.left-iframe-div {
    float: left;
    /* width: 46.6vw; */
    padding: 0 !important;
    position: relative;
}

.right-iframe-div {
    transform: translateX(-27px);
    float: right;
    /* width: 46.6vw; */
    position: relative;
}

.second-iframe-container {
    transform: translateY(-10%);
    display: flex;
}

.jobsNameList:hover {
    background: $color-primary;
    padding: 10px !important;
    border: 1px solid $color-grey-md;
}

.tableau-dateFilter-div {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 50px;
    right: 20px;
    margin-right: 2px;
    background-color: $color-blue-medium;
    padding: 2px;
    z-index: 99;
    position: fixed;
    top: 14%;
}

.separeat_sector {
    padding: 15px;
    border-left: $color-secondary;
    margin-left: 5px;
}

.single-calendar-performance {
    display: flex;
    align-items: center;
    margin-top: -12px !important;
    margin-right: -27px !important
}

.job-drilldown {
    position: absolute;
    right: 40px;
    top: 30px;
    z-index: 9;

    .types {
        background-color: $secondary-color-bg;
        color: $color-white;
        font-family: Montserrat;
        font-size: $font-size-base;
        font-weight: $font-weight-500;
        text-align: center;
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;

        &.active {
            background-color: $color-primary;
            color: $color-tertiary ;
            font-weight: $font-weight-800;
        }
    }
}

.right-job-drilldown {
    right: 5px;
}

.performance-details-symbol {
    position: absolute;
    top: 111px;
    left: 200px;
    color: $color-grey-md;
    font-size: $font-size-larger;
}

.performance-details-title {
    position: absolute;
    top: 112px;
    left: 220px;
    font-weight: bold;
}

.performance-header-name {
    position: absolute;
    margin-left: 30px;
    margin-top: 29px;
    color: $primary-color-bg !important;
    padding: 20px;
    width: 310px;
}

.performance-model-menu {
    color: $color-grey;
    font-size: $font-size-larger;
    font-weight: $font-weight-600;
    font-family: 'Montserrat';
    padding-top: 20px;
    position: absolute;
    top: 40px;
}
.goBackButton{
    position: absolute;
    top: 100px;
    right: 5px;
    background: $color-secondary !important;
    border: 1px solid $primary-color-bg;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 3px !important;

    .MuiButton-startIcon{
        display: inherit;
        margin-right: 0px;
        margin-left: -4px;  
    }

}
.server-select {
    display: flex;
    align-items: center;
    margin-top: -12px !important;
    width: 12vw;
}

.server-icon {
    position: absolute;
    left: 130px;
    top: 20px;
}
.dateFilterTD {
    margin: 0;
    width: 87%;
}
