@import '../../theme-variables.scss';
.iframe-height-vv {
    .main-content {
        height: 80vh !important;
        overflow: scroll !important;
    }
    }
.under-development {
    position: absolute;
    left: 45%;
    top: 50%;
    color: darkgray;
}

.under-development-text {
    position: relative;
    right: 25%;
    top: 50%;
    margin: 0px;
}