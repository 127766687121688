.page-table {
    padding: 0px 16px;
}

.reasonOfSLA_tabledata .rdg {
    background: #0c0f25;
height: calc(100vh - 250px);
    border: none;
    overflow-y: auto !important;
}

.reasonOfSLA_tabledata .rdg-cell {
    font-weight: 300;
    padding: 4px 7px 4px 10px !important;
    color: white;
    border: none;
    line-height: 28px;
    font-size: 12px;
}

.reasonOfSLA_tabledata .rdg-cell[aria-selected='true'] {
    outline: none;
}

.reasonOfSLA_tabledata .rdg-cell:hover {
    overflow: visible;
    z-index: 111;
    max-width: initial;
    background: #0c0f25;
}

.reasonOfSLA_tabledata .rdg-cell[aria-sort='descending'] .rdg-sort-arrow,
.reasonOfSLA_tabledata .rdg-cell[aria-sort='ascending'] .rdg-sort-arrow {
    fill: none;
    height: 20px;
}

.reasonOfSLA_tabledata .rdg-row:nth-child(even) {
    background: none;
}

.reasonOfSLA_tabledata .rdg-header-row {
    background: #1b1e35;
}

.reasonOfSLA_tabledata .rdg-row:nth-child(odd) {
    background: rgba(113, 117, 143, 0.05);
}

.reasonOfSLA_tabledata .rdg-row:nth-child(odd) .rdg-cell {
    border-right: 1.5px solid #1b1e35;
}

.reasonOfSLA_tabledata .rdg-header-row .rdg-cell {
    border-right: 1.5px solid #0c0f25;
}

.reasonOfSLA_tabledata .rdg-cell:last-child {
    border-right: none !important;
}

.reasonOfSLA_tabledata .reasonForDelay:hover {
    position: absolute;
}

.reasonOfSLA_tabledata .highlightClass .rdg-cell {
    background-color: #71758f66;
    border-right: none !important;
}

.reasonOfSLA_tabledata .highlightClass:hover .rdg-cell {
    background-color: #71758f66;
}