.cal-icon {
    float: left;
    height: 15px !important;
    margin: 2px 10px 0px;
    margin-left: 20px !important;
}
.cal-txt {
    margin: 0px;
    float: left;
}
.box-panel {
    overflow-y: scroll;
    scrollbar-width: none;
}
.h-360 {
    height: 360px !important;

}
.job-execution-box-panel {
    height: 441px;
}
.attrValue {
    color: #e5e5e5;
    font-family: 'Montserrat';
    font-size: 13px;
    line-height: 20px;
}
.attrValue img {
    height: 10px;
    margin-left: 5px;
}
.attrTitle {
    color: #71758f;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
}
/* MUI chips */
.MuiChip-colorSuccess {
    background-color: #17372e;
    color: #01c008;
}

.MuiChip-colorSecondary {
    background-color: #3d2033;
    color: #cd2429;
}

.MuiChip-colorWarning {
    background-color: #ffa20033;
    color: #ffa200;
}

.MuiChip-colorDefault {
    background-color: #0c0f25;
    color: #e5e5e5;
    margin-bottom: 10px;
    max-width: 106px;
    margin-right: 10px;
    height: 24px;
}
/* MUI Paper */
.MuiPaper-root,
.MuiTableCell-root {
    background-color: #1b1e35;
    color: #e5e5e5;
    border: none !important;
    padding: 5px;
}
.root,
#root {
    height: 100%;
    overflow: auto;
    max-height: 100%;
}
.job-execution-iframe-div {
    padding-bottom: 0;
    margin-bottom: 0;
}