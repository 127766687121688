.resourceGroupDetails-overview-table {
	margin: 10px 0 0 4px;
}
.resourceGroupDetails-overview-table .attrTitle {
	font-size: 12px;
	padding-bottom: 20px;
	vertical-align: top;
	width: 16%;
	max-width: 140px;
	color: #71758f;
}
.resourceGroupDetails-overview-table .attrValue {
	font-size: 12px;
	padding-bottom: 20px;
	width: 20%;
	max-width: 25%;
	word-break: break-all;
	vertical-align: top;
}
.resourceGroups_tabledata {
	padding: 0px 16px;
	margin-top: 80px;
}
.resources_tabledata {
	padding: 0px 16px;
	margin-top: 10px;
}

.resourceGroups_tabledata .rdg {
	background: #0c0f25;
	height: calc(100vh - 250px);
	border: none;
	overflow-y: auto !important;
}
.feature-drift-table .rdg {
	background: #0c0f25;
	height: fit-content;
	border: none;
}
.feature-drift-table .darkPagination {
	height: 50px !important;
	width: 325px !important;
	margin-left: 15px !important;
}
.feature-drift-table .customPaginationText {
	margin-top: 15px !important;
	margin-left: 5px !important;
}
.accordin-title {
	font-family: Montserrat;
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}
.accordin-subtitle {
	font-family: Montserrat;
	font-size: 16px;
	font-style: italic;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	padding-left: 5px;
	color: #e5e5e5;
}
.resourceGroups_tabledata .rdg-cell {
	font-weight: 300;
	padding: 4px 7px 4px 10px !important;
	color: white;
	border: none;
	line-height: 28px;
	font-size: 12px;
}

.resourceGroups_tabledata .rdg-cell[aria-selected='true'] {
	outline: none;
}

.resourceGroups_tabledata .rdg-cell:hover {
	overflow: visible;
	z-index: 111;
	max-width: initial;
	background: #0c0f25;
}

.resourceGroups_tabledata .rdg-cell[aria-sort='descending'] .rdg-sort-arrow,
.resourceGroups_tabledata .rdg-cell[aria-sort='ascending'] .rdg-sort-arrow {
	fill: none;
	height: 20px;
}

.resourceGroups_tabledata .rdg-row:nth-child(even) {
	background: none;
}

.resourceGroups_tabledata .rdg-header-row {
	background: #1b1e35;
}

.resourceGroups_tabledata .rdg-row:nth-child(odd) {
	background: rgba(113, 117, 143, 0.05);
}

.resourceGroups_tabledata .rdg-row:nth-child(odd) .rdg-cell {
	border-right: 1.5px solid #1b1e35;
}

.resourceGroups_tabledata .rdg-header-row .rdg-cell {
	border-right: 1.5px solid #0c0f25;
}

.resourceGroups_tabledata .rdg-cell:last-child {
	border-right: none !important;
}
.resourceGroups_tabledata .sector:hover {
	position: absolute;
}

.resourceGroups_tabledata .highlightClass .rdg-cell {
	background-color: #71758f66;
	border-right: none !important;
}

.resourceGroups_tabledata .highlightClass:hover .rdg-cell {
	background-color: #71758f66;
}

.resourceGroups_tabledata .selectedClass .rdg-cell {
	border: 1px solid !important;
	border-right: none !important;
	border-left: none !important;
	border-color: #00d08d !important;
}

.resourceGroups_tabledata .selectedClass .rdg-cell:last-child {
	border-right: 1px solid #00d08d !important;
}
.resourceGroups_tabledata .selectedClass .rdg-cell:first-child {
	border-left: 1px solid #00d08d !important;
}
.resourceGroups_tabledata .rdg-cell a {
	color: white;
	text-decoration: none;
}

.resourceGroups_tabledata .rdg-cell.has-editor:hover {
	outline: 1px solid #3f3a4b;
	background: #3f3a4baa;
}

.resourceGroups_tabledata .reasonForDelay:hover {
	position: absolute;
}

.resourceGroups_tabledata .rdg-cell.has-editor.rdg-editor-container:hover {
	outline: none;
}

.resourceGroups_tabledata .has-editor.rdg-editor-container {
	padding-left: 0 !important;
	padding-top: 2px !important;
}
.resourceGroups_tabledata .has-editor.rdg-editor-container:focus {
	border-bottom: #00d08d 1px solid;
}

.resourceGroups_tabledata .has-editor.rdg-editor-container * {
	background: none !important;
}

.resourceGroups_tabledata .rdg-cell.has-editor input {
	border: 0px solid #3f3a4b;
	color: #e5e5e5;
}
.resourceGroups_tabledata .rdg-cell .editable_ph {
	color: #71758f;
	font-style: italic;
	font-weight: 400;
}
.resourceGroups_tabledata .row-select-drpdwn .MuiSelect-icon {
	left: 95% !important;
}
.resourceGroups_tabledata .MuiInput-root::after {
	border-bottom: none;
	width: 140px !important;
}
.resourceGroupsNameList:hover {
	background: #0c0f25;
	padding: 10px !important;
	border: 1px solid #71758f;
}

.model-details-panel-stack {
	padding: 0px 10px 10px 35px;
}
.resourceGroup-details-panel-stack {
	margin-top: 120px;
	margin-left: 10px;
}

.w-full {
	width: 100%;
}
.w-60 {
	width: 60%;
}
.w-40 {
	width: 40%;
}
.mt-105 {
	margin-top: 105px;
}
.mt-95 {
	margin-top: 95px;
}
.mt-12px {
	margin-top: 12px;
}
.mt-15 {
	margin-top: 15px;
}
.ml-5 {
	margin-left: 5px !important;
}

.resources_tabledata .dateFilter-formControl {
	width: 260px;
	margin: -15px;
	padding: 0px;
}
.resources_tabledata .dateFilter-formControl .MuiOutlinedInput-root {
	height: 35px !important;
	padding: 0px !important;
}
.resources_tabledata .rdg-cell.has-editor .dateFilter-formControl input {
	border: none !important;
}
.resources_tabledata .MuiInput-root::after {
	border-bottom: 2px solid #00d08d;
	width: 200px !important;
}
.resources_tabledata .row-select-drpdwn .MuiSelect-icon {
	left: 95% !important;
}
.resources_tabledata .datepicker_placeholder {
	position: fixed;
}
.resources_tabledata .datepicker_placeholder_icon {
	float: right;
	width: 15px;
}
.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px; 
  }   
  .custom-button {
    padding: 10px 20px;
    background-color: rgba(27, 30, 53, 1);
    color: #00d08d;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: -39px;
    
  }
  
  .custom-button:hover {
    background-color: #00d08d;
    color: white;
  }
.transparent-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 3px solid rgba(113, 117, 143, 1); 
    width: 16px; 
    height: 16px; 
    background-color: transparent; 
}
.transparent-checkbox:checked {
    background-color: #00d08d; /* Change background color when checked */
    border-color: rgba(113, 117, 143, 1); /* Change border color when checked */
}
.transparent-checkbox:checked::before {
    content: '\2713'; /* Unicode character for a checkmark */
    display: inline-block;
    font-size: 12px; /* Adjust size as needed */
    line-height: 16px; /* Adjust line-height to vertically center */
    color: white; /* Color of the checkmark */
    visibility: visible; /* Ensure the checkmark is visible */
}
.custom-button-1 {
    background-color: #00d08d;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: -39px;
}
.resource-heading {
	position: relative;
}

.search-icon-wrap {
	position: absolute;
	right: 65px;
	float: right;
	.search-icon {
		border-radius: 0px;
		cursor: pointer;
		height: 42px;
	}
	.highlight-icon {
		background-color: #00D08D;
		border-radius: 50px;
		display: inline-block;
		height: 8px;
		position: absolute;
		right: 4px;
		width: 8px;
	}
}

.clear-filter {
	cursor: pointer;
	text-align: right;
	color:#00d08d;
	top: 412px;
	float: right;
	right:135px;
	position:absolute;
	font-size: 14px;
    font-weight: 500;
}

// .search-icon-wrap {
// 	position: relative;
// 	right: 50px;
// 	float: right;
// 	.search-icon {
// 		border-radius: 0px;
// 		cursor: pointer;
// 		height: 42px;
// 	}
// }

// .clear-filter {
// 	cursor: pointer;
// 	color:#00d08d;
// 	right: 55px;
// 	float: right;
// 	position: relative;
// 	font-size: 16px;
// 	top: -17px;
// }

.popup-container {
    position: fixed;
    top: 50%;
    left: 25%;
	width: 50%;
	transform: translateY(-50%);
    background-color: rgba(12, 15, 37, 1);
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 9; 
	.popup-header {
		background-color: rgba(27, 30, 53, 1);
		color: #fff;
		padding: 10px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		.close-button {
			float: right;
			background-color: rgba(27, 30, 53, 1);
			color: white;
		}
	}
	
	.popup-content {
		margin-bottom: 12px;
		margin-top: 40px;
		color: rgba(113, 117, 143, 1);
	}
	
	.popup-actions {
		display: flex;
		justify-content: flex-end;
	}
}

