@import '../../theme-variables.scss';

.platformCost_title_div {
    display: flex;
    align-items: center;
    margin-top: -10px;
    color: $color-grey;
    font-family: Montserrat;
    font-size: $font-size-default;
    font-style: normal;
    font-weight: $font-weight-700;
    line-height: normal;
    margin-left: 18px;
}

.platformCost_title {
    color: $color-white;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: $font-weight-800;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 10px;
}

.sector-filter {
    top: 50px;
    left: 95%;
    position: absolute;
}

.pcobs-menu {
    color: $color-grey;
    font-size: $font-size-larger;
    font-weight: $font-weight-600;
    font-family: 'Montserrat';
    padding-top: 20px;
}

.pcobs-icon {
    width: 40px;
    margin-top: 31px;
    margin-left: 33px;
    padding-top: 0px;
    position: absolute;
}

.pcobs-details-icon {
    width: 40px;
    margin-top: 46px;
    margin-left: 25px;
    padding-top: 0px;
    position: absolute;
}

.pcobs-header-name {
    position: absolute;
    margin-left: 75px;
    margin-top: 40px;
}

.pcobs-details-header-name {
    position: absolute;
    margin-left: 70px;
    margin-top: 55px;
}

.pcobs-page-title {
    color: $color-grey;
    font-size: $font-size-medium;
    font-weight: 600;
    font-family: 'Montserrat';
    position: absolute;
    top: 58px;
    left: 310px;
}

.pcobs-symbol {
    color: $color-grey;
    font-size: $font-size-larger;
    font-weight: $font-weight-600;
    font-family: 'Montserrat';
    position: absolute;
    top: 58px;
    left: 285px;
}

.edit-icon {
    float: right;
    width: 15px;
    margin-top: 5px;

    path {
        fill: #e5e5e5;
    }
}
.pcobs-summary-panel{
    margin-top: 45px;
}

.details-view{
    position: relative;
    top: 30px;
}
