@import '../../../theme-variables.scss';

// Additional styles for tableau
.tableau-page-table {
	padding: 0px 16px;
  }
  
  .tableau_tabledata {
	height: calc(100vh - 130px);
	overflow-y: auto !important;
	.rdg {
	  background: $color-primary;
	  height: calc(100vh - 260px);
	  border: none;
	  overflow-y: auto !important;
	}
  
	.rdg-cell {
	  font-weight: $font-weight-300;
	  padding: 4px 7px 4px 10px !important;
	  color: $color-white;
	  border: none;
	  line-height: 28px;
	  font-size: $font-size-small;
  
	  &[aria-selected='true'] {
		outline: none;
	  }
  
	  &:hover {
		overflow: visible;
		z-index: 111;
		max-width: initial;
		background: $color-primary;
	  }
  
	  &[aria-sort='descending'] .rdg-sort-arrow,
	  &[aria-sort='ascending'] .rdg-sort-arrow {
		fill: none;
		height: 20px;
	  }
	}
  
	.rdg-row:nth-child(even) {
	  background: none;
	}
  
	.rdg-header-row {
	  background: $color-secondary;
	}
  
	.rdg-row:nth-child(odd) {
	  background: rgba(113, 117, 143, 0.05);
	}
  
	.rdg-row:nth-child(odd) .rdg-cell {
	  border-right: 1.5px solid $color-secondary;
	}
  
	.rdg-header-row .rdg-cell {
	  border-right: 1.5px solid $color-primary;
	}
  
	.rdg-cell:last-child {
	  border-right: none !important;
	}
  
	.reasonForDelay:hover {
	  position: absolute;
	}
  }
  
  .TableauTableHeading {
	font-weight: $font-weight-700;
	  margin-top: 30px !important;
	  margin-bottom: 10px !important;
	
  }
  
  .summary-panel {
	margin-top: 11%;
  }
  
  .separeat_sector {
	padding: 15px;
	border-left: 1.5px solid $color-secondary;
	margin-left: 5px;
  }
  
  .MuiInputBase-root {
	color: $color-white !important;
  }
  
  .single-calendar-summary {
	display: flex;
	align-items: center;
	margin-top: -12px !important;
	margin-right: -27px !important;
  }

  .server-select {
    display: flex;
    align-items: center;
    margin-top: -12px !important;
    width: 12vw;
}

.server-icon {
    position: absolute;
    left: 130px;
    top: 20px;
}
.dateFilterTD {
    margin: 0;
    width: 87%;
}
