.checkbox-sec {
	margin-left: 3px !important;
	float: left;
	margin-top: 10px;
	margin-bottom: 20px;
}
.checkbox-sec .MuiCheckbox-root {
	color: #71758f;
	width: 20px;
	height: 20px;
}
.checkbox-sec label {
	height: max-content !important;
}
.checkbox-sec label {
	height: 30px;
	font-family: 'Montserrat';
	float: left;
	margin-left: 0px;
}
.checkbox-sec label span {
	color: #f5f5f5;
	font-size: 12px;
	padding-left: 10px;
}
.filterContainer .MuiPaper-root {
	height: min-content;
	width: 240px;
	margin-left: 50px;
	padding: 0px;
}
.filterContainer .MuiList-root {
	padding-bottom: 0px !important;
	padding-top: 0px;
}
.filterContainer .MuiPaper-root li {
	display: block;
	height: max-content !important;
	width: 100%;
}
.filterContainer .MuiPaper-root .checkbox-sec li {
	height: 30px !important;
}
.buttonSec {
	display: block;
	float: left;
	width: 100%;
	clear: both;
}
.filter-green-dot {
	position: sticky;
	z-index: -1;
	margin-left: -11% !important;
	margin-top: 5px !important;
}
.selectAll {
	background: rgba(113, 117, 143, 0.4);
	border: 1px solid #3d3a50;
	border-radius: 8px;
	padding: 0px 20px 5px;
	float: left;
	width: 100%;
}

.selectAll .MuiCheckbox-root {
	color: #71758f;
	width: 20px;
	height: 20px;
	margin-top: 7px;
}

.selectAll label span {
	color: #f5f5f5;
	font-size: 12px;
	padding-left: 10px;
	padding-top: 7px;
	font-family: 'Montserrat';
}

.selectAll label{
	margin-left:4px;
}

.selectAll input[type='checkbox']:checked,
.selectAll-sec {
	height: 140px;
	overflow: auto;
	padding: 0px;
	margin: 0px;
}

.selectAll-sec label {
	float: left;
	width: 100%;
	font-family: 'Montserrat';
}
.selectAll-sec input {
	padding: 8px;
	margin: 2px 4px 2px 4px;
    width: 100%;
    box-sizing: border-box;
    background-color: #3D3A50;
    border-radius: 2%;
}
.text-filter-row img {
	float: left;
}
.text-filter-row p {
	float: left;
	margin: 0px 0px 0px 10px;
	text-transform: capitalize;
	line-height: 15px;
	font-family: 'Montserrat';
}
.text-filter-sec {
	float: left;
	width: 90%;
	border-top: 1px solid #71758f;
	padding: 15px 0px 0px 30px;
}
.filter-row {
	padding: 15px 20px 10px 20px !important;
	width: 86%;
	float: left;
}

.filter-row label span {
	font-size: 14px;
	text-transform: capitalize;
	font-family: 'Montserrat';
	padding-right: 0px;
}

.filter-row span svg {
	width: 18px;
	height: 18px;
}

.filter-row span svg:first-child {
	margin-right: 10px;
}

.filter-row img {
	float: left;
	margin-top: 0px;
	margin-right: 10px;
}

.filter-row:nth-child(2) {
	padding: 0px 20px 15px 30px;
}
.filter-row a {
	text-transform: capitalize;
	color: #f5f5f5;
	float: left;
	width: 86%;
	font-size: 14px;
	text-decoration: none;
	font-family: 'Montserrat';
}
.filter-row p {
	float: left;
}
.filter-row img {
	margin-top: 3px;
}

.MuiDayPicker-slideTransition .MuiDayPicker-weekContainer button.Mui-disabled {
	color: rgba(229, 229, 229, 0.2666666667) !important;
}

/* Timepicker MUI */
.MuiTimePickerToolbar-hourMinuteLabel {
	margin-top: 25%;
}

.MuiTimePickerToolbar-root .MuiTypography-root {
	font-family: Montserrat !important;
	color: #f5f5f5;
	text-transform: unset;
	font-weight: 700px;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.07);
	border-radius: 4px;
}

.EditSLATimePicker .PrivatePickersToolbarText-root.Mui-selected,
.EditSLATimePicker .PrivatePickersToolbarText-root.MuiTypography-root {
	color: #f5f5f5;
	font-size: 55px;
	background: #71758f66;
	padding: 3px;
	border-radius: 9px;
	width: 70px;
}

.PrivatePickersToolbarText-root.Mui-selected {
	border: 1px solid #00d08d;
}

.MuiTimePickerToolbar-separator.MuiTypography-root {
	background: none;
	width: 10px;
}

.MuiPickersArrowSwitcher-button {
	background-color: transparent;
	color: #00d08d;
}

.MuiPickersArrowSwitcher-button.Mui-disabled {
	background-color: transparent;
	color: #00d08d55;
}

.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockNumber-root.Mui-selected {
	background-color: #00d08d;
}

.MuiPickersToolbar-penIconButton {
	display: none;
}
.time-picker-zone {
	position: fixed;
	margin-top: 30%;
}
/* MUI Menu */
.MuiMenu-paper {
	background-color: #1b1e35 !important;
	color: #f5f5f5 !important;
}

.MuiMenuItem-root {
	height: 30px !important;
	font-family: Montserrat !important;
	font-size: 12px;
	font-size: 12px;
	padding: 6px;
}

.MuiInputBase-root {
	font-family: Montserrat !important;
}

.MuiFormControlLabel-label.Mui-disabled {
	color: #f5f5f5 !important;
	opacity: 0.5;
}
.jobs-list-datas .dateFilter .MuiSvgIcon-root,
.jobs-list-table .dateFilter .MuiSvgIcon-root,
.jobs-run-table .dateFilter .MuiSvgIcon-root {
	color: #71758f;
}

.jobs-list-datas .dateFilter input,
.jobs-list-table .dateFilter input,
.jobs-run-table .dateFilter input {
	color: white;
	font-size: 12px;
}

.jobs-list-datas .dateFilter-formControl,
.jobs-list-table .dateFilter-formControl,
.jobs-run-table .dateFilter-formControl {
	width: 95%;
	float: left;
	margin: 0px 10px 20px;
}

.jobs-list-datas .dateFilter-div,
.jobs-list-table .dateFilter-div,
.jobs-run-table .dateFilter-div {
	display: block;
	clear: both;
	padding: 25px 0px 20px;
	float: left;
	border-top: 1px solid #71758f;
}

.jobs-list-datas .dateFilter .MuiOutlinedInput-notchedOutline,
.jobs-list-table .dateFilter .MuiOutlinedInput-notchedOutline,
.jobs-run-table .dateFilter .MuiOutlinedInput-notchedOutline {
	border: none;
	/* border-bottom: 1px solid #71758f; */
	border-radius: 0px;
}

.jobs-list-datas .dateFilter .MuiInputAdornment-root,
.jobs-list-table .dateFilter .MuiInputAdornment-root,
.jobs-run-table .dateFilter .MuiInputAdornment-root {
	margin-left: 0px;
}

.jobs-list-datas .dateFilter .MuiOutlinedInput-root.Mui-focused,
.jobs-list-table .dateFilter .MuiOutlinedInput-root.Mui-focused,
.dateFilter .MuiOutlinedInput-notchedOutline:hover,
.jobs-run-table .dateFilter .MuiOutlinedInput-root.Mui-focused {
	border-color: #00d08d;
}

.jobs-list-datas .dateFilter .editJobForm .MuiOutlinedInput-root.Mui-focused,
.jobs-list-table .dateFilter .editJobForm .MuiOutlinedInput-root.Mui-focused,
.dateFilter .MuiOutlinedInput-notchedOutline,
.jobs-run-table .dateFilter .editJobForm .MuiOutlinedInput-root.Mui-focused {
	border-color: #71758f;
}

.jobs-list-datas .dateFilter-formControl .MuiInputBase-root:hover,
.jobs-list-table .dateFilter-formControl .MuiInputBase-root:hover,
.jobs-run-table .dateFilter-formControl .MuiInputBase-root:hover {
	border-bottom: 1px solid #00d08d;
	border-radius: 0px;
}

.jobs-list-datas .MuiButtonBase-root,
.jobs-list-table .MuiButtonBase-root,
.jobs-run-table .MuiButtonBase-root {
	border-radius: 0px;
	background: transparent;
	color: white;
	&.Mui-disabled {
		color: rgba(0, 0, 0, 0.26) !important;
	}
}

.jobs-list-datas .MuiPickersPopper-root .MuiTypography-root.Mui-selected,
.jobs-list-table .MuiPickersPopper-root .MuiTypography-root.Mui-selected,
.jobs-run-table .MuiPickersPopper-root .MuiTypography-root.Mui-selected {
	font-size: 15px;
	color: #00d08d;
	padding: 0px;
	border: none;
}

.jobs-list-datas .MuiPickersDay-root.Mui-selected,
.jobs-list-table .MuiPickersDay-root.Mui-selected,
.jobs-run-table .MuiPickersDay-root.Mui-selected {
	background-color: #00d08d !important;
}

.jobs-list-datas .MuiPickersPopper-root,
.jobs-list-table .MuiPickersPopper-root,
.jobs-run-table .MuiPickersPopper-root {
	top: 0px;
}

.jobs-list-datas .MuiDateTimePickerToolbar-separator,
.jobs-list-table .MuiDateTimePickerToolbar-separator,
.jobs-run-table .MuiDateTimePickerToolbar-separator {
	padding: 10px;
}

.jobs-list-datas .MuiPickersPopper-root .MuiClockNumber-root,
.jobs-list-table .MuiPickersPopper-root .MuiClockNumber-root,
.jobs-run-table .MuiPickersPopper-root .MuiClockNumber-root {
	color: white;
}

.jobs-list-datas .MuiPickersPopper-root .MuiClockPicker-root,
.jobs-list-table .MuiPickersPopper-root .MuiClockPicker-root,
.jobs-run-table .MuiPickersPopper-root .MuiClockPicker-root {
	margin-top: 10px;
}

.jobs-list-datas .MuiDateTimePickerToolbar-dateContainer .MuiTypography-root,
.jobs-list-datas .MuiPickersPopper-root .MuiTypography-root,
.jobs-list-table .MuiDateTimePickerToolbar-dateContainer .MuiTypography-root,
.jobs-list-table .MuiPickersPopper-root .MuiTypography-root,
.jobs-run-table .MuiPickersPopper-root .MuiTypography-root {
	font-family: Montserrat !important;
	color: #f5f5f5;
	text-transform: unset;
	font-size: 15px;
	font-weight: 700px;
}

.jobs-list-datas .MuiDateTimePickerToolbar-root .time-picker-zone,
.jobs-list-table .MuiDateTimePickerToolbar-root .time-picker-zone,
.jobs-run-table .MuiDateTimePickerToolbar-root .time-picker-zone {
	margin-top: 15%;
	color: white;
	margin-bottom: 10px;
}

.dateFilter {
	padding-top: 10px !important;
}

.dateFilter-formControl label {
	margin: 22px 0px 20px 10px !important;
}

.clusterFailureFilter .MuiPaper-root {
	height: min-content;
	width: auto;
	padding: 0px;
	}

.costSummaryFilter .MuiPaper-root {
	height: min-content;
	width: auto;
	padding: 0px;
}