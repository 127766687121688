@import '../../../theme-variables.scss';

.extract_failure_title {
    background: #1b1e35;
    padding: 15px;
    border-radius: 352px;
    margin-bottom: 15px;
    width: fit-content;
}

.tableau_title {
    color: $color-white;
    font-family: Montserrat;
    font-size: $font-size-default;
    font-style: normal;
    font-weight: $font-weight-700;
    line-height: normal;
    margin-bottom: 10px;
}

.extractFailure_tabledata {
    padding: 0px 15px;
    margin-top: 110px;

    .MuiChip-filled {
        height: 20px;
        width: 85px;

        &.slaChip {
            width: 55px;
        }
    }
}

.MuiChip-label {
    padding: 0 10px;
    font-size: $font-size-tiny;
}


.rdg {
    background: $theme-color-orange-bg;
    height: calc(100vh - 300px);
    border: none;
    overflow-y: auto !important;

    .rdg-cell {
        font-weight: $font-weight-300;
        padding: 4px 7px 4px 10px !important;
        color: $color-white;
        border: none;
        line-height: 28px;
        font-size: $font-size-small;

        &:hover {
            overflow: visible;
            z-index: 111;
            max-width: initial;
            background: $theme-color-orange-bg;
        }

        &[aria-selected='true'] {
            outline: none;
        }

        a {
            color: $color-white;
            text-decoration: none;
        }

        &[aria-sort='descending'],
        &[aria-sort='ascending'] {
            .rdg-sort-arrow {
                fill: none;
                height: 20px;
            }
        }

        &.center-text {
            text-align: center;
        }

        &.has-editor {
            input {
                border: 1px solid #3f3a4b;
            }
        }
    }
}

.rdg-text-editor {
    appearance: none;
    box-sizing: border-box;
    inline-size: 100%;
    block-size: 100%;
    border: $color-secondary;
    color: $color-white;
    background-color: $primary-color-bg;
    font-family: 'Montserrat';
    font-size: $font-size-small;
    height: 30px;

    &::placeholder {
        color: $color-grey;
        opacity: 1;
    }

    &:focus {
        border: 1px solid;
        border-color: $color-white;
        outline: none;
    }
}

.rdg-editor-container {
    .MuiInputBase-root {
        font-family: Montserrat !important;
        font-size: $font-size-small;
    }
}

.reasonForDelay:hover {
    position: absolute;
}


.rdg-row:nth-child(even) {
    background: none;
}

.rdg-header-row {
    background: $color-secondary;

    .rdg-cell {
        border-right: $color-primary;
    }
}

.rdg-row:nth-child(odd) {
    background: rgba(113, 117, 143, 0.05);

    .rdg-cell {
        border-right: 1.5px solid #1b1e35;
    }
}

.rdg-cell:last-child {
    border-right: none !important;
}

.highlightClass {
    .rdg-cell {
        background-color: $primary-color-bg;
        border-right: none !important;

        &:hover {
            background-color: $primary-color-bg;
        }
    }
}


.extractFailure_tabledata .rdg-cell.has-editor:hover {
    outline: 1px solid #3f3a4b;
    background: #3f3a4baa;
}

.extractFailure_tabledata .rdg-cell.has-editor.rdg-editor-container:hover {
    outline: none;
}

.extractFailure_tabledata .has-editor.rdg-editor-container {
    padding-left: 0 !important;
    padding-top: 2px !important;
}

.extractFailure_tabledata .has-editor.rdg-editor-container * {
    background: none !important;
}

.selectAll label span {
    color: #f5f5f5 !important;
}
.checkbox-sec label span {
    color: #f5f5f5 !important;
}


