@import '../../theme-variables.scss';

.head {
	position: fixed;
	z-index: 99;
	width: 100%;
}

.header {
	position: relative;
	display: flex;
	margin-left: 60px;
	/* width: 100%; */
	justify-content: space-between;
	animation-delay: 60px;
	color: #fff;
	padding: 0 16px;
	min-height: 60px;
	max-height: 200px;
	background: #001e3e;
}

.header1 {
	position: relative;
	display: flex;
	margin-left: 60px;
	/* width: 100%; */
	justify-content: space-between;
	animation-delay: 60px;
	background: $color-primary;
	color: #fff;
	padding: 0 16px;
	height: 152px !important;
}

.databricks_title1 {
	margin-left: -15px;
}

.header span {
	color: #e5e5e5;
	font-size: 20px;
	font-weight: 600;
	font-family: 'Montserrat';
	line-height: 35px;
}

.header-name.incident-header {
	.titleLink {
		margin: 0 5px;
		font-size: 18px !important;
	}

	a.titleLink {
		margin-left: 0;
	}

	.project-name {
		display: inline-block;
		width: 70%;
	}

	.symbol {
		opacity: .5;
		margin: 0 10px !important;
	}

	.page-title {
		font-size: 18px !important;
		margin-left: 2px !important;
		word-break: break-word !important;
	}
}

.job-menu.job-menu-add-schedules {
	.project-name,
	.program-name {
		display: inline-block;
		width: auto;
		max-width: 35%;
		text-overflow: ellipsis;
		overflow: hidden;
		vertical-align: middle;
		white-space: nowrap;
	}
}

.logo {
	object-fit: contain;
	margin-top: 10px;
}

/* .MuiAvatar-circular {
	background-color: $color-primary;
} */

.icons-header-collapse {
	height: 55px !important;
	width: 60px !important;
	background: $color-secondary !important;
	margin-left: 0px !important;
	border-top: 0.5px solid $color-primary;
	border-radius: 0;
}

.search-icon .MuiSvgIcon-root {
	width: 15px !important;
	height: 15px !important;
}

.icons-header .MuiSvgIcon-root {
	width: 15px;
	height: 15px;
}

.page-title {
	line-height: 30px;
	// vertical-align: top;
	margin-left: 3px !important;
	font-family: Montserrat !important;
	font-weight: 700;
}

.title-has-ellipsis {
	max-width: calc(100vw - 96%);
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	vertical-align: middle;
}

.head-title-has-ellipsis {
	max-width: 125px;
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	vertical-align: middle;
}

.title-ellipsis-responsive {
	max-width: 54%;
	@media screen and (max-width: 1600px) {
		max-width: 55%;
	}
	@media screen and (max-width: 1430px) {
		max-width: 340px;
	}
	@media screen and (max-width: 1200px) {
		max-width: 200px;
	}
}

.header-title {
	display: inline-block;
}

.badgeContainer .MuiBadge-root img {
	width: 20px;
	height: 20px;
}

.badgeContainer {
	/* width: 100%; */
	max-height: 55px;
	display: flex;
	justify-content: flex-end;
	padding-right: 7%;
	margin-top: 0.8%;
}

.badgeContainer .MuiBadge-badge {
	font-size: 10px;
	z-index: 1;
	top: -3px;
}

.MuiBox-root .MuiBadge-badge {
	height: 24px;
	width: 24px;
	border-radius: 50px;
	top: -3px;
}

.badgeContainer .MuiBox-root {
	background: #71758f0d;
	border-bottom: none !important;
}

.new_job_badge .MuiBadge-badge {
	background-color: #0844b9;
}

.warning_badge .MuiBadge-badge {
	background-color: #ffa200;
}

.alert_badge .MuiBadge-badge {
	background-color: #fe2626;
}

.zero_badge .MuiBadge-badge {
	background-color: #2c2f47;
	color: #71758f;
	font-family: 'Montserrat';
	font-weight: 600;
}

.badge_icon,
.badge_box {
	cursor: pointer;
	z-index: 2;
	// font-size: 25px;
}

.disabled_badge_icon,
.disabled_badge_box {
	cursor: default;
}

.badge_box:hover .badge_icon path {
	fill: #00d08d;
}

.badge_box:hover .badge_icon {
	z-index: 0;
}

.badge_icon:hover path {
	fill: #00d08d;
}

.badge_name {
	display: none;
	vertical-align: middle;
	padding-left: 15px;
	font-size: 10px;
	color: #00d08d;
}

.badge_name:hover {
	display: inline;
}

.badge_name:hover .badge_icon path {
	fill: #00d08d;
}

.badge_box .MuiBadge-root:hover+.badge_name {
	display: inline;
}

.badge_name:hover {
	display: inline;
}

@keyframes blink {
	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

.blink .MuiBadge-badge {
	animation: blink 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

.header-name {
	display: inline-block;
	// padding-top: 26px;
}

.header-name-powerBIObs {
	display: inline-block;
	// padding-top: 26px;
	margin-left: 5px;
}
.project-details-header {
	margin-bottom: 15px;
}
.job-menu .makeStyles-jobIcon-1 {
	width: 35px;
	// padding-top: 20px;
	padding-right: 0px;
	display: inline-block;
}

.job-menu-powerBIObs {
	padding-left: 8px;
}

.dashboard_title {
	font-family: 'Montserrat';
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: 'left';
	min-width: 330px;
}

.dashboard_title_icon {
	font-family: 'Montserrat';
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: 'left';
}

/* MUI Tooltip */
.MuiTooltip-tooltip {
	background: $color-primary;
	font-size: 12px;
	color: #f5f5f5;
}

.MuiTooltip-tooltip img {
	vertical-align: middle;
	padding: 10px;
}

.MuiTooltip-arrow {
	color: $color-primary;
}

.header .date-sync {
	color: #71758f;
	display: inline-block;
	font-size: 12px;
	font-weight: normal;
	margin-left: 10px;
}

.lastSync {
	top: 85%;
	left: 380px;
	position: absolute;
	font-size: 13px;
	color: #71758f;
	/* width: 300px; */
}

.lastSync1 {
	top: 80%;
	left: 30%;
	position: absolute;
	font-size: 13px;
	color: #fcc046 !important;
}

.lastSync {
	width: 300px;
	font-size: 13px;
	color: #71758f;
}

.dashboard_title_powerbi {
	font-family: 'Montserrat';
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: 'left';
	min-width: 330px;
	margin-left: 7px;
	margin-bottom: 8px;
}

.header-title_powerbi {
	display: inline-block;
	margin-top: -5px;
	margin-left: 7px;
}

/* .makeStyles-jobIcon-1 {
    width: 33px !important;
} */
.teradata_title {
	display: flex;
	align-items: center;
}

.teraObs_title {
	color: #fff;
	font-family: Montserrat;
	font-size: 24px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
	text-transform: uppercase;
	margin-left: 10px;
}

.teradata_logo,
.databricks_logo {
	width: 34px;
	height: 34px;
}

.dataEstate_logo {
	width: 30px;
	height: 32px;
	padding: 12px;
}

.ibp-exe-dsbd {
	width: 40px;
	height: 40px;
}

.dashboard_title_datagrowth {
	font-family: 'Montserrat';
	font-size: 15px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: 'left';
	min-width: 330px;
	margin-left: 7px;
	margin-bottom: 5px;
}

.databricks_title {
	display: flex;
	align-items: center;
}

.ibp--title {
	margin-left: 5px !important;
}

.page_title {
	margin-left: 10px;
}

.databricksObs_title {
	color: #fff;
	font-family: Montserrat;
	font-size: 24px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
	text-transform: uppercase;
	margin-left: 10px;
}

.DAIExecutive_title {
	color: #fff;
	font-family: Montserrat;
	font-size: 17px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
	// text-transform: uppercase;
	padding: 10px;
	cursor: pointer;
}

.biApplicationIcon {
	height: 25px !important;
	width: 25px !important;
	padding-right: '0px';
	display: 'inline-block';
	float: 'left';
}

.version-toggle-wrapper {
	margin-left: 940px;
  }
.monthly-xops {
	color: #8c8c8c !important;
	cursor: pointer;
}
.chevron-icon {
	margin-left: 10px;
}