@import '../../../theme-variables.scss';
.tableau_tabledata {
	height: calc(100vh - 110px);
	overflow-y: auto !important;
}

.governance-container-main {
    display: flex;
}

.workBook-iframe-div-governance{
    width: 29vw !important;
    padding: 0 !important;
    position: relative;
    transform: translateX(10px);
}
.extracts-iframe-div-governance{
    width: 30vw !important;
    padding: 0 !important;
    position: relative;
    transform: translateX(34px);
}
.views-iframe-div-governance {
    width: 30vw !important;
    padding: 0 !important;
    position: relative;
    transform: translateX(60px);
}

.governance-container-sub {
    display: flex;
}


.orphaned-iframe-div-governance {
    width: 33vw;
    padding: 0 !important;
    transform: translateX(10px);}

.unusedExtracts-iframe-div-governance {
    width: 65vw;
    padding: 0 !important;
    margin-top: -5px !important;
    transform: none;
    position: relative;
}
.unUsed-extracts {
    position: absolute;
    right: 3%;
    top: 20px;
    z-index: 9;
    
    .types {
        background-color: $secondary-color-bg;
        color: $color-white;
        font-family: Montserrat;
        font-size: 7px;
        font-weight: $font-weight-500;
        text-align: center;
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;

        &.active {
            background-color: $color-primary;
            color: $color-tertiary ;
            font-weight: $font-weight-800;
        }
    }
}


.work-book {
    position: absolute;
    right: -4%;
    top: 150px;
    z-index: 9;

    .types {
        background-color: $secondary-color-bg;
        color: $color-white;
        font-family: Montserrat;
        font-size: 7px;
        font-weight: $font-weight-500;
        text-align: center;
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;

        &.active {
            background-color: $color-primary;
            color: $color-tertiary ;
            font-weight: $font-weight-800;
        }
    }
}

.extract-book {
    position: absolute;
    right: -4%;
    top: 150px;
    z-index: 9;

    .types {
        background-color: $secondary-color-bg;
        color: $color-white;
        font-family: Montserrat;
        font-size: 7px;
        font-weight: $font-weight-500;
        text-align: center;
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;

        &.active {
            background-color: $color-primary;
            color: $color-tertiary ;
            font-weight: $font-weight-800;
        }
    }
}

.views-book {
    position: absolute;
    right: 5%;
    top: 150px;
    z-index: 9;

    .types {
        background-color: $secondary-color-bg;
        color: $color-white;
        font-family: Montserrat;
        font-size: 7px;
        font-weight: $font-weight-500;
        text-align: center;
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;

        &.active {
            background-color: $color-primary;
            color: $color-tertiary ;
            font-weight: $font-weight-800;
        }
    }
}

@media screen and (min-width: 1500px) {
    .workBook-iframe-div-governance{
        width: 30vw !important;
        padding: 0 !important;
        position: relative;
        transform: translateX(0px);
    }
    .extracts-iframe-div-governance{
        width: 30vw !important;
        padding: 0 !important;
        position: relative;
        transform: translateX(30px);
    }
    .views-iframe-div-governance {
        width: 30vw !important;
        padding: 0 !important;
        position: relative;
    transform: translateX(68px);
    }
    
}

@media screen and (min-width: 1500px) {
.unusedExtracts-iframe-div-governance {
    width: 65vw;
    padding: 0 !important;
    margin-top: -5px !important;
    transform: translateX(-45px);
}
}
