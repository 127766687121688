@import '../../theme-variables.scss';

.root,
#root {
    height: 100%;
    overflow: hidden;
    max-height: 100%;
}

.data-pipeline-wrapper {
    .sector-filter-dataestate {
        background: transparent !important;

        .sector-cta {
            background: transparent !important;
        }
    }
}

.d-flex-main {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100%;

    .image {
        width: 98px;
    }
}

.h-40 {
    height: 40vh !important;
}

.d-flex {
    display: flex !important;
}

.titleLink {
    margin: 0;

    .view-icon {
        color: #009BD8;
        display: inline-block;
        margin-top: 3px;
    }
}

.action-cta-link {
    display: inline-block;
    padding: 0;
    min-width: 40px;

    .icon-actions {
        height: 30px;
        width: 30px;
    }

    &.action-cta-link-delete {
        min-width: 24px;
        margin-left: -5px;
    }
}

.icon-actions {
    cursor: pointer;

    &.icon-actions-delete {
        margin-top: 6px;
    }

    path {
        fill: #e5e5e5;
    }

    &:hover {
        path {
            fill: #aaa;
        }
    }
}

.custom-time-field {
    background-color: transparent;
    border: none;
    color: #e5e5e5;
    cursor: pointer;
    width: 95px;

    // &::-webkit-datetime-edit-ampm-field {
    //     display: none;
    //   }
    //   &::-webkit-clear-button {
    //     -webkit-appearance: none;
    //     -moz-appearance: none;
    //     -o-appearance: none;
    //     -ms-appearance:none;
    //     appearance: none;
    //     margin: -10px; 
    //   }
    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        filter: brightness(0) saturate(100%) invert(61%) sepia(85%) saturate(4437%) hue-rotate(166deg) brightness(92%) contrast(101%);
    }
}

.cta-link {
    color: #fff;
    text-decoration: none;

    img {
        margin-right: 7px;
        vertical-align: text-bottom;
    }
}



.service-sector-filter {
    .project-date-filter-select {
        svg {
            position: absolute;
            right: 10px;
            top: 10px;
            height: 22px;
            width: 22px;
        }
    }

}


.service-date-filter.date-picker-summary {
    right: 280px;

    .date-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        height: 22px;
        width: 22px;
    }

    #single-date-select {
        font-size: 14px;
        background-color: #002751;
        padding: 11px 14px !important;
    }
}

.cta-btn {
    background-color: #009BD8 !important;
    font-weight: 700;
}

.mui-disabled {
    color: #f5f5f5 !important;
}

.h-2-txt {
    font-size: 16px;
    font-weight: 700;
    color: white;
}

// .proj-table {
// .incidents_tabledata .rdg {
//     height: 30vh !important;
//     overflow-y: scroll !important;
// }

// .table-footer div:third-child {
//     margin-top: 5px !important;
// }

// .table-footer div:first-of-type {
//     display: flex;
//     width: 100%;
//     justify-content: start;
//     align-items: center;
//     margin-top: 0px !important;
// }

// .table-footer div:first-of-type(3n) {
//     display: flex;
//     width: 100%;
//     justify-content: start;
//     align-items: center;
//     margin-top: 0px !important;
// }

// .darkPagination div:first-of-type {
//     display: flex;
//     width: 100%;
//     justify-content: end !important;
//     align-items: center;
//     margin-top: 0px !important;
//     height: 65px !important;
// }

// }

.MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded {
    height: 205px !important;
}

.d-inline-block {
    display: inline-block !important;
    vertical-align: top;
}

.w-20 {
    width: 20% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 52% !important;
}

.w-60 {
    width: 60% !important;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.w-100 {
    width: 100% !important;
}

.w-201 {
    width: 201px !important;
}

.w-230 {
    width: 230px !important;
}

.m-5 {
    margin: 0px 7px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-3 {
    margin-top: 3px !important;
}

.m-7 {
    margin: 7px 7px;
}

.h-50p {
    height: 50% !important;
}

.mr-5 {
    margin-right: 7px;
}

.my-10 {
    margin: 10px 0px;
}

img {
    .icon {
        height: 32px !important;
        width: 32px !important;
        z-index: 99 !important;
    }

    .image {
        height: 100px !important;
        width: 100px !important;
    }
}

.acc-summ {
    background-color: #003771;
    color: white;
    font-weight: 600;
    font-size: 16px;
    width: 100% !important;
    border-radius: 4px !important;

    .MuiAccordionSummary-content {
        margin: 6px 0 7px;
        position: relative;
    }
}

.MuiPaper-root,
.MuiTableCell-root {
    padding: 0px !important;
    margin: 0px 10px 0px 5px;

}


.MuiAccordionDetails-root {
    border: 2px solid #003771 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    background-color: #001E3E !important;
    background: #001E3E !important;
}

.panel-typo {
    border-right: 2px solid white;

    .MuiTypography-root {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
    }
}

.actions-item-wrapper {
    margin-right: 10px;
    position: absolute;
    right: 0;
}

.actions-item {
    .actions-btn {
        background: none;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        margin-top: 10px;
        min-width: 24px;
        margin-right: 0;
        background-color: transparent !important;

        &.actions-item-list {
            margin-right: 10px;
        }

        &.actions-item-list-dots {
            margin-top: 0;
        }
    }
}

.accordion-list-wrapper.Mui-expanded {
    margin: 0px 10px 0px 5px !important;

    .Mui-expanded {
        min-height: 48px !important;
    }
}

.menu-list-wrapper {
    .MuiPaper-root {
        overflow: hidden;
        background-color: #003771 !important;
        border: 1px solid #83B9F9 !important;
        border-radius: 4px;
        box-shadow: none;

        .MuiList-root {
            padding: 16px;
            height: auto !important;

            .MuiMenuItem-root {
                color: #A9ADCB;
                font-size: 16px;
                font-weight: 400;
                height: 35px !important;

                &:hover {
                    background: #002349;
                    border-radius: 4px;
                    color: #fff;
                }

                .text {
                    margin-left: 5px;
                }
            }
        }
    }
}

#customGrid {
    .MuiButtonBase-root {
        background-color: transparent !important;
    }
}



.actions-item-details {
    position: absolute;
    right: 10px;
    top: 24px;

    .actions-btn {
        margin-top: 6px;

    }

    .icon-info {
        margin-right: 5px;

        .MuiButtonBase-root {
            margin-top: 4px;
            padding: 0;
        }
    }

}

.MuiTooltip-tooltip {
    background: #002349;
}

.MuiTooltip-arrow {
    color: #002349;
}

.info-tooltip-wrapper {
    background: #002349;
    padding: 5px;

    .info-date {
        display: inline-block;
        font-family: Montserrat;
        width: 120px;

        .title {
            font-size: 13px;
            font-style: italic;
            color: #A9ADCB;
            font-weight: 500;
            line-height: 25px;
        }

        .value {
            font-size: 12px;
            font-weight: 500;
            color: #F5F5F5;
            line-height: 18px;

            .img {
                height: 16px;
                vertical-align: text-bottom;
            }
        }
    }
}

.info-tooltip {
    font-size: 16px;
    width: 200px;
}

.count-box {
    background-color: #A9ADCB33;
    border-radius: 6px;
    padding: 4px 0;
    font-size: 22px;
    font-weight: 600;
    width: 50px;
    margin: 0px 20px;
    text-align: center;
}

.project-name-header {
    padding-left: 16px;
    width: 56%;
}

.status-header {
    width: 40%;

    .status-box {
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
    }

    .box-total-jobs {
        width: 125px;
    }

    .box-missed,
    .box-completed,
    .box-failed,
    .box-met,
    .box-run {
        width: 105px;
    }
}

.status-group {
    display: inline-block;
}

.status-count-wrap {
    margin-left: 20px;

    .status-box {
        display: inline-block;
        width: 125px;
    }

    .count-box,
    .count-box-project {
        margin-left: 0;
        width: 70px;
    }

    .box-completed,
    .box-failed,
    .box-met,
    .box-missed,
    .box-run {
        width: 105px;
    }

}

.w-19 {
    width: 19% !important;
}

.w-25 {
    width: 25% !important;
}

.sla-comp {
    background-color: rgba(41, 155, 255, 0.2) !important;
    color: rgba(41, 155, 255, 1) !important;
}


.sla-na {
    background-color: rgba(169, 173, 203, 0.2) !important;
    color: rgba(169, 173, 203, 1) !important;
}

.sla-miss {
    background-color: rgba(255, 162, 0, 0.2) !important;
    color: rgba(255, 162, 0, 1) !important;
}

.sla-fail {
    background-color: rgba(254, 38, 38, 0.2) !important;
    color: rgba(254, 38, 38, 1) !important;
}

.sla-met {
    background-color: rgba(1, 192, 8, 0.2) !important;
    color: rgba(1, 192, 8, 1) !important;
}

.count-box-project {
    border-radius: 6px;
    padding: 4px 0;
    font-size: 22px;
    font-weight: 600;
    margin-left: 20px;
    width: 60px;
    text-align: center;
}


.schedules-list-wrapper {
    margin-top: 20px;
    padding: 0 10px;

    &.schedules-list-wrapper-program {
        padding: 0;
    }
}

.modal-wrapper {
    .modal-header {
        background-color: #003771;
        padding: 10px;
        position: relative;

        .close {
            cursor: pointer;
            position: absolute;
            right: 8px;
            top: 3px;
            padding: 5px;
        }
    }

    .modal-content {
        .loading-wrapper {
            text-align: center;
            padding: 50px;
        }

        .success-content {
            padding: 50px;
        }

        .actions {
            text-align: right;
        }

        .close {
            border: none;
            cursor: pointer;
            background-color: transparent;
            font-size: 14px;
            padding: 5px;

            a {
                color: rgba(0, 155, 216, 1);
                text-decoration: none;
            }
        }

        .add-more {
            border: none;
            background-color: rgba(0, 155, 216, 1);
            color: #fff;
            padding: 5px;
            margin-left: 10px;
        }
    }
}

.success-modal {
    .MuiDialog-container {
        .MuiPaper-root {
            width: 50%;
        }
    }
}

.platform-icon {
    margin-right: 5px;
}

.dd-title {
    vertical-align: super;
}

.custom-dropdown-menu--platform {
    .MuiSelect-select {
        padding-bottom: 2px;
        border: none;
        color: #fff !important;
        -webkit-text-fill-color: aliceblue;
    }
}

.custom-drodown-box {
    .MuiInputBase-root {
        .MuiSelect-select {
            padding: 8px 14px 9px;
        }
    }
}

.MuiMenuItem-root {
    height: 40px !important;

    .hide-temp {
        opacity: 1;
        color: #aaa !important;
    }
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}

.w-205 {
    width: 210px !important;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.justify-btw-items-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
}

.no-program-txt {
    color: #71758F;
    font-size: 24px;
    font-weight: 600;
}

.flex-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.h-80 {
    height: 80vh !important;
}

.m-10 {
    margin: 0px 30px;
}

.program-data-card {
    background-color: #003771;
    border-radius: 4px;
    margin-right: 10px;
    min-height: 54px;
    padding: 15px 20px 11px;
    position: relative;
}

.project-data-card {
    background-color: #003771;
    border-radius: 4px;
    margin-right: 10px;
    padding: 15px 20px 21px;
    position: relative;

    @media screen and (max-width: 1282px) {
        padding-bottom: 17px;
    }
}

.clarity-proj {
    cursor: default;
}

.has-ellipsis {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.h-180 {
    height: 180px !important;
}

.warn-div {
    width: 100%;
    display: flex;
    justify-items: start;
}


.warn-txt {
    color: #E5E5E5;
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    margin-left: 30px;

}

.tile-txt {
    font-size: 16px;
    font-weight: 700;
    color: white;
    margin: 0;
}

.tile-sub-header-txt {
    color: #A9ADCB;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    width: 100%;

    .label-txt {
        color: rgba(169, 173, 203, 1);
        font-weight: 500;
        // display: inline-block;
        vertical-align: center;
    }

    .tile-detail-txt {
        color: rgba(245, 245, 245, 1);
        font-weight: 600;
        // display: inline-block;
        word-wrap: break-word;
        width: 100%;
        margin-top: 5px;

        @media screen and (max-width: 1282px) {
            font-size: 14px;
        }

        &.small {
            width: 45%;
        }

        .img {
            color: grey;
            height: 20px;
            opacity: .7;
            vertical-align: text-bottom;
        }
    }
}

.normal-compl-time-wrap {
    position: relative;

    .MuiInputBase-input,
    .MuiInputBase-root {
        padding: 0;
    }

    .MuiButtonBase-root {
        color: white;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }

    .custom-time-input {
        position: absolute;
        left: 0;
        top: 0px;
        width: 77%;
        background: #001e3e;
        border: none;
        color: #fff;
        font-size: 12px;
        font-family: Montserrat;
        height: 23px;
    }

    .pencil-icon {
        opacity: 0;
        vertical-align: middle;
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: -1
    }

    .custom-time-picker {
        .custom-icon-calc {
            cursor: pointer;
            position: absolute;
            vertical-align: middle;
            position: absolute;
            z-index: -1;
            left: 60px;
            top: 6px;

            path {
                fill: rgba(0, 155, 216, 1);
            }
        }
    }

    &:hover {
        .pencil-icon {
            opacity: .6;
        }
    }
}

.tile-detail-txt {
    color: #F5F5F5;
    font-size: 16px;
    font-weight: 500;
}

.flex-col {
    flex-direction: column !important;
}

.flex-row {
    flex-direction: row !important;
}

.count-box-txt {
    color: #E5E5E5;
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 1282px) {
        font-size: 28px;
    }
}

.loading-txt {
    color: #E5E5E5 !important;
}

.table-icon {
    height: 24px !important;
    width: 24px !important;
}

.count-box-txt-poj {
    color: #E5E5E5;
    font-weight: 700;
    font-size: 32px;
    margin: 0;
    line-height: 34px;
}

.m-0 {
    margin: 0px !important;
}

.m-75 {
    margin: 0.75em 0em !important;
}

.tot-jobs-card {
    background-color: #003771;
    padding: 5px 10px;
    border-radius: 4px;
}

.tot-jobs-status-card {
    padding: 12px 10px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 1.49%;
    width: 12% !important;
    vertical-align: top;

    &:last-child {
        margin-right: 0 !important;
    }

    .tile-txt {
        font-weight: 500;
        font-size: 14px;
    }

    @media screen and (min-width: 1920px) {
        margin-right: 1.49%;
        width: 12.4% !important;

        .tile-txt {
            font-weight: 700;
            font-size: 16px;
        }
    }

    @media screen and (max-width: 1750px) {
        margin-right: 1.2%;
        width: 12% !important;

        .tile-txt {
            font-weight: 500;
            font-size: 13px;
        }
    }

    @media screen and (max-width: 1430px) {
        margin-right: 1.2%;
        width: 11.6% !important;

        .tile-txt {
            font-weight: 500;
            font-size: 13px;
        }
    }

    @media screen and (max-width: 1300px) {
        margin-right: 1.2%;
        width: 12.2% !important;
        padding: 12px 8px;
        border-width: 5px !important;

        .tile-txt {
            font-weight: 500;
            font-size: 12px;
        }
    }

    @media screen and (max-width: 1263px) {
        margin-right: 1.2%;
        width: 11.5% !important;
        padding: 12px 8px;
        border-width: 5px !important;

        .tile-txt {
            font-weight: 500;
            font-size: 12px;
        }
    }

    @media screen and (max-width: 1154px) {
        .tile-txt {
            font-size: 9px;
        }
    }

    @media screen and (max-width: 1100px) {
        width: 11% !important;

        .tile-txt {
            font-size: 8px;
        }
    }

    @media screen and (max-width: 1000px) {
        width: 10% !important;

        .tile-txt {
            font-size: 6px;
        }
    }
}

.data-pipeline-wrapper {
    margin-top: 145px !important;

    .sector-cta {
        .cta-btn {
            border-radius: 4px;
            padding: 7px 10px;
        }
    }

    .service-date-filter {
        min-width: 260px;
    }
}

.completed-card {
    border-right: 8px solid rgba(41, 155, 255, 1);
    background-color: rgba(41, 155, 255, 0.2);
}

.sla-met-card {
    background-color: rgba(1, 192, 8, 0.2);
    border-right: 8px solid rgba(1, 192, 8, 1);
}

.sla-missed-card {
    background-color: rgba(255, 162, 0, 0.2);
    border-right: 8px solid rgba(255, 162, 0, 1);
}

.failed-card {
    background-color: rgba(254, 38, 38, 0.2);
    border-right: 8px solid rgba(254, 38, 38, 1);
}

.na-card {
    background-color: rgba(169, 173, 203, 0.2);
    border-right: 8px solid rgba(169, 173, 203, 1);
}

.h-50 {
    height: 50vh !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    top: 0px !important;
    left: 0px !important
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1em !important;
}

.p-fix {
    position: fixed !important;
    z-index: 99 !important;
}

.editJobForm input {
    height: 1.4em !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.program-list-container::-webkit-scrollbar {
    display: none;
}

.project-list-container::-webkit-scrollbar {
    display: none;
}


.program-list-container {
    height: 73vh !important;
    overflow-y: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.project-list-container {
    height: calc(100vh - 310px);
    overflow-y: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */


}

.css-qiwgdb.MuiSelect-select {
    height: 1em !important;
}

.MuiPaper-elevation24 {
    h2 {
        background-color: #003771 !important;
    }
}

.editJobForm {
    background-color: #001E3E !important;
    position: relative;

    .error-txt {
        color: #FD783B;
        font-size: 12px;
        font-weight: 600;
        font-style: italic;
    }
}

.editJobForm-program {
    position: absolute;
    right: 0;
    bottom: 0;
}

.edit-job-modal .editJobForm .MuiGrid-container .MuiFormControl-root .MuiInputLabel-root {
    color: #71758f !important;
    margin-left: 15px;
    margin-top: 0 !important;

    &.MuiInputLabel-shrink {
        margin-top: -8px !important;
    }

    &.sector-list-label {
        margin-top: -25px !important;
        margin-left: -8px;
    }
}

.editJobForm-project {
    .MuiDialogContent-root {
        overflow: hidden;
    }

    .MuiFormLabel-asterisk {
        display: none;
    }
}

.sector-list {
    display: block;
    margin-top: 10px;
    min-height: 120px;

    .sector {
        cursor: pointer;
        width: 170px;
        margin-right: 10px;

        color: $color-grey-md;
        font-family: Montserrat;
        font-size: $font-size-15;
        font-style: normal;
        font-weight: $font-weight-600;
        margin-top: 20px;

        input {
            vertical-align: text-bottom;
        }

        label {
            cursor: pointer;
        }
    }
}

.rdrCalendarWrapper.custom-date-range-wrapper {
    background-color: #003771;
    border-radius: 4px;
    padding: 20px;

    .rdrDateDisplayWrapper {
        display: none;
    }

    .rdrMonthAndYearWrapper {
        position: relative;
        display: block;
        margin-bottom: 10px;
    }

    .rdrMonthAndYearPickers {
        display: block;
        height: 30px;

        select {
            background: none;
            border: none;
            color: #fff;
            font-size: 15px;
            font-weight: 700;
            padding: 3px 0;

            option {
                background: #001e3e;
                color: #fff;
                font-size: 15px;
                font-weight: 500;
                padding: 4px 0;

                &:focus,
                &:active {
                    outline: none;
                    border: none;
                }
            }
        }
    }

    .rdrNextPrevButton {
        border: 1px solid #71758F;
        background: none;
        border-radius: 4px;
        position: absolute;
        right: 0;
        top: 0;
        padding: 4px 7px;

        &.rdrPprevButton {
            right: 35px;

            i {
                border: solid #e5e5e5;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(-135deg);
                vertical-align: middle;
            }
        }

        &.rdrNextButton {
            i {
                border: solid #e5e5e5;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
                vertical-align: text-top;
                margin-top: 2px;
            }
        }

        &:hover {
            background-color: #002349;
        }
    }

    .rdrMonths {
        .rdrMonth {
            width: 16em;
        }

        .rdrWeekDays {
            margin-bottom: 10px;

            .rdrWeekDay {
                font-family: Montserrat;
                color: #71758F;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .rdrSelected,
        .rdrInRange,
        .rdrStartEdge,
        .rdrEndEdge {
            background: #009BD8;
            position: absolute;
            top: 5px;
            left: 0;
            right: 0;
            bottom: 5px;
        }

        .rdrInRange {
            background-color: rgba(113, 117, 143, 0.4);
        }

        .rdrStartEdge {
            border-top-left-radius: 1.042em;
            border-bottom-left-radius: 1.042em;
            left: 2px;
        }

        .rdrEndEdge {
            border-top-right-radius: 1.042em;
            border-bottom-right-radius: 1.042em;
            right: 2px;
        }

        .rdrDayNumber {
            span {
                color: #fff;
            }
        }

        .rdrDayToday {
            &:after {
                content: '';
                position: absolute;
                bottom: 4px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 18px;
                height: 2px;
                border-radius: 2px;
                background: #3d91ff;
            }
        }

        .rdrDays {
            .rdrDay {
                background-color: #003771;
                border: none;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                padding: 13px 0;
            }

            .rdrDayPassive {
                .rdrDayNumber {
                    span {
                        color: rgba(113, 117, 143, 1);
                    }
                }
            }
        }
    }
}

.delete-txt {
    font-size: 28px;
    font-weight: 600;
}

.projects-cards-div {
    width: 50%;
    height: 200px;
    margin-right: 13px;

}



// @media only screen and (max-width: 1200px) {
//     .program-data-card {
//         width: 100% !important;
//         height: 170px !important;
//     }
// }

.MuiAutocomplete-popper {
    * {
        color: #fff !important;
        font-family: Montserrat;
    }
}

.edit-job-modal .editJobForm .MuiGrid-container {
    .Mui-checked .MuiSvgIcon-root {
        color: #009BD8 !important;
    }
}

.transparent-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid rgba(170, 170, 170, 1);
    border-radius: 3px;
    width: 20px;
    height: 20px;
    background-color: transparent;

    &:checked {
        background-color: #00B9FF;
        border-color: transparent;
    }
}

.checkbox:checked {
    background-color: #00d08d;
    /* Change background color when checked */
    border-color: rgba(113, 117, 143, 1);
    /* Change border color when checked */
}

.transparent-checkbox:checked::before {
    content: '✓';
    /* Unicode character for a checkmark */
    display: inline-block;
    font-size: 14px;
    /* Adjust size as needed */
    line-height: 18px;
    /* Adjust line-height to vertically center */
    color: white;
    /* Color of the checkmark */
    visibility: visible;
    /* Ensure the checkmark is visible */
    padding-left: 4px;
}

.tile-detail-txt-description {
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 18px;
}

.has-successor {
    color: #71758f;
}

.chart-widget-query {
    padding: 6px 0px;
}

.custom-text-field {
    .MuiInputLabel-root {
        display: flex;
        align-items: center;
    }

    .MuiInputLabel-shrink {
        transform: translate(0, 1.5px) scale(0.75);
    }

    .MuiFormHelperText-root {
        margin-left: 1px;
    }
}

.rdg-header-row {
    .rdg-cell {
        border-right: 1.5px solid #1b1e35;
    }
}

.programlist_tabledata .rdg-header-row {
    background: #003771 !important;
}

.programlist_tabledata .rdg {
    background: transparent !important;
    height: calc(100vh - 250px);
    border: none;
    overflow-y: auto !important;
    max-height: 300px !important;
}

.programlist_tabledata .pagination-div {
    width: 100%;
    bottom: 0px;
    background: #002349;
}

.programlist_tabledata .darkPagination {
    margin-bottom: 0;
    bottom: inherit;
    right: 0;
    height: 86px;
    background: #002349;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0px 0px;
    box-sizing: border-box;
    float: right;
}

.programlist_tabledata .highlightClass .rdg-cell {
    background-color: #062242;
}

.custom-time-picker {
    position: relative;

    &.active,
    &:hover {
        outline: 1px solid #009bd8;
    }

    .custom-time-input-ele {
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-family: Montserrat;
        letter-spacing: 1px;
        padding: 5px 0;
        width: 90px;

        &:focus {
            outline: none;
        }
    }

    &.active {
        .custom-time-input-ele {
            +.custom-timepicker-menu {
                display: block;
            }
        }
    }

    .pencil-icon {
        position: absolute;
    }

    .custom-timepicker-menu {
        display: none;
        cursor: pointer;
        position: fixed;
        max-height: 200px;
        z-index: 999;
        overflow-y: auto;
        background: rgb(0, 55, 113);
        padding: 5px 0 5px 5px;

        .list {
            padding: 3px 15px;
            margin-bottom: 4px;
            line-height: 20px;
            letter-spacing: 1px;

            &:hover {
                background-color: #001e3e;
            }
        }

        .list-disabled {
            padding: 3px 15px;
            margin-bottom: 4px;
            line-height: 20px;
            letter-spacing: 1px;
            color: rgba(113, 117, 143, 1);
            cursor: default;
        }
    }

    .custom-time-dropdown {
        padding: 0 10px;
        // position: absolute;
        width: 100%;

        .MuiSelect-select {
            padding: 0 !important;
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }
}

.schedule_tabledata .highlightClass .rdg-cell {
    background-color: #062242;
}


.jobsNameList:hover {
    // background: none !important;
    padding: 10px 10px 10px 5px !important;
    border: none !important;
}

// .page-title {
//     width: 18rem !important;
// }

.table-action-items {
    margin-bottom: 25px;
    position: relative;

    .action-item-title {
        font-weight: 600;
    }

    .action-item-delete {
        border: 1px solid #fe2626;
        border-radius: 4px;
        color: #fe2626;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
    }

    .action-item-reorder {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        right: 0;
        top: 0;
    }

    .action-item-reorder-cancel {
        cursor: pointer;
        margin-right: 15px;
    }

    .action-item-reorder-confirm {
        border: 1px solid rgba(0, 155, 216, 1);
        border-radius: 4px;
        font-weight: 700;
        color: rgba(0, 155, 216, 1);
        cursor: pointer;
        padding: 5px;
    }
}

.custom-ag-pagination {
    background-color: #00377133;
    color: #e5e5e5;
    padding: 15px 10px;
    margin-top: 5px;
}

.calendar-icon-style {
    display: inline-block;
    position: fixed;
    top: 94px;
    right: 270px;
    z-index: 999;

    .date-label {
        display: inline-block;
        vertical-align: bottom;
        line-height: 28px;
        margin-right: 7px;
    }
}

.ag-custom-theme-quartz {
    .ag-drag-handle {
        margin-top: 0;

        .ag-icon {

            &:before,
            &:after {
                display: none;
            }

            background-image: url('../../assets/reorder-four.svg');
            height: 24px;
            width: 24px;
        }
    }

    .ag-checkbox-input-wrapper {
        &.ag-indeterminate:after {
            border: none;
            content: '';
            background: #fff;
            width: 10px;
            height: 3px;
            top: 8px;
            left: 5px;
        }

        &.ag-indeterminate {
            background-color: #009BD8;
            border-radius: 4px;
            height: 20px;
            width: 20px;
        }

        .ag-input-field-input,
        &:before {
            height: 20px;
            width: 20px;
        }

        &:after {
            content: "";
            border: 1px solid #fff;
            width: 20px;
            height: 20px;
            border-radius: 4px;
        }

        &.ag-checked:before {
            background-color: transparent;
            border-radius: 5px;
        }

        &.ag-checked:after {
            content: "" !important;
            border: none;
            background-image: url(../../assets/tick-svgrepo.svg);
            background-size: contain;
            background-color: transparent;
        }
    }

    .ag-checkbox-input:focus,
    .ag-checkbox-input:active {
        outline: none;
    }

    .ag-column-first {
        margin-left: 10px;
    }

    .ag-paging-panel {

        .ag-paging-page-summary-panel {
            color: #e5e5e5;

            .ag-disabled .ag-icon {
                color: rgba(229, 229, 229, 1);
            }
        }

        .ag-picker-field {
            .ag-picker-field-wrapper {
                background: transparent;
                min-width: auto;

                .ag-picker-field-icon .ag-icon {
                    color: #e5e5e5;
                }
            }
        }
    }
}

.custom-ag-pagination {
    background-color: #00377133;
    color: #e5e5e5;
    padding: 15px 10px;
    margin-top: 5px;
}

.calendar-icon-style {
    display: inline-block;
    position: fixed;
    top: 94px;
    right: 270px;
    z-index: 999;

    .date-label {
        display: inline-block;
        vertical-align: bottom;
        line-height: 28px;
        margin-right: 7px;
    }
}

.ag-unselectable {
    padding: 0 10px;

    .ag-icon-arrows {
        background-image: url('../../assets/env-panel-dark.svg');
    }

    .ag-icon-not-allowed {
        background-image: url('../../assets/close-panel-dark.svg');
    }

    .ag-icon {
        background-position: 0;
        background-size: 120%;
        height: 24px;
        width: 24px;

        &:before {
            content: "" !important;
        }
    }
}

.dp-header {

    padding: 0 16px 16px !important;

}

.programOpsScreens {
    padding: 16px;
    background-color: #003771;
    height: 100%;
}

.css-i4bv87-MuiSvgIcon-root {
    color: white;
}

.MuiDayPicker-header {
    .MuiTypography-root {
        color: #fefefe;
    }
}

.MuiCalendarPicker-root {
    .MuiButtonBase-root {
        background-color: transparent;
        color: #fff;
    }

    .Mui-selected {
        background-color: #003771;
    }
}

.MuiTypography-overline {
    color: #fff;
}

.dateFilterDataPipeline {
    background-color: rgba(0, 55, 113, 0.4);

    .MuiInputBase-input {
        padding: 10px 14px;
    }
}

.wrapper-search-prgm {
    width: 240px;
}

.invalid-cell-dp-bulk {
    background-color: rgba(255, 162, 0, 0.15) !important;
}

.css-v2dzbl {
    padding: 0px !important;
}

.DataInfo {
    .MuiBox-root {
        border-bottom: 7px solid transparent !important;
    }

    .solidBlue {
        border-bottom: 7px solid rgba(41, 155, 255, 1) !important;
        background: transparent;

        p {
            color: rgba(41, 155, 255, 1);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }

    .pgmGreen {
        border-bottom: 7px solid rgba(1, 192, 8, 1) !important;
        background: transparent;

        p {
            color: rgba(1, 192, 8, 1);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }

    .pgmPink {
        border-bottom: 7px solid rgba(216, 43, 81, 1) !important;
        background: transparent;

        p {
            color: rgba(216, 43, 81, 1);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }

    .pgmYellow {
        border-bottom: 7px solid rgba(236, 185, 20, 1) !important;
        background: transparent;

        p {
            color: rgba(236, 185, 20, 1);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }

    .paleGray {
        border-bottom: 7px solid rgba(169, 173, 203, 1) !important;
        background: transparent;

        p {
            color: rgba(169, 173, 203, 1);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }

    .paleRed {
        border-bottom: 7px solid rgba(255, 137, 137, 1) !important;
        background: transparent;

        p {
            color: rgba(255, 137, 137, 1);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }

    .paleYellow {
        border-bottom: 7px solid rgba(252, 192, 70, 1) !important;
        background: transparent;

        p {
            color: rgba(252, 192, 70, 1);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }

    .red {
        border-bottom: 7px solid rgb(254, 52, 52) !important;
        background: transparent;

        p {
            color: rgb(254, 52, 52);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }

    .blue {
        border-bottom: 7px solid rgb(54, 168, 255) !important;
        background: transparent;

        p {
            color: rgb(54, 168, 255);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }

    .purple {
        border-bottom: 7px solid rgba(160, 129, 255, 1) !important;
        background: transparent;

        p {
            color: rgba(160, 129, 255, 1);
        }

        .colorIntimation {
            background: transparent !important;
        }
    }
}

.highlight-cell:hover {
    border: 0.5px solid rgb(54, 168, 255) !important;
    overflow: visible !important;
}

.timeline-class {
    background-color: rgba(0, 55, 113, 1);
    border-radius: 4px;
    padding: 15px;
    height: 10rem;
}

.legend {
    justify-content: end;

    .legend-item {
        color: rgba(169, 173, 203, 1);
        margin-left: 10px;
        font-size: 14px;
        font-weight: 600;

        .color-box {
            border-radius: 4px;
            display: inline-block;
            height: 15px;
            margin-right: 6px;
            vertical-align: text-top;
            width: 15px;
        }
    }
}

.soon-bg-color {
    background-color: rgba(113, 117, 143, 1);
    .MuiChip-label {
        font-size: 12px;
    }
}

.success-bg-color {
    background-color: rgba(1, 192, 8, 1);
    .MuiChip-label {
        font-size: 12px;
    }
}

.fail-bg-color {
    background-color: rgba(254, 38, 38, 1);
    .MuiChip-label {
        font-size: 12px;
    }
}

.risk-bg-color {
    background-color: rgba(236, 185, 20, 1);
    .MuiChip-label {
        font-size: 12px;
    }
}

.no-bg {
    background: none;
}

.soon-color {
    color: rgba(113, 117, 143, 1);
}

.success-color {
    color: rgba(1, 192, 8, 1);
}

.fail-color {
    color: rgba(254, 38, 38, 1);
}

.risk-color {
    color: rgba(236, 185, 20, 1);
}

.status-cards {
    .card-box {
        background-color: #003771;
        position: relative;
        padding: 10px 8px 10px 30px;
        width: 84%;

        .clickable {
            cursor: pointer;
        }

        .stats {
            display: inline-block;
            width: 100%;
        }

        .MuiTypography-root {
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 4px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            left: 0;
            border-top: 75px solid transparent;
            border-left: 20px solid rgb(0, 35, 70);
            border-bottom: 97px solid transparent;
            margin: -10px 0 0 0;
        }

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 10px;
            width: 0;
            height: 0;
            border-top: 75px solid rgb(0, 35, 70);
            border-left: 22px solid rgb(0, 55, 113);
            border-bottom: 96px solid rgb(0, 35, 70);
            margin: -10px 0px 0 0px;
        }

        &.secondary {
            border-radius: 5px;
            cursor: default;
            margin-right: 19px;
            padding-left: 0;
            width: 92%;

            &:before,
            &:after {
                display: none;
            }
        }
    }
}

.timeline-class {
    .MuiChip-label {
        font-size: 16px !important;
        font-weight: 700 !important;
    }

    .heatlth-chip {
        border: 2px solid rgba(169, 173, 203, 1) !important;
    }
}

.on-track-indicator {
    rect {
        fill: rgba(1, 192, 8, 1);
        stroke: rgba(1, 192, 8, 1);
    }
}


.alert-indicator {
    rect {
        fill: rgba(254, 38, 38, 1);
        stroke: rgba(254, 38, 38, 1);
    }
}

