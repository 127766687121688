@import '../../theme-variables.scss';


.selectAll-sec1 {
    height: 200px;
    overflow: auto;
    padding: 0px;
    margin: 0px;
    width: 250px;
}

.filters-text {
    position: absolute;
    top: 10px;
    left: 20px;
}

.buttonsSec1 {
    display: block;
    float: right;
    width: 70%;
    clear: both;
}



.label-container {
    position: relative;
    left: 20px;
}

.filterContainers .MuiPaper-root {
    height: 300px;
    overflow: hidden;
    width: 30%;
    right: 200px;
    top: 100px;
}

.filterContainers .sector-list {
    float: left;
    width: 40%;
    margin-right: 0;
    margin-top: 5px;
    height: 240px;
    overflow-y: auto;
}

// .checkbox-sec-datagrowth {
// }

.list-items {
    padding-left: 0px;
    margin-top: 0px;
}

.dataGrowthselectAll-sec1 {
    position: relative;
    float: left;
    width: 45%;
}

.MuiButtonBase-root-MuiCheckbox-root {
    color: #AAAAAA;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: $color-grey;
}

.dataGrowthselectAll-sec2 {
    padding-left: 20px;
}

.site-filter.filterContainers .MuiPaper-root li,
.list-items ul li {
    width: 100%;
    right: 0px;
    padding: 10px 15px;
    border-bottom: 1px solid #71758f45;
    height: max-content !important;
}

.dataGrowthcheckbox-sec {
    position: absolute;
    top: 0%;
    bottom: 100%;
    margin: 29px;
    height: 80px;
    right: 20px;
}

.filterContainers .MuiPaper-root .dataGrowthcheckbox-sec {
    width: 45%;
    left: 120px;
}

.checkbox-sec-datagrowth {
    border-left: 1px solid #71758f45;
    padding-left: 13px;
}

.filterContainers .MuiPaper-root .env {
    position: absolute;
    right: 80px;
    top: 70px;
    height: -webkit-fill-available;
    border-left: 1px solid rgba(113, 117, 143, 0.2705882353) !important;
    margin-right: 25px;
    margin-top: -22px;
    padding-top: 14px;
}

.filter-header {
    display: flow-root;
    padding-bottom: 10px;
    border-bottom: 1px solid #71758f45;
}

.list-items ul {
    padding-left: 0px;
    margin-top: 0px;
}

.dataGrowthselectAll-sec1 table {
    width: 100%;
}

.filter-base .MuiPaper-root {
    padding: 0px;
}


.filterContainers .MuiPaper-root .site {
    position: absolute;
    right: 80px;
    top: 70px;
    height: -webkit-fill-available;
    border-left: 1px solid rgba(113, 117, 143, 0.2705882353) !important;
    margin-right: 25px;
    margin-right: 25px;
    margin-top: -22px;
    padding-top: 14px;

}

.MuiMenuItem-root .all-sector {
    padding-left: 10px;
}

.site-align {
    padding-left: 12px;
}

.second-iframe-container-tableau {
    transform: translateY(-10%);
    display: flex;
}

.left-iframe-div-tableau {
    float: left;
    /* width: 46.6vw; */
    padding: 0 !important;
    position: relative;
}

.right-iframe-div-tableau {
    transform: translateX(-27px);
    float: right;
    /* width: 46.6vw; */
    position: relative;
}

.data-check {
    color: $color-grey !important;
}
.second-databricks-iframe-container {
    margin-top: -100px !important;

}

.filterContainers .storage-list {
    float: left;
    width: 53%;
    margin-right: 0;
    margin-top: 5px;
    height: 240px;
    overflow-y: auto;
}
.sector-filter-text {
    left: 89%;
    position: fixed;
    top: 95px;
}