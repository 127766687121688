.App {
	text-align: center;
}

.parent-container {
	margin-top: 0px;
}
.component-container {
	margin: 135px 20px 0 24px;
	border-radius: 4px;
}
.custom-dropdown-menu {
	background-color: rgba(0, 155, 216, 0.2);
	border-radius: 4px;
	margin-top: 0;
    margin-right: 15px;

	&.no-bg {
		background: none;
	}
	em {
		font-size: 14px;
		color: rgba(113, 117, 143, 1);
		font-style: normal;
	}

	.platform-icon {
		margin-right: 5px;
		height: 20px;
	}

	.MuiSelect-select {
		border: 1px solid rgba(113, 117, 143, 0.8) !important;
		font-size: 14px;
		padding: 8px 10px 10px;
	}

	.MuiSvgIcon-root {
		color: #fff;
	}

	fieldset {
		border: none;
	}

}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.fs16 {
	font-size: 16px !important;
}
.fs14px {
	font-size: 14px !important;
}
.fw700 {
	font-weight: 700 !important;
}
.fw600 {
	font-weight: 600 !important;
}
.fw500 {
	font-weight: 500 !important;
}
.fw400 {
	font-weight: 400 !important;
}
.mb-5 {
	margin-bottom: 5px;
}
.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}
.mb-20 {
	margin-bottom: 20px;
}
.ml-20 {
	margin-left: 20px;
}
.iframe-parent-container .parent-container {
	height: 100% !important;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.main-content {
	position: relative;
	/* overflow: scroll; */
	/* margin-top: 40px; */
	margin-left: 60px;
	height: calc(100vh - 118px);
	/* width: calc(100vw - 60px); */
	padding-top: 8px;
}
.footer-text {
	text-transform: capitalize;
	position: absolute;
	bottom: 0px;
	text-align: center;
	width: 100%;
	font-size: 12px;
	font-weight: 600;
}
.footer-text {
	display: none;
}
.loginScreen .footer-text {
	display: block;
}
.root,
#root {
	height: 100%;
	overflow: auto;
	max-height: 100%;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.sch-health-placeholder {
	border: 1px solid rgba(113, 117, 143, 0.5);
	border-top: 0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	color: rgba(245, 245, 245, 1);
	font-size: 12px;
	font-weight: 500;
	padding: 2px 10px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 0;
	z-index: 999;
}

.time-placeholder {
	position: absolute;
	right: 70px;
	top: 15px;
	padding: 18px 0 0;
	z-index: 100;
}

.fullScrrenBtn {
	position: absolute;
	// left: 95.5%;
	right: 0;
	top: 15px;
	z-index: 100;
}
.common-notification {
	position: absolute;
	left: 92.5%;
	z-index: 100;
	top: 11px;
}

.bell-wrapper {
	position: relative;
}

.landing-common-notification {
	position: absolute;
	left: 91%;
	z-index: 100;
	top: 5px;
}

.exitFullScrrenBtn {
	position: absolute;
	// left: 95.5%;
	right: 0;
	top: 15px;
	z-index: 100;
}

// @media screen and (max-width: 1420px) {
// 	.fullScrrenBtn {
// 		left: 94%;
// 	}
// }

// @media screen and (min-width: 1000px) {
// 	.fullScrrenBtn {
// 		top: 1.9%;
// 	}

// 	.exitFullScrrenBtn {
// 		top: 1.5%;
// 	}
// }

// @media screen and (max-width: 1000px) {
// 	.fullScrrenBtn {
// 		top: 2.8%;
// 		// left: 89.5%;
// 	}

// 	.exitFullScrrenBtn {
// 		top: 1.8%;
// 	}
// }
@media screen and (min-width: 1600px) {
	.css-jpln7h-MuiTabs-scroller {
		overflow: hidden !important;
		margin-bottom: 0px !important;
		margin-right: 25px !important;
	}
}
.custom-radio {
	position: absolute;
    display: inline-block;
    opacity: 0;
}
.custom-radio:focus + label, .custom-radio:hover + label, .custom-radio:checked + label {
	color: #F5F5F5 !important;
}
.custom-radio + label {
    cursor: pointer;
	color: #f5f5f5;
	font-weight: 500;
	font-size: 16px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    line-height: 12px;
    padding: 0 0 0 30px;
    transition: all 0.5s ease-in-out;
}
.custom-radio + label::before, .custom-radio + label::after {
    content: "";
    position: absolute;
    color: #f5f5f5;
    text-align: center;
    border-radius: 0;
    top: -5px;
    left: 0;
    width: 12px;
    height: 12px;
    transition: all 0.5s ease-in-out;
}
.custom-radio + label::before {
    border-radius: 50%;
	text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1rem;
    background-color: #001E3E;
    color: #ffffff;
    // box-shadow: inset 0 0 0 1px #666565, inset 0 0 0 1rem #ffffff, inset 0 0 0 1rem #6b0707;
	border: 3px solid #001E3E;
	outline: 2px solid #AAAAAA;
}
.custom-radio:checked + label::before {
    content: "";
    background-color: #009BD8;
    color: #ffffff;
    border: solid 3px #001E3E;
    // box-shadow: inset 0 0 0 1px #bc88d4, inset 0 0 0 0 #ffffff, inset 0 0 0 16px #d43333;
	outline: 2px solid #009BD8;
}

.bg-resource {
	.MuiMenu-paper,
	.MuiPaper-root {
		background-color: #ffffff !important;
	};
	background-color: #ffffff !important;
	.MuiSnackbarContent-root {
		background-color: #ffffff !important;
		color: #ffffff !important;
	};
}

.alert-resource {
	background-color: #ffffff !important;
	color: #000000 !important;
	.MuiAlert-icon {
		color: #fd9532 !important;
	}
}
	
