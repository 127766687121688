.base-tab-infra {
    margin-top: 26vh;
    margin-left: 4vh;
    margin-right: 4vh;
}
.base-tab-infra #simple-tabpanel-0,#simple-tabpanel-1{
    background-color: #0c0f25 !important;
    margin-top: 0px;
    border-radius: 0%;
    margin-left: 0px;
}
/* MUI Tabs */
.base-tab-infra .MuiTab-root {
	color: #e5e5e5;
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 12px;
    width: 185vh;
    background: #11142a;
    margin-right: 1px; 
    min-height: 30px !important;
    position: relative;
    top: 5px;    
    text-transform: capitalize;

}
.TabsControl {
    float: right;
    margin-right: 0px;
}
.css-19kzrtu {
    padding: 0px !important;
}

.base-tab-infra .PBITabs .MuiTab-root.Mui-disabled {
	color: #71758f;
	pointer-events: unset;
	cursor: not-allowed;
}
.base-tab-infra .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    max-width: 230px;
    min-width: 90px;
}

.base-tab-infra .MuiTab-root.Mui-selected {
    border-radius: 4px;
    color: #00d08d !important;
        background: #0c0f25 !important;
        /* font-weight: 700 !important; */
    }
    
        @media screen and (max-width: 1300px) {
            .base-tab-infra {
                margin-top: 32vh;
                margin-left: 4vh;
                margin-right: 4vh;
            }
    
            .base-tab-infra .MuiTab-root {
                position: relative;
                top: 5px;   
                width: 98vh;
            }
        }



