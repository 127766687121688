.table-footer .MuiInputBase-root {
    margin-left: 10px;
}
#pageSize1 {
    padding: 10px 40px 0 0;
    font-size: 14px;
    line-height: 16px !important;
}
.table-footer .MuiInputBase-root #pageSize1 {
    border: 1px solid #71758f;
    border-radius: 0;
    padding-right: 30px;
    margin-top: 11px;
    color: white
}
.table-footer .MuiInputBase-root .MuiSvgIcon-root {
    color: #fff;
    right: 0;
    top: 6px;
}
.table-footer .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border: none;
}
/* MUI Pagination style overrides */
.darkPagination {
    margin-bottom: 0;
    bottom: inherit;
    right: 0;
    height: 86px;
    background: #0c0f25;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0px 0px;
    box-sizing: border-box;
    float: right;
}

.darkPagination .MuiPaginationItem-root {
    color: rgba(229, 229, 229, 1) !important;
}

.darkPagination .MuiPaginationItem-root:hover {
    background-color: rgba(90, 84, 152, 0.2) !important;
}

.darkPagination .Mui-selected {
    background-color: rgba(90, 84, 152, 0.2) !important;
}

.MuiSelect-select {
    color: white !important;
}
/* MUI Menu */
.MuiMenu-paper {
    background-color: #1b1e35 !important;
    color: #f5f5f5 !important;
}

.MuiMenuItem-root {
    height: 30px !important;
    font-family: Montserrat !important;
    font-size: 12px;
    font-size: 12px;
    padding: 6px;
}

.MuiInputBase-root {
    font-family: Montserrat !important;
}

.MuiFormControlLabel-label.Mui-disabled {
    color: #f5f5f5 !important;
    opacity: 0.5;
}