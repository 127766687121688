$theme-color-orange-bg: #0c0f25;
$theme-color-orange-sidebar: #1b1e35;
$theme-color-orange-menu: #1b1e35;

$color-white: #fff;
$color-black: #000;


$color-primary: #0c0f25;
$color-secondary: #1b1e35;
$color-tertiary: #00D08D;
$color-blue: #003771;


$color-red-dark: #ed1c24;
$color-whitesmoke: #f5f5f5;
$color-blue-dark: #3f3a4b;
$color-blue-darker: #3f3a4baa;

$color-blue-medium: #71758f0d;
$primary-color-bg: #71758f66;
$secondary-color-bg: #2d2f47;
$color-grey-pale: #999;
$color-scrollbar: #009BD8;

// ocean blue
// $color-grey: #d4f1f4;
// $color-primary: #05445e;
// $color-secondary: #189ab4;
// $color-tertiary: #75e6da;

// pinky
// $color-grey: #d8a6b1;
// $color-primary: #fae8e0;
// $color-secondary: #ef7c8e;
// $color-tertiary: #b6e2d3;

// midnight blue
// $color-grey: #b1d4e0;
// $color-primary: #0c2d48;
// $color-secondary: #145da0;
// $color-tertiary: #238bc0;

// brownie
// $color-grey: #d6ad60;
// $color-primary: #f4ebd0;
// $color-secondary: #b68d40;
// $color-tertiary: #122620;

// green
// $color-grey: #59981a;
// $color-primary: #81b622;
// $color-secondary: #3d550c;
// $color-tertiary: #ecf87f;

$alert-yellow: #ffa200;
$alert-red: #fe2626;
$alert-grey: #2c2f47;

$color-grey: #e5e5e5;
$color-grey-pale: #999;
$color-grey-md: #71758f;

$color-blue-medium: #71758f0d;

$primary-color-bg: #71758f66;
$secondary-color-bg: #2d2f47;


// define new colors above 

$font-size-tiny: 10px;
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-default: 16px;
$font-size-medium: 18px;
$font-size-larger: 20px;
$font-size-largest: 22px;

$font-size-15: 15px;
$font-size-17: 17px;
$font-size-28: 28px;
$font-size-52: 52px;

$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

$theme-color-bg: $theme-color-orange-bg;
$theme-color-header: $theme-color-orange-bg;
$theme-color-sidebar-active: #0c0f25;
$theme-color-sidebar-default: $theme-color-orange-sidebar;

$font-color-title: $color-white;
$font-color-header: $color-white;

$landing-page-header: #003771;
$landing-page-body: $color-primary;
$color-white-light:$color-white;

$color-primary-light: #11142a;