.editJobName {
	width: 93%;
	text-overflow: ellipsis;
	overflow: hidden;
}
.editJobName:hover {
	padding: 10px;
	border: 1px solid #f5f5f5;
	overflow: visible !important;
	width: max-content !important;
	background: #0c0f25;
	position: absolute;
}
.editJobForm input {
	color: #f5f5f5;
	border-color: #f5f5f5;
	height: 2em;
}
.editJobForm input:focus {
	border-color: #f5f5f5;
}
.editJobForm .MuiOutlinedInput-root.Mui-focused,
.editJobForm .MuiOutlinedInput-notchedOutline {
	border-color: #f5f5f5;
}
.editFormHelperText {
	position: absolute;
	left: 35px;
	font-size: 14px;
	font-style: italic;
	color: #71758f;
}
.MuiInputLabel-root {
	color: #f5f5f5 !important;
}
.MuiInputLabel-asterisk.Mui-error {
	color: #71758f;
}
.attrTitle {
	color: #71758f;
	font-family: 'Montserrat';
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 10px;
}
/* Timepicker MUI */
.MuiTimePickerToolbar-hourMinuteLabel {
	margin-top: 25%;
}

.MuiTimePickerToolbar-root .MuiTypography-root {
	font-family: Montserrat !important;
	color: #f5f5f5;
	text-transform: unset;
	font-weight: 700px;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.07);
	border-radius: 4px;
}
.EditSLATimePicker .PrivatePickersToolbarText-root.Mui-selected,
.EditSLATimePicker .PrivatePickersToolbarText-root.MuiTypography-root {
	color: #f5f5f5;
	font-size: 55px;
	background: #71758f66;
	padding: 3px;
	border-radius: 9px;
	width: 70px;
}
.PrivatePickersToolbarText-root.Mui-selected {
	border: 1px solid #00d08d;
}
.MuiTimePickerToolbar-separator.MuiTypography-root {
	background: none;
	width: 10px;
}
.MuiPickersArrowSwitcher-button {
	background-color: transparent;
	color: #00d08d;
}
.MuiPickersArrowSwitcher-button.Mui-disabled {
	background-color: transparent;
	color: #00d08d55;
}
.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockNumber-root.Mui-selected {
	background-color: #00d08d;
}
.MuiPickersToolbar-penIconButton {
	display: none;
}
.time-picker-zone {
	position: fixed;
	margin-top: 30%;
}
/* MUI Menu */
.MuiMenu-paper {
	background-color: #1b1e35 !important;
	color: #f5f5f5 !important;
}

.MuiMenuItem-root {
	height: 30px !important;
	font-family: Montserrat !important;
	font-size: 12px;
	font-size: 12px;
	padding: 6px;
}

.MuiInputBase-root {
	font-family: Montserrat !important;
}

.MuiFormControlLabel-label.Mui-disabled {
	color: #f5f5f5 !important;
	opacity: 0.5;
}
.jobs-list-datas .MuiButtonBase-root,
.jobs-list-table .MuiButtonBase-root,
.jobs-run-table .MuiButtonBase-root {
    border-radius: 0px;
    background: transparent;
    color: white;
}

.jobs-list-datas .MuiPickersPopper-root .MuiTypography-root.Mui-selected,
.jobs-list-table .MuiPickersPopper-root .MuiTypography-root.Mui-selected,
.jobs-run-table .MuiPickersPopper-root .MuiTypography-root.Mui-selected {
    font-size: 15px;
    color: #00d08d;
    padding: 0px;
    border: none;
}

.jobs-list-datas .MuiPickersDay-root.Mui-selected,
.jobs-list-table .MuiPickersDay-root.Mui-selected,
.jobs-run-table .MuiPickersDay-root.Mui-selected {
    background-color: #00d08d !important;
}

.jobs-list-datas .MuiPickersPopper-root,
.jobs-list-table .MuiPickersPopper-root,
.jobs-run-table .MuiPickersPopper-root {
    top: 0px;
}

.jobs-list-datas .MuiDateTimePickerToolbar-separator,
.jobs-list-table .MuiDateTimePickerToolbar-separator,
.jobs-run-table .MuiDateTimePickerToolbar-separator {
    padding: 10px;
}

.jobs-list-datas .MuiPickersPopper-root .MuiClockNumber-root,
.jobs-list-table .MuiPickersPopper-root .MuiClockNumber-root,
.jobs-run-table .MuiPickersPopper-root .MuiClockNumber-root {
    color: white;
}

.jobs-list-datas .MuiPickersPopper-root .MuiClockPicker-root,
.jobs-list-table .MuiPickersPopper-root .MuiClockPicker-root,
.jobs-run-table .MuiPickersPopper-root .MuiClockPicker-root {
    margin-top: 10px;
}

.jobs-list-datas .MuiDateTimePickerToolbar-dateContainer .MuiTypography-root,
.jobs-list-datas .MuiPickersPopper-root .MuiTypography-root,
.jobs-list-table .MuiDateTimePickerToolbar-dateContainer .MuiTypography-root,
.jobs-list-table .MuiPickersPopper-root .MuiTypography-root,
.jobs-run-table .MuiPickersPopper-root .MuiTypography-root {
    font-family: Montserrat !important;
    color: #f5f5f5;
    text-transform: unset;
    font-size: 15px;
    font-weight: 700px;
}

.jobs-list-datas .MuiDateTimePickerToolbar-root .time-picker-zone,
.jobs-list-table .MuiDateTimePickerToolbar-root .time-picker-zone,
.jobs-run-table .MuiDateTimePickerToolbar-root .time-picker-zone {
    margin-top: 15%;
    color: white;
    margin-bottom: 10px;
}
.edit-job-modal .editJobForm .MuiGrid-container .MuiFormControl-root {
    border-color: #0c0f25;
    margin-bottom: 10px !important;
}
.edit-job-modal .editJobForm .MuiGrid-container .MuiFormControl-root .MuiInputLabel-root {
    color: #71758f !important;
    margin-top: -10px !important;
}