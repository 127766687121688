@import '../../theme-variables.scss';

.main-content {
    height: 100vh;
    overflow-y: auto;
    background: #001e3e;
}
.MuiPaper-root,
.MuiTableCell-root {
	background-color: #1b1e35;
	color: #e5e5e5;
	border: none !important;
	padding: 5px;
    width: auto;
}
.MuiPickersPopper-root .MuiTypography-root.Mui-selected {
    font-size: 15px;
    color: #00d08d;
    padding: 0px;
    border: none;
}
.MuiDateTimePickerToolbar-dateContainer .MuiTypography-root,
.MuiPickersPopper-root .MuiTypography-root {
    font-family: Montserrat !important;
    color: #f5f5f5;
    text-transform: unset;
    font-size: 15px;
    font-weight: 700px;
}
.dateFilter input {
    color: white;
    font-size: 12px;
}
.dateFilter .MuiOutlinedInput-notchedOutline {
    border: none;
    /* border-bottom: 1px solid #71758f; */
    border-radius: 0px;
}
.dateFilter .MuiInputAdornment-root {
    margin-left: 0px;
}
.dateFilter .MuiOutlinedInput-root.Mui-focused {
    border-color: #00d08d;
}