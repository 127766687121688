.presto_title_div {
	display: flex;
	align-items: center;
	margin-top: -20px;
	color: #e5e5e5;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-left: 18px;
}

.presto_title {
	color: #fff;
	font-family: Montserrat;
	font-size: 24px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
	text-transform: uppercase;
	margin-left: 10px;
}

.sector-filter {
	top: 70px;
	left: 95%;
	position: absolute;

}
.presto-model-menu {
	color: #e5e5e5;
	font-size: 20px;
	font-weight: 600;
	font-family: 'Montserrat';
	padding-top: 20px;
	position: absolute;
	margin-left: -14px;
}

.presto-icon {
	width: 40px;
	margin-top: 40px;
	margin-left: 30px;
	padding-top: 0px;
	position: absolute;
}

.presto-header-name {
	position: absolute;
	margin-left: 50px;
	margin-top: 29px;
	color: #ffffff;
	padding: 20px;
	width: 310px;
}

.presto-page-title {
	color: #e5e5e5;
	font-size: 18px;
	font-weight: 600;
	font-family: 'Montserrat';
	position: absolute;
	top: 72px;
	left: 200px;
}

.presto-symbol {
	color: #71758f;
	font-size: 20px;
	font-weight: 600;
	font-family: 'Montserrat';
	position: absolute;
	top: 72px;
	left: 178px;
}
.under-development-container {
	position: absolute;
	top: 50%;
	left: 45%;
	color: darkgray;
}
.under-development-text {
	position: relative;
	right: 25%;
	margin: 0px;
}

.presto_tabledata {
    padding: 0px 15px;
    margin-top: 130px;
}

.presto_drilldown_heading{
	margin-bottom: 15px;
	display: block;
	
}
.prestoGoBack_button{
    display: flex;
    align-items: center;
    margin-left: 91%;
	margin-top: -17px;
	cursor: pointer;
  }

  .back_icon{
	cursor: pointer;
	margin-right: 5px;
  }