.executionRunHistory {
	position: relative;
}

.executionRunHistory .paginationStack {
	position: absolute;
	right: 0px;
	bottom: 15px;
}
.row-select-drpdwn {
	margin: 0;
}

.row-select-drpdwn .MuiSelect-select {
	padding-left: 8px;
}

.row-select-drpdwn .MuiSelect-icon {
	color: #fff;
	right: 16px !important;
}
/* MUI chips */
.MuiChip-colorSuccess {
	background-color: #17372e;
	color: #01c008;
}

.MuiChip-colorSecondary {
	background-color: #3d2033;
	color: #cd2429;
}

.MuiChip-colorWarning {
	background-color: #ffa20033;
	color: #ffa200;
}

.MuiChip-colorDefault {
	background-color: #0c0f25;
	color: #e5e5e5;
	margin-bottom: 10px;
	max-width: 106px;
	margin-right: 10px;
	height: 24px;
}
/* MUI Menu */
.MuiMenu-paper {
	background-color: #1b1e35 !important;
	color: #f5f5f5 !important;
}

.MuiMenuItem-root {
	height: 30px !important;
	font-family: Montserrat !important;
	font-size: 12px;
	font-size: 12px;
	padding: 6px;
}

.MuiInputBase-root {
	font-family: Montserrat !important;
}

.MuiFormControlLabel-label.Mui-disabled {
	color: #f5f5f5 !important;
	opacity: 0.5;
}
.run_history_tabledata .rdg {
    background: #0c0f25;
    height: 60vh;
    border: none;
    overflow-y: auto !important;
}

.run_history_tabledata .rdg-cell {
    font-weight: 300;
    padding: 4px 7px 4px 10px !important;
    color: white;
    border: none;
    line-height: 28px;
    font-size: 12px;
}

.run_history_tabledata .rdg-cell:hover {
    overflow: visible;
    z-index: 111;
    max-width: initial;
    background: #0c0f25;
}

.run_history_tabledata .rdg-cell[aria-selected='true'] {
    outline: none;
}

.run_history_tabledata .rdg-cell[aria-sort='descending'] .rdg-sort-arrow,
.run_history_tabledata .rdg-cell[aria-sort='ascending'] .rdg-sort-arrow {
    fill: none;
    height: 20px;
}

.run_history_tabledata .rdg-row:nth-child(even) {
    background: none;
}

.run_history_tabledata .rdg-header-row {
    background: #1b1e35;
}

.run_history_tabledata .rdg-row:nth-child(odd) {
    background: rgba(113, 117, 143, 0.05);
}

.run_history_tabledata .rdg-row:nth-child(odd) .rdg-cell {
    border-right: 1.5px solid #1b1e35;
}

.run_history_tabledata .rdg-header-row .rdg-cell {
    border-right: 1.5px solid #0c0f25;
}

.run_history_tabledata .rdg-cell:last-child {
    border-right: none !important;
}

.run_history_tabledata .rdg-text-editor {
    appearance: none;
    box-sizing: border-box;
    inline-size: 100%;
    block-size: 100%;
    border: 2px solid #1b1e35;
    color: #f5f5f5;
    background-color: #71758f66;
    font-family: 'Montserrat';
    font-size: 12px;
    height: 30px;
    }

.run_history_tabledata .rdg-text-editor::placeholder {
    color: #999;
    opacity: 1;
}

.run_history_tabledata .rdg-text-editor:focus {
    border: 1px solid;
    border-color: white;
    outline: none;
}

.run_history_tabledata .rdg-editor-container .MuiInputBase-root {
    font-family: Montserrat !important;
    font-size: 12px;
}

.run_history_tabledata .rdg-cell.has-editor:hover {
    outline: 1px solid #3f3a4b;
    background: #3f3a4baa;
}

.run_history_tabledata .reasonForDelay:hover {
    position: absolute;
}

.run_history_tabledata .rdg-cell.has-editor.rdg-editor-container:hover {
    outline: none;
}

.run_history_tabledata .has-editor.rdg-editor-container {
    padding-left: 0 !important;
    padding-top: 2px !important;
}

.run_history_tabledata .has-editor.rdg-editor-container * {
    background: none !important;
}

.run_history_tabledata .rdg-cell.has-editor input {
    border: 1px solid #3f3a4b;
}

.run_history_tabledata .rdg-cell.center-text {
    text-align: center;
}

.run_history_tabledata .highlightClass .rdg-cell {
    background-color: #71758f66;
    border-right: none !important;
}

.run_history_tabledata .highlightClass:hover .rdg-cell {
    background-color: #71758f66;
}

.jobs-run-table .run_history_tabledata .rdg {
    height: 47vh;
}
.jobs-list-datas .dateFilter .MuiSvgIcon-root,
.jobs-list-table .dateFilter .MuiSvgIcon-root,
.jobs-run-table .dateFilter .MuiSvgIcon-root {
    color: #71758f;
}

.jobs-list-datas .dateFilter input,
.jobs-list-table .dateFilter input,
.jobs-run-table .dateFilter input {
    color: white;
    font-size: 12px;
}

.jobs-list-datas .dateFilter-formControl,
.jobs-list-table .dateFilter-formControl,
.jobs-run-table .dateFilter-formControl {
    width: 95%;
    float: left;
    margin: 0px 10px 20px;
}

.jobs-list-datas .dateFilter-div,
.jobs-list-table .dateFilter-div,
.jobs-run-table .dateFilter-div {
    display: block;
    clear: both;
    padding: 25px 0px 20px;
    float: left;
    border-top: 1px solid #71758f;
}

.jobs-list-datas .dateFilter .MuiOutlinedInput-notchedOutline,
.jobs-list-table .dateFilter .MuiOutlinedInput-notchedOutline,
.jobs-run-table .dateFilter .MuiOutlinedInput-notchedOutline {
    border: none;
    /* border-bottom: 1px solid #71758f; */
    border-radius: 0px;
}

.jobs-list-datas .dateFilter .MuiInputAdornment-root,
.jobs-list-table .dateFilter .MuiInputAdornment-root,
.jobs-run-table .dateFilter .MuiInputAdornment-root {
    margin-left: 0px;
}

.jobs-list-datas .dateFilter .MuiOutlinedInput-root.Mui-focused,
.jobs-list-table .dateFilter .MuiOutlinedInput-root.Mui-focused,
.dateFilter .MuiOutlinedInput-notchedOutline:hover,
.jobs-run-table .dateFilter .MuiOutlinedInput-root.Mui-focused {
    border-color: #00d08d;
}

.jobs-list-datas .dateFilter .editJobForm .MuiOutlinedInput-root.Mui-focused,
.jobs-list-table .dateFilter .editJobForm .MuiOutlinedInput-root.Mui-focused,
.dateFilter .MuiOutlinedInput-notchedOutline,
.jobs-run-table .dateFilter .editJobForm .MuiOutlinedInput-root.Mui-focused {
    border-color: #71758f;
}

.jobs-list-datas .dateFilter-formControl .MuiInputBase-root:hover,
.jobs-list-table .dateFilter-formControl .MuiInputBase-root:hover,
.jobs-run-table .dateFilter-formControl .MuiInputBase-root:hover {
    border-bottom: 1px solid #00d08d;
    border-radius: 0px;
}

.jobs-list-datas .MuiButtonBase-root,
.jobs-list-table .MuiButtonBase-root,
.jobs-run-table .MuiButtonBase-root {
    border-radius: 0px;
    background: transparent;
    color: white;
}

.jobs-list-datas .MuiPickersPopper-root .MuiTypography-root.Mui-selected,
.jobs-list-table .MuiPickersPopper-root .MuiTypography-root.Mui-selected,
.jobs-run-table .MuiPickersPopper-root .MuiTypography-root.Mui-selected {
    font-size: 15px;
    color: #00d08d;
    padding: 0px;
    border: none;
}

.jobs-list-datas .MuiPickersDay-root.Mui-selected,
.jobs-list-table .MuiPickersDay-root.Mui-selected,
.jobs-run-table .MuiPickersDay-root.Mui-selected {
    background-color: #00d08d !important;
}

.jobs-list-datas .MuiPickersPopper-root,
.jobs-list-table .MuiPickersPopper-root,
.jobs-run-table .MuiPickersPopper-root {
    top: 0px;
}

.jobs-list-datas .MuiDateTimePickerToolbar-separator,
.jobs-list-table .MuiDateTimePickerToolbar-separator,
.jobs-run-table .MuiDateTimePickerToolbar-separator {
    padding: 10px;
}

.jobs-list-datas .MuiPickersPopper-root .MuiClockNumber-root,
.jobs-list-table .MuiPickersPopper-root .MuiClockNumber-root,
.jobs-run-table .MuiPickersPopper-root .MuiClockNumber-root {
    color: white;
}

.jobs-list-datas .MuiPickersPopper-root .MuiClockPicker-root,
.jobs-list-table .MuiPickersPopper-root .MuiClockPicker-root,
.jobs-run-table .MuiPickersPopper-root .MuiClockPicker-root {
    margin-top: 10px;
}

.jobs-list-datas .MuiDateTimePickerToolbar-dateContainer .MuiTypography-root,
.jobs-list-datas .MuiPickersPopper-root .MuiTypography-root,
.jobs-list-table .MuiDateTimePickerToolbar-dateContainer .MuiTypography-root,
.jobs-list-table .MuiPickersPopper-root .MuiTypography-root,
.jobs-run-table .MuiPickersPopper-root .MuiTypography-root {
    font-family: Montserrat !important;
    color: #f5f5f5;
    text-transform: unset;
    font-size: 15px;
    font-weight: 700px;
}

.jobs-list-datas .MuiDateTimePickerToolbar-root .time-picker-zone,
.jobs-list-table .MuiDateTimePickerToolbar-root .time-picker-zone,
.jobs-run-table .MuiDateTimePickerToolbar-root .time-picker-zone {
    margin-top: 15%;
    color: white;
    margin-bottom: 10px;
}

.root,
#root {
    height: 100%;
    overflow: auto;
    max-height: 100%;
}
